@import 'styles/theme.scss';

.dashboard-container{
  position: relative;
}
.dashboard {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0 50px 50px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .no-proposals {
    width: 90%;
    max-width: 420px;
    color: #05034d;
    text-align: center;

    .feature-svg {
      width: 200px !important;

      svg {
        width: 100% !important;
      }
    }

    .text {
      font-size: 1.2em;
    }

    .link {
      color: #4F4E7B;
      text-decoration: underline;
      font-weight: 600;
    }

    .new-proposal-btn {
      margin: 30px auto;

      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  .item-list {
    padding-bottom: 50px;
    width: 100%;
    bottom: 0;
    &.empty-list{
      @extend .flex-center;
      padding-top: 70px;
      flex-direction: column;
    }

    @include sm {
      .avatar-wrapper {
        display: none;
      }

      .value-status {
        display: none;
      }

      .action-column .proposal-item-menu .anticon svg {
        width: 3px;
      }
    }
  }

  .dashboard-header.hide-items~.item-list {
    height: calc(100dvh - 190px);
  }

  .value-status {
    min-width: 110px;
  }

  .dashboard-list-header,
  .item-list {
    @include rwd(650) {
      .value-status {
        display: none;
      }
    }

    @include rwd(768) {
      .value-opened {
        display: none;
      }
    }

    @include rwd(992) {
      .value-quote {
        display: none;
      }
    }

    @include rwd(1200) {
      .value-watched {
        display: none;
      }
    }
  }

  @media screen and (min-width:1366px) {
    .value-status {
      width: 100px;
      max-width: unset;
      flex: unset;
    }

    .action-column {
      width: 35px;
      max-width: unset;
      flex: unset;
      padding: 0 !important;
    }
  }

  .month-name {
    margin-top: 12px;
    font-weight: 300;
    line-height: 140%;
    color: $primary-color;
    font-size: $medium-text-font-size;
  }

  .empty-view {
    margin-top: 6em;
  }

  .scrollTop.arrow-top-button {
    background: $white-color;
    border: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $box-shadow;
    opacity: 1;
    transition: opacity 500ms, transform 500ms;
    transform: scale(1);

    &.hide {
      opacity: 0;
      transform: scale(0);
    }
  }
}

.handle-user-lock {
  .dashboard {
    .item-list {
      .ant-row.ant-row-middle {
        .ant-col {
          opacity: 0.4;
          pointer-events: none;

          &:last-child {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
  }
}

.ant-notification {
  .download-report-notification.ant-notification-notice {
    width: 30em;
    height: 5em;
    border-left: 5px solid $primary-color-light;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .ant-notification-notice-message {
      color: $primary-color-text;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      margin: 0;
      margin-left: 3em;
    }

    .ant-notification-notice-icon {
      font-size: 18px;
      align-items: center;
      border-radius: 100%;
      text-align: center;

      .anticon {
        svg {
          width: 1.6em;
          height: 1.6em;
        }
      }
    }
  }
}

.open-logs-modal {
  width: 100% !important;
  max-width: 900px !important;

  @include rwd-min(568) {
    width: calc(100% - 40px) !important;
  }

  @include rwd-min(120) {
    max-width: 860px !important;
  }

  .ant-modal-body {
    padding: 2em 0;

    .title {
      margin-top: 0;
      justify-content: flex-start;
      margin: 0 24px;
    }

    .divider {
      margin: 0;
      margin: 1em 24px;
    }

    .body {
      text-align: left;
      margin-bottom: 0em;
      color: $primary-color-text;

      .content {
        margin: 0 24px;
        margin-bottom: 1em;
      }

      .ant-table-wrapper {
        .ant-table {
          color: $primary-color-text;
          overflow-x: auto;

          .ant-table-thead>tr>th {
            color: $primary-color-text;
            background: $tab-background;
            border-bottom: 1px solid $tab-background;
          }

          .ant-table-cell {
            .open_logs_span {
              color: $label-color-light;
              opacity: 0.8;
              font-size: $sub-font-size;
            }
          }

          .ant-table-row .ant-table-cell {
            &:first-child {
              font-weight: 600;
            }
          }
        }

        .ant-table-container {
          min-width: 820px;
        }

        .ant-pagination {
          text-align: center;
          margin: 1em 2em;
          margin-bottom: 0;

          .ant-pagination-prev,
          .ant-pagination-next {
            border: none;
            background: $tab-background;
            border-radius: 4px;
            padding: 0px 1em;
            font-size: $label-font-size;
            font-weight: 600;

            a {
              color: $primary-color-text;
            }

            .ant-pagination-item-link {
              background-color: transparent;
              border: none;
            }
          }

          .ant-pagination-disabled {
            font-weight: 400;
          }

          .ant-pagination-item {
            border: none;

            a {
              color: $primary-color-text;
            }
          }

          .ant-pagination-item-active {
            background: $tab-background;
            border-radius: 4px;
            font-size: $label-font-size;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.device-info-tooltip {
  max-width: 350px;

  .device-info-container {
    padding: 0.6em;

    .ant-row {
      font-size: $sub-font-size;

      &:first-child {
        font-weight: 600;
        font-size: $label-font-size;
        line-height: 140%;
        margin-bottom: 0.5em;
      }
    }

    .ant-divider {
      height: 1.5em;
      border-color: $tab-border;
    }
  }
}

.feature-flags-sider {
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  flex-basis: 26% !important;
  max-width: 26% !important;
  width: 23% !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
  background-color: white !important;
  box-shadow: 0px 2px 4px rgba(3, 20, 63, 0.1);
  &.sider-is-collapsed {
    transform: translateX(-100%);
    overflow-y: unset;
  }
  .title {
    h4 {
      display: flex;
      justify-content: center;
      margin-top: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: #05034d;
    }
    .divider {
      margin-top: 1em;
      background: #f03f3b;
      width: 32px;
      height: 1px;
    }
  }
  .ant-layout-sider-children {
    padding: 2em;
    position: relative;
    z-index: 10000;
    .content-flags {
      margin-top: 1.5rem;
      .feature-flag-item {
        display: flex;
        justify-content: space-between;
        padding: 1em 0;
        border-bottom: 1px solid #f6f6f6;
        align-items: flex-start;
        .check-label {
          display: inline-block;
          max-width: 210px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:last-child {
          border-bottom: none;
        }
        .ant-switch {
          height: 21px;
          min-width: 35px;
        }
        .ant-switch-handle {
          top: 3px;
          width: 15px;
          height: 15px;
        }
        .ant-switch-checked {
          background-color: #2f2c88;
          .ant-switch-handle {
            left: calc(100% - 16px - 2px);
          }
        }
      }
    }
  }
}
.feature-flags-sider-overlay-toggle {
  overflow: auto;
  height: 100vh;
  position: fixed;
  max-width: 100% !important;
  width: 100% !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  cursor: pointer;
  background-color: rgba(48, 53, 124, 0.57);
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
}
.feature-flags-sider-collapse {
  position: absolute !important;
  background: #fbfbfb;
  text-align: center;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  padding: 12px;
  height: 42px;
  right: 248px;
  bottom: 22px;
  transform: translateX(100%);
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(3, 20, 63, 0.1);
  z-index: 1001;
  .anticon {
    font-size: 18px;
    padding: 0.5em;
    &.feature-flags-sider-collapse-expand {
      transform: rotate(180deg);
    }
  }
  .btn-text {
    text-transform: uppercase;
    padding: 0.5em;
    padding-left: 0;
  }
}
