@import 'styles/theme.scss';

.auth-layout {
  overflow-y: auto;
  height: 100dvh;
  @include rwd-max(767) {
    background: white;
  }
  
  .auth-wrapper {
    width: 100%;
    height: 100%;
    display: flex !important;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: linear-gradient(
      155deg,
      rgb(4, 2, 54) 0%,
      rgb(11, 0, 74) 38.500080333246004%,
      rgb(5, 3, 77) 74.85924410434251%,
      rgb(47, 44, 136) 100%
    ) !important;

    @include rwd-min(768) {
      height: 100dvh;
      background: linear-gradient(
        155deg,
        rgb(4, 2, 54) 0%,
        rgb(11, 0, 74) 38.500080333246004%,
        rgb(5, 3, 77) 74.85924410434251%,
        rgb(47, 44, 136) 100%
      ) !important;
    }

    @include rwd-min(1024) {
      height: calc(100% - 5%);
    }

    @include rwd-max(767) {
      height: auto;
      background: white !important;
    }
    .auth-backdrop {
      position: fixed;
      left: 0px;
      top: 0px;
      width: 100vw;
      height: 100vh;
      @include rwd-max(767) {
        display: none;
      }
      .backdrop-container {
        align-content: flex-start;
        align-items: flex-start;
        background: linear-gradient(
          155deg,
          rgb(4, 2, 54) 0%,
          rgb(11, 0, 74) 38.500080333246004%,
          rgb(5, 3, 77) 74.85924410434251%,
          rgb(47, 44, 136) 100%
        ) !important;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 10px;
        height: min-content;
        justify-content: flex-start;
        min-height: 1211px;
        overflow: visible;
        padding: 0;
        position: relative;
        min-height: 100dvh;
        width: auto;
        .backdrop-content {
          position: absolute;
          top: 0;
          left: calc(50.00000000000002% - 100% / 2);
          padding: 0 20px;
          gap: 10px;
          align-content: center;
          align-items: center;
          opacity: 0.1;
          display: flex;
          flex: none;
          flex-direction: row;
          flex-wrap: nowrap;
          height: 100vh;
          width: 100%;
          justify-content: center;
          overflow: hidden;
          z-index: 0;
          @include rwd-max(767) {
            gap: 10px;
            height: 128.08056872037915vh;
            padding: 0 10px;
          }

          .ticker-container {
            flex: 1 0 0px;
            height: 100%;
            position: relative;
            width: 1px;
            
            @media screen and (min-width: 820px) and (max-width: 1180px) {
              &:nth-child(n + 4) {
                display: none;
              }
            }
            @include rwd-max(767) {
              &:nth-child(n + 3) {
                display: none;
              }
            }
            @include rwd-max(768) {
              &:nth-child(n + 4) {
                display: none;
              }
            }
          }

          .ticker-section {
            display: flex;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            place-items: center;
            margin: 0;
            padding: 0;
            list-style-type: none;
            opacity: 1;
            overflow: visible;
          }

          .auth-template-images-list {
            display: flex;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            place-items: center;
            margin: 0;
            padding: 0;
            list-style-type: none;
            text-indent: none;
            gap: 10px;
            position: relative;
            flex-direction: column;
            will-change: transform;
            transform: translateY(-0px);
          }

          .ticker-template-item {
            display: contents;
            &.width-full {
              width: 100%;
              display: block;
            }
          }

          .images-container {
            aspect-ratio: 0.5997993981945837 / 1;
            border-radius: 12px;
            box-shadow: 0 10px 30px #0000001a;
            height: auto;
            min-height: 395px;
            overflow: visible;
            position: relative;
            width: 100%;
            flex-shrink: 0;
            background-color: rgba(0, 0, 0, 0);
            opacity: 0.7;
            transform: none;
            @include rwd-max(767) {
              opacity: 0.2;
              pointer-events: none;
            }

            &:hover {
              opacity: 1;
            }
          }

          .background-image-wrapper {
            position: absolute;
            border-radius: inherit;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }

          .background-image {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            object-position: center;
            object-fit: cover;
            image-rendering: auto;
          }
        }
      }
    }

    .auth-content {
      z-index: 2;
      @include rwd-max(767) {
        width: 100%;
      }
    }
  }
  .auth-footer {
    position: absolute;
    bottom: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    align-self: center;
    width: 100%;

    @media screen and (min-width: 820px) and (max-width: 1180px) {
      position: fixed;
      z-index: 20;
    }

    .footer-links {
      padding: 10px 0;
      text-align: center;
      display: flex;
      color: #e2e3e8;
      justify-content: center;
      align-items: center;
      position: relative;
      opacity: 0.7;
      z-index: 3;
      @include rwd-max(767) {
        font-size: 8px;
        color: #c8c8c8;
        opacity: 1;
      }
      a {
        font-size: 12px;
        margin: 0 20px;
        color: #e2e3e8;
        @include rwd-max(767) {
          font-size: 10px;
          color: #93939d;
          opacity: 1;
        }
      }
    }
  }
}
