@import 'styles/theme.scss';
.scrollTop.arrow-top-button {
  background: $white-color;
  border: $white-color;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(3, 20, 63, 0.1);
  opacity: 1;

  transition: opacity 500ms, transform 500ms;
  transform: scale(1);
  &.hide {
    opacity: 0;
    transform: scale(0);
  }
}
