.admin-ai-prompt {
  .ant-radio-wrapper {
    input {
      width: 85%;
    }
  }
  .anticon {
    margin-left: 1em;
  }
}
