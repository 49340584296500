.upload-proposal-item-modal {
  .select-image {
    .ant-upload-drag {
      .ant-upload-btn {
        padding: 0;
        .select-image-uploader {
          margin-bottom: 0;
          height: 100%;
        }
      }
    }
    .ant-upload-list-item-info {
      & > span {
        display: flex;
      }
    }
  }
  .fade {
    opacity: 0.8 !important;
  }
  .ant-upload.ant-upload-drag {
      border: 0 !important;
  }

  canvas { image-rendering: pixelated; image-rendering: optimizespeed; }
}
