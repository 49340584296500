@import 'styles/theme.scss';

.covers-library-section {
  .ant-card {
    border-radius: 4px;
    overflow: hidden;
    filter: drop-shadow(0px 2px 4px rgba(3, 20, 63, 0.2));
    .ant-card-cover {
      transform: translateY(0px);
    }
  }

  .ant-card-body {
    padding: 0;
  }

  .folder {
    margin: 16px 0;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .anticon {
      margin-right: 10px;
    }
    .folder-path-arrow {
      svg {
        width: 12px;
        height: 12px;
        path {
          fill: $primary-color-light;
        }
      }
    }
  }

  .folder-path {
    display: flex;
    align-items: center;
    padding-top: 1em;

    .path {
      flex-grow: 1;

      .folder-path-name {
        text-transform: capitalize;
        color: $primary-color-text;
        &:last-of-type {
          font-weight: bold;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
        }
      }
    }

    .folder-path-buttons {
      .prosper-icon-button {
        height: 32px !important;
        font-size: 14px !important;
        min-width: 0;
        margin-right: 10px;
        .prosper-button-text {
          margin-top: 0px;
        }
        .prosper-button-add-icon {
          svg {
            height: 20px;
            width: 20px;
          }
        }
      }

      .back-button {
        background: $light-color;
        border: 2px solid $light-color;
        color: $primary-color-text;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        &:hover {
          opacity: 0.8;
          background: $light-color;
          border-color: $light-color;
        }
      }
    }
  }

  .prosper-button-add-folder-icon {
    background: $tab-background;
    margin: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: $label-font-size;
    color: $primary-color-text;
    border: none;
    box-shadow: $box-shadow;
  }
}
