@import 'styles/theme.scss';

.proposal-signaturesection {
  margin-bottom: 0px;
  padding: 50px 50px 40px;
  border-bottom: 0px none $white-color;
  background-color: $white-color;
  box-shadow: 1px 6px 3px 0 rgba(0, 0, 0, 0.28);
  box-shadow: none !important;
  transition: background 0.5s ease;
  position: relative;

  .cover-buttons {
    display: none;
  }

  .pdf-signed-info {
    display: none;
    color: #555;
    z-index: 10000;
    background: #eee;
    font-size: 12px;
    text-align: center;
    margin-top: 2.5em;
    padding: 1em;
  }

  &:hover {
    .cover-buttons {
      position: absolute;
      left: 8px;
      top: 8px;
      z-index: 3;
      background-color: $white-color;
      font-size: $label-font-size;
      font-weight: normal;
      line-height: 140%;
      text-align: center;
      color: $primary-color-text;
      display: flex;
      box-shadow: 0px 2px 4px rgba(0, 38, 95, 0.2);
      border-radius: 4px;
      & > span {
        cursor: pointer;
        padding: 4px 8px;
        display: inline-block;
        height: 25px;
        vertical-align: middle;
        &:not(:last-child) {
          border-right: 1px solid #cbced8;
        }
        &:hover {
          background-color: #f5f6fa;
        }
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
          span.remove-cover-icon {
            height: auto;
            padding: 0;
          }
        }
      }
    }
  }

  .section-title {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    font-size: $title-font-size;
    word-break: break-word;
    &.center {
      min-width: 280px;
      .public-DraftEditorPlaceholder-root,
      .public-DraftStyleDefault-rtl,
      .public-DraftStyleDefault-ltr {
        text-align: center !important;
      }
    }
  }

  .signaturetext {
    position: absolute;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    font-family: Sacramento, sans-serif;
    font-size: $title-font-size;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 0.65em;
  }

  .signaturetext.imgsig {
    text-align: center;
    opacity: 1 !important;
    display: grid;
    left: 0;
    transform: none;
    img {
      left: 10px;
      width: calc(100% - 20px);
      height: calc(100% - 10px);
      position: absolute;
      object-fit: contain;
    }
  }

  .signature-name,
  .additional-signature-name {
    white-space: pre-wrap;
  overflow-wrap: break-word;
    margin-top: 5px;
    margin-top: 0px;
    margin-right: 10px;
    margin-left: 10px;
    padding-top: 12px;
    border-top: 1px solid #c2c2c2;
    color: #4a4a4a;
    font-size: 20px;
  }

  .signature-col {
    font-size: $label-font-size;
    min-height: 6em;
    word-break: break-word;
    position: relative;
    margin-right: 10px;
    margin-left: 10px;

    cursor: pointer;
    .show-hide-user-signature {
      opacity: 0;
      transition: opacity 0.4s ease;
      position: absolute;
      right: -20px;
      top: -16px;
      padding: 0 0 60px 60px;
    }

    .signature-overlay {
      opacity: 0;
      position: absolute;
      z-index: 1;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $section-background;
      transition: opacity 0.5s;
      cursor: auto;

      .anticon {
        cursor: pointer;
        padding: 0.5em;
        position: absolute;
        right: 0;
        &:hover {
          background-color: $publish-background-color;
          border-radius: 50%;
        }
      }
    }

    &:hover {
      .show-hide-user-signature {
        opacity: 1;
      }
      .signature-overlay {
        opacity: 0.8;
      }
    }
  }

  .show-hide-signature {
    transition: opacity 0.4s ease;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 68px;
    left: -40px;
    width: 17px;
    margin-left: -8px;
    z-index: 1;
    opacity: 0;
  }

  &:hover .show-hide-signature {
    opacity: 1;
  }

  .sign-proposal-button {
    width: 100%;
    border-radius: 4px;
    height: 44px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    background-color: $section-color;
    color: $white-color;

    > input {
      background-color: transparent;
      border: none;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      width: 100%;
      &:focus {
        outline: none;
      }
    }

    .ant-btn-loading-icon {
      position: absolute;
      margin-right: 2.5em;
      .anticon-loading {
        padding: 0;
        margin-right: 8px;
        svg: {
          width: 1.5em;
          height: 1.5em;
        }
      }
    }

    &:hover {
      background-color: $section-color-hover;
      color: $white-color;
      text-decoration: none !important;
    }

    &:focus {
      background-color: $section-color-hover;
      color: $white-color;
      text-decoration: none !important;
    }
  }

  .sign-proposal-button.disabled {
    background-color: transparent !important;
  }

  .ant-col.signature-btn-col-text {
    display: block;
    padding-top: 0;
  }

  .signature-btn-col,
  .additional-signature-btn-col,
  .draft-signature-btn-col,
  .draft-additional-signature-btn-col {
    word-break: break-all;
    padding-top: 1.5em;
    .ant-btn,
    .signature-btn-wrapper {
      flex-grow: 1;
    }
    .signature-btn-wrapper {
      padding: 2px;
      border: 2px solid transparent;
      position: relative;
      &:hover {
        border: 2px solid $primary-color;
      }
      .ant-btn {
        flex-grow: 0;
        margin: 0;
        width: 100%;
        cursor: not-allowed;
      }
      .signature-btn-wrapper-option {
        right: -2px;
        position: absolute;
        top: -30px;
        color: $primary-color-text;
        box-shadow: $box-shadow-avatar;
        border-radius: 4px;
        visibility: hidden;
        cursor: pointer;
        & > span {
          cursor: pointer;
          padding: 4px 6px;
          display: inline-block;
          vertical-align: middle;
          font-size: 12px;
          font-weight: 400;
          &:not(:last-child) {
            border-right: 1px solid #cbced8;
          }
        }
      }
    }
    .ant-btn[disabled] {
      background-color: $section-color !important;
      color: $white-color !important;
      opacity: 0.5 !important;
      width: 100% !important;
      height: 44px !important;
    }
  }

  .draft-signature-btn-col,
  .draft-additional-signature-btn-col {
    min-height: 6em;
    margin-right: 10px;
    margin-left: 10px;
    font-size: $label-font-size;
    &:hover {
      .signature-btn-wrapper-option {
        visibility: visible;
      }
      .signature-btn-wrapper {
        border: 2px solid $primary-color;
      }
    }
  }

  .draft-additional-signature-btn-col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .additional-signature-btn-col {
    max-width: 100%;
  }
}

.preview-proposal-modal {
  .sign-proposal-button {
    pointer-events: none;
  }
}

.ant-tooltip-content {
  .ant-tooltip-inner {
    color: $primary-color-text !important;
    background-color: $white-color !important;
    text-align: center !important;
  }
}

@include xs {
  .proposal-signaturesection {
    padding: 10px;
    &:hover .cover-buttons > span {
      height: auto;
    }

    .proposal-signaturesection-content {
      display: block;
      .draft-signature-btn-col,
      .draft-additional-signature-btn-col,
      .additional-signature-btn-col {
        margin-top: 1em;
      }
    }
  }
}
