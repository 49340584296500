@import "styles/theme.scss";

.preview-proposal-modal {
  .publish-content {
    background-color: lightgray;
    overflow-y: scroll;
    border: 1px solid lightgray;
    #section-content-container, > div {
      pointer-events: none;
    }
  }
  .preview-cover-modal {
    width: 100%;
    text-align: center;
  }
  .preview-media-modal {
    width: 100%;
    height: 75vh;
  }
  .proposal-button-right {
    float: right !important;
    margin-right: 40px !important;
    &:hover {
      background-color: #F5F4F9 !important;
      border: 0;
      border-radius: 4px !important;
      color: $heading-color !important;
    }
  }
}

.title.hebrew {
  direction: rtl;
}
