@import "styles/theme.scss";

.react-resizable.html-container,
.image-component-container.react-resizable {
  .react-resizable-handle {
    display: none;
    padding: 0;
    background: blue;
    border: 1px solid;
    border-radius: 6px;
    width: 12px;
    height: 12px;
    background-image: none;

    &.react-resizable-handle-w,
    &.react-resizable-handle-e {
      top: 55%;
    }
    &.react-resizable-handle-se,
    &.react-resizable-handle-e,
    &.react-resizable-handle-ne {
      right: -7px;
    }
    &.react-resizable-handle-se,
    &.react-resizable-handle-s,
    &.react-resizable-handle-sw {
      bottom: -8.5px;
    }
    &.react-resizable-handle-nw,
    &.react-resizable-handle-ne {
      top: -7px;
    }
    &.react-resizable-handle-nw,
    &.react-resizable-handle-w,
    &.react-resizable-handle-sw {
      left: -7px;
    }
  }
}

.html-container{
  max-width: 100%;
  &:before{
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    width:calc(100% +  50px);
    height:calc(100% +  35px);
  }
}
.html-content{
  max-width: 100%;
  position: relative;
  & > :first-child {
    @include custom-scrollbar;
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    overflow: auto;
    position: absolute;
    top: 0;
  }
}
