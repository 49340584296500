@import 'styles/theme.scss';

.select-file {
  text-align: center;
  &p {
    font-size: 16px;
    line-height: 150%;
  }
  .select-file-uploader {
    border: 2px dashed $section-color;
    margin-bottom: 1.2em;
    padding: 35px;
    height: 309px;
    text-align: center;
    box-shadow: 0px 5px 20px rgba(4, 3, 49, 0.15);
    border-radius: 4px;
    background-color:$section-background;
    p {
      margin-bottom: .5em;
      color: $primary-color-text;
    }
    .select-file-title {
      font-size: 18px;
      line-height: 140%;
      font-weight: 600;
    }
    .select-file-info {
      font-size: 16px;
      line-height: 150%;
    }
    .select-image-details {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.04em;
    }
    .select-file-details {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.04em;
    }
    .select-file-upload-text {
      justify-content: center;
      padding-top: 25px;
      align-items: center;
      h4{
        margin-bottom: 0;
        color: $primary-color-text;
        border-bottom:  2px solid #{$primary-color-text};
      }
      .select-file-upload-icon {
        padding: 0 10px;
        svg {
          width: 15px;
          path {
            stroke: $secondary-color;
          }
        }
      }
    }
  }
}