@import 'styles/theme.scss';

.whats-new-wrapper {
  position: relative;
  
  .ant-col {
    .ant-row#headway-features {
      display: block;
      overflow-y: scroll;
      @include thin-custom-scrollbar;
	  overflow-x: hidden;
      height: auto;
      font-size: 12px;
      @include rwd-min(1024) {
        font-size: 12px;
      }
      @include rwd-min(1920) {
        font-size: 20px;
      }
      .ant-col {
        .logs-container {
          margin-top: 20px;
          margin-bottom: 18px !important;
          padding-left: 0.7em;
		  @include rwd-max(767) {
			padding-left: 0;
		  }
          .block-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 8px;
          .pulse-dot {
            height: 0.6em;
            width: 0.6em;
            min-width: 0.6em;
            margin-right: 8px;
            border-radius: 2px;
            background-color: rgba(120, 118, 193, 0.8);
          }
        
          .ant-tag {
            background-color: rgba(120, 118, 193, 0.8);
            border-radius: 4em;
            border: none;
            color: white;
            width: 3.4em;
            min-height: 1.5em;
            min-width: 3.4em;
            text-align: center;
          }}
        }
      }
      .HW_badge_cont {
        display: none;
      }
    }
    .divider {
      &:last-child {
        display: none;
      }
    }
  }
  .headway-item {
    padding: 0 0.7em 0 0.7em;
	@include rwd-max(767) {
		padding: 0;
	  }
    .logs-container {
      font-size: 11px;
      text-align: left;
      a {
        color: rgba(255, 255, 255, 1);
        font-weight: 500;
        font-size: 13px;
        text-decoration: none;
        opacity: 0.9;
      }
      .link {
        width: 198px;
        height: 11px;
        
      }
    }
    .hw-title {
      font-size: 11px;
      text-align: right;
    }
    .hw-paragraph {
      color: rgba(255, 255, 255, 1) !important;
      font-size: 13px;
      font-weight: 200 !important;
      margin-left: 1.2em;
      opacity: 0.8;
      margin-bottom: 5px;
    }
  }

  .divider {
    background: rgba(255, 255, 255, 0.2);
    width: 100%;
    height: 1px;
    padding: 0 10px;
    opacity: 0.7;
  }

  .HW_badge_cont.HW_visible {
    visibility: hidden;
    pointer-events: auto;
    display: none;
  }
}

/*! Custom content loader */

@-webkit-keyframes placeload {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}
@keyframes placeload {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}

.content-shape-group {
	width: 100%;
	max-width: 100%;
	.content-shape {
		&:not(:last-child) {
			margin-bottom: .5rem;
		}
	}
}

.content-shape {
	height: 8px;
	width: 100%;
	max-width: 100%;
	border-radius: .25rem;
}

.loads {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-name: placeload;
	animation-name: placeload;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: #eee;
	background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	background-size: 1200px 104px;
  opacity: 0.2 !important;
	position: relative;
}
.placeload-wrap.is-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.placeload-wrap {
	.content-shape-group.is-centered {
		margin-left: auto;
		margin-right: auto;
	}
	.content-shape.is-centered {
		margin-left: auto;
		margin-right: auto;
	}
	.placeload-avatar {
		display: block;
		width: 40px;
		min-width: 40px;
		height: 40px;
	}
	.placeload-avatar.is-small {
		width: 32px;
		min-width: 32px;
		height: 32px;
	}
	.placeload-avatar.is-medium {
		width: 50px;
		min-width: 50px;
		height: 50px;
	}
	.placeload-avatar.is-large {
		width: 68px;
		min-width: 68px;
		height: 68px;
	}
	.placeload-avatar.is-big {
		width: 80px;
		min-width: 80px;
		height: 80px;
	}
	.placeload-avatar.is-centered {
		margin-left: auto;
		margin-right: auto;
	}
}

@media only screen and (max-width: 767px) {
	.placeload-wrap.is-flex {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 1rem 0;
		.content-shape-group {
			margin-top: .5rem;
			max-width: 70%;
			margin-left: auto;
			margin-right: auto;
			.content-shape {
				margin-left: auto;
				margin-right: auto;
			}
		}
		>.content-shape {
			margin-top: .5rem;
			max-width: 70%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
