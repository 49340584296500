@import "styles/theme.scss";

.general-settings-modal {
    .company-logo {
        max-height: 73px;
    }

    .heading {
        color: $label-color-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
        text-transform: capitalize;
        opacity: 0.8;
    }

    .ant-form-item {
        margin-bottom: 0px;
    }

    .sender-helper-text {
        color:$primary-color-text;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        opacity: 0.5;
    }
}