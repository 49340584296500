@import "styles/theme.scss";

.attach-section {
  margin-top: calc(24px - 6em);
  background: white;
  padding: 16px;

  &.attachment-preview {
    margin-top: 24px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -24px;
      left: 0;
      width: 100%;
      height: 24px;
      background-color: $border-color;
    }
  }

  h3 {
    margin-bottom: 24px;
    color: $primary-color-text;
    font-size: $medium-text-font-size;
    font-weight: 600;
  }

  .folder {
    border: 1px solid #{$tab-border};
    padding: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
    border-radius: 3px;

    p {
      margin-bottom: 0;
      margin-left: 10px;
      margin-right: 10px;
      font-size: 16px;
      color: $primary-color-text;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .attachment-empty {
    text-align: center;
    color: $disabled-color;
  }

  .attach-header {
    display: flex;
    justify-content: space-between;
    h3 {
      text-transform: capitalize;
    }
    .add-files {
      color: $primary-color-text;
      font-weight: 600;
      background-color: $tab-background;
      border: none;
      border-radius: 4px;
    }
  }

  .attachments-options {
    padding-bottom: 1em;
    height: 100%;
    &:hover {
      .folder {
        border: 2px solid $section-color;
      }
      .attachments-options-wrapper {
        visibility: visible;
      }
    }

    .folder {
      border: 2px solid transparent;
    }

    .ant-btn {
      flex-grow: 0;
      margin: 0;
      width: 100%;
      cursor: not-allowed;
    }

    .attachments-options-wrapper {
      right: 0.5em;
      position: absolute;
      bottom: -0.5em;
      color: $primary-color-text;
      box-shadow: $box-shadow-dark;
      border-radius: 4px;
      visibility: hidden;
      cursor: pointer;

      &:hover {
        visibility: visible;
      }

      & > span {
        cursor: pointer;
        padding: 4px 6px;
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        font-weight: 400;
        &:not(:last-child) {
          border-right: 1px solid #cbced8;
        }
      }
    }
  }
}

.attachment-popover-icons {
  display: flex;
  align-items: center;
  .anticon.delete svg {
    width: 13px;
  }
}
.delete-attachment{
  padding: 12px 16px;
}