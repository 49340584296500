@import "styles/theme.scss";

.ant-modal.simple-info-modal.test-sign-proposal-modal {
  width: auto !important;
  .body {
    text-align: center;
    text-align: -webkit-center;
    p {
      max-width: 560px;
    }
  }
  .ant-modal-content > .ant-modal-body {
    .ant-btn-loading-icon {
      position: relative;
      margin-left: auto;
    }
    padding: 3em;
    .button-wrapper {
      button {
        width: 100%;
        &:last-child {
          margin-left: 1em;
          color: white;
        }
      }
      button {
        width: 100%;
        &:last-child {
          margin-left: 1em;
        }
      }
    }
  }

  @include md {
    width: 100% !important;
    .ant-modal-content > .ant-modal-body {
      .button-wrapper {
        padding: 0;
        flex-direction: column;
        button {
          margin-bottom: 1em;
          &:last-child {
            margin-left: 0em !important;
            color: white;
          }
        }
      }
    }
  }

  &.language-rtl {
    .ant-modal-content > .ant-modal-body {
      .button-wrapper {
        button {
          width: 100%;
          &:first-child {
            margin-left: 1em;
          }
        }
      }
    }
  }
}