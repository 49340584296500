@import 'styles/theme.scss';

.proposal-header {
  position: fixed;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0em 16px 0em 1em !important;
  border-bottom: solid 1px #e8e8e8;
  background-color: #fff !important;
  box-shadow: 0px 2px 4px rgba(7, 5, 60, 0.1);

  .btn-left {
    margin-left: 10px !important;
  }

  .header-btn {
    border: none;
    color: rgba(0, 0, 0, 0.85) !important;
    box-shadow: unset;
    display: flex;
    border-radius: 4px;

    .anticon {
      height: 24px;
    }

    &>span {
      line-height: 2;
    }

    &:hover {
      background-color: #f5f4f9 !important;
      color: $heading-color !important;
    }
  }

  .switch-toggle-button {
    padding: 3px 5px 3px;
    background: $light-color;
    border-radius: 4px;
    height: 38px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;

    button {
      color: $white-color;
      font-weight: 600;
      font-size: 14px;
      border-color: $secondary-color;
    }

    .anticon {
      padding-top: 2px;
    }

    .ant-dropdown-trigger {
      border-left-color: $white-color;
      .anticon > svg > path {
        fill: $white-color;
      }
    }

    &:hover {
      color: $white-color;
      text-decoration: none !important;
    }

    &:focus {
      text-decoration: none !important;
    }

    .anticon svg {
      display: inline-block;
      overflow: overlay;
      @supports (-moz-appearance: none) {
        overflow: auto;
      }
    }

    .ant-radio-button-wrapper {
      position: relative;
      display: inline-block;
      margin: 0;
      height: 28px;
      color: $primary-color-text;
      font-size: 12px;
      line-height: 30px;
      background: none !important;
      border: 0 !important;
      & > :hover {
        opacity: 0.8;
        color: $primary-color-text !important;
      }
      & > :active {
        opacity: 0.8;
      }
      box-shadow: none !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      z-index: 1;
      color: $text-color !important;
      background: $white-color !important;
      border-radius: 4px !important;
      &:first-child {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      &:last-child {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: $white-color !important;
      border-right-color: 0 !important;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: none !important;
      visibility: hidden !important;
    }

    .ant-radio-group {
      line-height: 0px;
    }

    @include xs {
      padding-top: 6px;
      .ant-radio-group {
        display: flex;
        .switch-toggle-button-text {
          display: none;
        }
      }
    }
    @media (max-width: 985px) {
      .ant-radio-group {
        display: flex;
        .switch-toggle-button-text {
          display: none;
        }
      }
    }
  }

  & > :first-child {
    margin-right: 22px !important;
    @media screen and (min-width: 992px) {
      margin-right: 32px !important;
    }
  }

  .seprator {
    height: 64px;
    width: 1px;
    background-color: $tab-border;
  }

  & > :not(.spacer) {
    flex-grow: 0;

    &:not(.ant-divider):not(.seprator) {
      margin-right: 1em;
      margin-left: 1em;
      @media screen and (min-width: 992px) {
        margin-right: 2em;
        margin-left: 2em;
      }
    }
  }

  & > .spacer {
    flex-grow: 1;
  }

  @media screen and (max-width: 992px) {
    .settings-button {
      span:nth-child(2) {
        display: none;
      }
    }
  }

  .save-proposal.anticon {
    cursor: pointer;
    svg {
      width: 1.7em;
      height: 1.7em;
    }
  }

  .proposal-link-container {
    margin-right: 1em!important;
    .proposal-link-input {
      width: 15em;
      height: 32px;
      border-radius: 4px;
      @media screen and (min-width: 992px) {
        width: 20em;
      }
      &:focus,
      &:hover,
      &.ant-input-affix-wrapper-focused {
        border: 1px solid $border-color-base;
        box-shadow: 0 0;
        outline: none;
      }
    }
  }

  .preview-button,
  .publish-button {
    background: $secondary-color-light;
    border: 2px solid $secondary-color-light;
    border-radius: 4px;
    color: $secondary-color;
    height: 32px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    box-shadow: $light-box-shadow;

    button {
      background: $secondary-color;
      color: $white-color;
      font-weight: 600;
      font-size: 14px;
      border-color: $secondary-color;
    }

    .anticon {
      padding-top: 2px;
    }

    .ant-dropdown-trigger {
      border-left-color: #ffffff63;
      .anticon > svg > path {
        fill: $white-color;
      }
    }

    &:hover {
      background: $secondary-color !important;
      border-width: 2px !important;
      color: $white-color;
      border-style: solid !important;
      border-color: transparent !important;
      border-image: initial !important;
      text-decoration: none !important;
      > .anticon {
        path {
          fill: $white-color;
        }
      }
    }

    &:focus {
      background: $secondary-color !important;
      color: $white-color;
      border-color: transparent !important;
      border-image: initial !important;
      text-decoration: none !important;
    }

    .ant-btn-loading-icon {
      position: absolute;
      left: calc(50% - 8px);
      top: calc(50% - 8px);
      .anticon {
        margin: 0;
      }
    }

    &:disabled {
      background: $input-disabled-color !important;
      border-color: transparent !important;

      .link-text,
      > .anticon {
        opacity: 0;
      }
    }
  }

  .publish-button:not(.publish-button-right) {
    margin-left: 0 !important;
    margin-right: 1em !important;
    @media screen and (max-width: 992px) {
      margin-right: 0 !important;
    }
    &:disabled {
      background: $input-disabled-color !important;
      color: $disabled-color;
      border-color: transparent !important;
      .anticon {
        path {
          fill: $disabled-color;
        }
      }
    }
  }

  .preview-button-right,
  .publish-button-right {
    margin-right: 1em !important;
    @media screen and (max-width: 992px) {
      margin-right: 0 !important;
    }
  }

  .publish-button {
    background: $secondary-color;
    border: 2px solid $secondary-color;
    color: $white-color;

    &:hover {
      background: $secondary-color-hover !important;
      button {
        background: $secondary-color-hover;
        border-color: $secondary-color-hover;
      }
      .ant-dropdown-trigger {
        border-left-color: #ffffff63;
      }
    }

    &:focus {
      background: $secondary-color-hover !important;
    }
  }

  .back-button {
    text-align: right;
    height: 32px;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    background: $light-color !important;
    border: 2px solid $light-color !important;
    color: $primary-color-text !important;
    &:hover {
      opacity: 0.8;
      background: $light-color !important;
      border-color: $light-color !important;
    }
    &.active {
      opacity: 0.8;
      background: $light-color !important;
      border-color: $light-color !important;
    }
  }

  .item-name {
    font-style: normal;
    font-weight: normal;
    font-size: $label-font-size;
    line-height: 140%;
    color: $primary-color-text;
  }

  .icon-with-text {
    display: flex;
    align-items: center;

    & > :first-child {
      margin-right: 7px;
    }
  }

  .undo-redo {
    display: flex;
    .anticon {
      padding: 0 2.5px;
    }
  }

  .ant-btn-group > .ant-btn:first-child {
    z-index: 0;
  }
}

.publish-button-menu {
  .ant-dropdown-menu-item {
    display: flex;
    position: relative;

    .copy-unshortened-link svg {
      height: 28px;
      width: 32px;
      fill: #7876c1;
    }

    .publish-button-menu-title {
      padding: 0.3em;
    }

    &.unpublish-button {
      background-color: $secondary-color;
      color: $white-color;

      &:hover {
        background-color: $secondary-color-hover;
      }

      .anticon {
        svg {
          width: 30px;
          height: 28px;
          path {
            stroke: $white-color;

            &:last-child {
              fill: $white-color;
            }
          }
        }
      }
    }
  }
}

.screen-icons-buttons {
  background: $light-color !important;
  border: 2px solid $light-color !important;
  color: $primary-color-text !important;
  align-items: center !important;
  display: flex !important;
  .anticon {
    font-size: 2em;
    margin-top: 0.15em;
  }
}
