@import "styles/theme.scss";
.integration {
  .integration-header {
    margin-bottom: 2em;
  }
  
  .integration-main-content {
    background-color: $white-color;
    box-shadow: 0px 2px 4px rgba(3, 20, 63, 0.1);
    padding: 3em;
    border-radius: 4px;
  }
}

