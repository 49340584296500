@import "styles/theme.scss";

.covers-library-section .ant-row.library-sections-container {
  > div {
    position: relative;
    box-shadow: 0px 0px 15px #03143f1a;
    margin-bottom: 1.5em;
    padding: 0px !important;
    border-radius: 6px;
    overflow: hidden;

    .section-text {
      padding-top: 15px;
      font-size: 0.41rem;
      min-height: 80px;
      max-height: 200px;
      overflow: hidden;
      position: relative;
      .overlay-mask{
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
    .section-image {
      width: 100%;
    }

    .toolbar-library-section {
      position: relative;

      border-radius: 4px;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
      }
    }

    .ant-row.section-title {
      position: absolute;
      padding: 0;
      bottom: 0;
      height: 30px;
      width: 100%;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      border-top: 1px solid #f0f0f0;
      display: none;
      color: $primary-color-text;
      background: white;
    }

    &:hover {
      .ant-row.section-title {
        display: flex;
      }
    }

    .drag-element-placeholder {
      padding: 0px;
      width: 100%;
      height: 100%;
      margin-top: 0;
    }
  }
}


.library-sections-container{
  .toolbar-library-section {
    cursor: grab;
    .section-text{
      background-color: white;

      .proposalblock-image{
        margin-top:-15px;
        img{
          width: 100%;
        }
      }
    }
  }
}