@import "styles/theme.scss";

.admin-content.container {
  --margin-top : 64px!important;
  padding: 20px;

  .admin-table-wrapper {
    border: 1px solid #d3d3d3;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 3px 2px 14px #88888887;
    margin-bottom: 20px;
    .table-action {
      h3 {
        font-size: 1.5em;
        margin-bottom: 0px;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .ant-table-content {
      overflow: auto;
    }
    .ant-table-thead {
      .ant-table-cell {
        background: #a1a0bd;
      }
    }
    .ant-table-cell.admin-user-deleted {
      &:last-child {
        border-right: 4px solid red;
      }
    }
  }
  .table-row-dark {
    background: #c8eae6;
  }

  @media screen and (min-width:1200px) {
    padding: 40px;
  }
}

.cancellation-form {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px 0px;
  height: 100%;
  border-radius: 8px;
  padding: 3em !important;
  .ant-form-item {
    .ant-col {
      text-align: start !important;
      .ant-radio-wrapper {
        line-height: 2;
      }
      .ant-select-selector {
        border-radius: 5px;
      }
    }
  }
}

.refund-modal-list {
  color: yellow;
  .ant-list-items {
    .ant-list-item {
      .ant-list-item-action {
        button {
          width: 8em;
          height: 44px;
          box-sizing: border-box;
          border-radius: 4px;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          background: $secondary-color;
          color: white;
          &:disabled {
            background: $input-disabled-color;
            color: lightgrey;
          }
        }
      }
    }
  }
}
