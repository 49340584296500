@import 'styles/theme.scss';

.signup-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  @include rwd-min(640) {
    flex-direction: row;
  }

  .signup-left {
    height: auto;
    min-height: 570px;
    width: 100%;
    align-items: center;
    flex-direction: column;
    display: flex;
    padding-top: 1.5em;
    padding-bottom: 2.5em;
    background-color: white;

    @include rwd-min(768) {
      width: 384px;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }

    @include rwd-min(1024) {
      width: 560px;
      padding-bottom: 2.5em;
    }

    @include rwd-max(767) {
      padding: 1.5em 1.8em;
    }

    @include rwd-min(640) {
      border-radius: 0.5rem;
    }

    .heading-wrap {
      text-align: center;
      .heading {
        font-size: 1.5em;
        font-weight: 600;
        color: rgba(5, 3, 77, 1);
        margin-bottom: 0;
        display: inline-block;
        @include lg {
          font-size: 2.2em;
        }
        @include rwd-min(768) {
          font-size: 1.8em;
        }
        @include rwd-min(1024) {
          font-size: 2.3em;
        }
        @include rwd-min(2560) {
          font-size: 2.6em;
        }
        @include rwd-max(767) {
          font-size: 1.875rem;
        }
        @include rwd(320) {
          font-size: 1.5rem;
        }
      }
      .sub-heading-text {
        font-size: 0.9em;
        font-weight: 300;
      }
      .color-divider {
        background: $secondary-color;
        width: 32px;
        height: 1px;
        padding: 0 10px;
        margin: 0 auto;
      }
    }
  }
  .signup-right {
    height: auto !important;
    width: 100%;
    flex-direction: column;
    display: flex;
    margin-left: 1.3em;
    background-color: rgba(5, 3, 77, 1);
    overflow: hidden;

    @include rwd-min(768) {
      width: 296px;
    }

    @include rwd-min(1024) {
      width: 340px;
    }

    @include rwd-max(767) {
      display: none;
      padding: 1.5em 1.5em 2em;
      margin-left: 0;
      min-height: 300px;
      background-color: transparent;
    }

    @include rwd-min(640) {
      border-radius: 0.5rem;
    }

    .heading-wrap { 
      padding: 1.7em 2em 0.7em;
      @include rwd-max(767) {
        padding: 1em;
      }

      .heading {
        font-size: 1em;
        font-weight: 500;
        color: #fff;
        display: inline-block;
        @include rwd-min(768) {
          font-size: 1em;
        }

        @include rwd-min(1024) {
          font-size: 1.1em;
        }

        @include rwd-max(767) {
          font-size: 1.3em;
        }

        @include rwd-min(2560) {
          font-size: 2em;
        }
      }
      .sub-heading-text {
        font-size: 0.9em;
        font-weight: 300;
      }
      .color-divider {
        background: white;
        width: 40px;
        height: 1px;
        padding: 0 10px;
      }
    }
  }
  .header-signup {
    padding: 20px;
    font-size: 14px;
    a {
      color: $primary-color;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  h3 {
    font-size: 1.5em;
    font-weight: 600;
    color: $primary-color-dark;
    margin-bottom: 0.5em;
    display: inline-block;
  }
  .cancel-text {
    margin-top: 10px;
    font-size: 0.75em;
    color: $placeholder-color;
  }
  .signup-btn {
    padding: 8px;
    width: 100%;
    height: 44px;
    margin-top: 20px;
    background: $secondary-color;
    border: none;
    font-size: 16px;
    box-sizing: border-box;
    box-shadow: 0px 5px 20px rgba(241, 92, 60, 0.2);
    border-radius: 6px;
    font-weight: 600;
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .spacer {
      padding: 5px;
    }
    span.anticon {
      height: 28px;
    }
    span.anticon-loading {
      padding-right: inherit;
      height: inherit;
      svg {
        width: 2em;
        height: 1.5em;
      }
    }
    &:hover,
    &:focus {
      opacity: 0.85;
      background: $secondary-color;
      border: none;
    }
  }
  .login-divider {
    margin: 30px 0;
    text-align: center;
    height: 0;
    border-top: 1px solid rgba(230, 230, 240, 1);
    color: rgba(147, 147, 157, 1);
    .inner-login-divider {
      margin: 0 auto;
      margin-top: -12px;
      width: 120px;
      background: #fff;
      font-size: 12px;
    }
  }
  .google-login-button {
    padding: 8px;
    width: 100%;
    height: 40px;
    display: flex;
    border: 1px solid $tab-border !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    justify-content: center;
    div {
      padding-right: 2px !important;
      height: 40px;
    }
    .google-login-icon {
      margin: 1px 2px 0 -1px;
    }
    .button-text {
      margin-top: 2px;
      margin-bottom: 10px;
      vertical-align: middle;
      color: $label-color-light;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      font-family: Poppins, sans-serif;
    }
    &:hover {
      box-sizing: border-box !important;
      box-shadow: 0px 5px 20px rgba(241, 92, 60, 0.2) !important;
    }
  }
  @media screen and (min-width: $screen-lg-min) {
    .signup-wrapper .logo a {
      display: none;
    }
  }
  .login-link {
    margin-top: 20px;
    text-align: center;
    font-size: 13px;
    span {
      font-weight: 500;
      text-decoration: none;
      color: rgba(240, 63, 59, 1);
      cursor:pointer;
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 1.5rem;

    @include xs {
      margin-top: 2.5rem;
    }

    @include rwd-min(576) {
      max-width: 370px;
    }

    @include rwd-min(768) {
      max-width: 285px;
    }

    @include rwd-min(1024) {
      max-width: 380px;
    }
    
  }
  .email-input {
    .ant-form-item-label {
      padding-bottom: 4px;
      color: $placeholder-color;
    }
  }
  .error-message {
    color: $form-error-color;
    margin-top: 26px;

    @include rwd-min(1024) {
      font-size: 16px;
    }
    @include rwd-min(2560) {
      font-size: 20px;
    }
  }
  .ant-form {
    margin-bottom: 0px;
  }
  .ant-form-item {
    margin-bottom: 0px;
    @include rwd(320) {
      margin: 0;
      flex-direction: row;
    }
    .ant-form-item-required::before {
      content: '';
      margin-right: 0px;
    }
    .ant-form-item-label {
      label {
        color: $label-color-light;
        opacity: 0.8;
        font-size: 0.875em;
        font-weight: 300;
        @include rwd-min(1440) {
          font-size: 13px;
        }
        &:before {
          display: none !important;
        }
      }
    }
    .ant-input {
      border-radius: 4px;
      padding: 6px 11px;
      &::placeholder {
        color: rgba(79, 78, 123, 1);
        opacity: 0.2;
      }
    }
    .ant-input-password {
      border-radius: 4px;
      padding: 3px 11px 0 0;
      .ant-input {
        &:placeholder-shown {
          padding: 5px 0 5px 11px !important;
        }
      }
    }
  }

  .signup-title {
    text-align: center;
  }
  .signup-card {
    background: $border-color;
    margin-top: 78px;
    @media (max-width: 900px) {
      width: 480px;
    }

    .signup-form {
      background-color: rgb(255, 255, 255);
      box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px 0px;
      height: 100%;
      border-radius: 8px;
      padding: 40px;
      .signup-input {
        box-sizing: border-box;
        display: flex;
        height: 36px;
        width: 100%;
        background-color: #f4f5f6;
        font-weight: 300;
        letter-spacing: normal;
        color: $form-text-color;
        font-size: 14px;
        border-radius: 4px;
        #basic_password {
          background-color: rgb(244, 245, 246);
        }
        @media (min-width: 2560px) {
          font-size: 60px;
          padding: 40px;
        }
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $primary-color;
        border-color: $primary-color;
      }
      a {
        color: $primary-color;
      }
    }
  }

  .password-label {
    .ant-form-item-label {
      margin-top: 15px !important;
      .ant-form-item-required {
        width: 100%;
      }
      .custom-label {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .forgot-password {
          font-weight: 400;
          color: rgba(240, 63, 59, 1);
          font-size: 0.8em;
          text-decoration: none;
        }
        a {
          text-decoration: underline;
          color: $primary-color-text;
          font-size: $sub-font-size;
        }
      }
    }
  }
  .remember-me {
    margin-top: 10px;
    color: $primary-color-text;
    font-size: $sub-font-size;
  }

  @include rwd-min(1440) {
    .ant-input-affix-wrapper {
      font-size: 1em;
    }
    .ant-checkbox + span {
      font-size: 1em;
    }
    .testimonial .testimonial-content p {
      position: relative;
      font-size: 1.125em;
      line-height: 1.3em;
      top: -5%;
    }
    .signup-container .password-label .ant-form-item-label .custom-label a {
      text-decoration: underline;
      font-size: 30px;
    }

    .footer {
      position: fixed;
      width: 58.33%;
      background: #ffffff !important;
      bottom: 0;
      margin-bottom: 0px;
      padding-top: 10px;
      padding-bottom: 20px;
      text-align: center;
      a {
        text-decoration: none;
        margin: 25px;
        color: $primary-color-text;
        text-align: center;
        opacity: 0.65 !important;
        &:hover {
          opacity: 1 !important;
          text-decoration: underline !important;
        }
      }
      @include rwd-land(1024, 500) {
        margin-bottom: 0px;
      }
    }
  }

  @include rwd(320) {
    .ant-form-vertical .ant-form-item {
      flex-direction: row;
    }
    .signup-container .password-label .ant-form-item-label {
      margin-top: 1px !important;
    }
    .signup-container .signup-btn .spacer {
      width: 28px;
      margin-right: 80px;
    }
    .ant-btn > span + .anticon {
      margin-left: 100px;
    }
  }
}

.ant-form-item-explain-error {
  div {
    font-size: 12px;
  }
}