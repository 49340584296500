@import 'styles/theme.scss';

.dashboard-modal {
  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-body {
    padding: 50px 80px;

    @include rwd(640) {
      padding: 50px 20px;
    }

    img {
      width: 237px;
      height: 160px;
      margin: 25px;
      border-radius: 0.5rem;
      background: #F6F5FB;
      box-shadow: 0px 11px 30px 0px rgba(23, 20, 115, 0.15);
    }

    .modal-container {
      text-align: center;

      .chatway-icon {
        svg {
          height: 50px;
          width: 50px;
        }
      }

      h1 {
        font-weight: 600;
        font-size: 24px;
        color: $primary-color-dark;
      }

      .sub-heading {
        font-size: 16px;
        color: #010032;
        margin-bottom: 30px;
      }

      .color-divider {
        background: $secondary-color;
        width: 32px;
        height: 1px;
        padding: 0 10px;
        margin: 10px auto;
      }

      .type-selector {
        cursor: pointer;
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 30px;

        @include rwd(640) {
          display: block;
        }



        h2 {
          font-size: 1.25em;
          font-weight: 600;
          margin-top: 20px;
          margin-bottom: 20px;
          font-size: 20px;
        }

        .circle {
          height: 160px;
          width: 160px;
          border-radius: 50%;
          margin: auto;
          margin-top: 30px;
          margin-bottom: 37px;
        }

        .explainer {
          font-weight: 300;
          font-size: 0.875em;
          margin: -20px 25px 0px;

          b {
            font-weight: 600;
          }
        }

        .box {
          box-sizing: border-box;
          border-radius: 4px;
          width: 289px;

          .explainer {
            color: #010032;
            opacity: 0.8;
            font-size: 14px;
            margin-top: 8px;
            margin-bottom: 20px;
        }

        h2 {
          color: $primary-color-dark;
        }

        .circle {
          background: $white-color;
          box-shadow: 0px 13px 45px rgba(186, 186, 187, 0.25);
        }

        background: $white-color;
        border: 1px solid #c4c4c4;

        &:hover {
          background: $primary-color;
          border: 1px solid $primary-color;
          box-shadow: 0px 20px 25px rgba(47, 44, 136, 0.25);

          h2 {
            color: $white-color;
          }

          .explainer {
            color: $white-color;
          }
        }
      }

      .step {
        h2 {
          margin-bottom: 0px;
        }

        @include rwd(640) {
          margin-bottom: 10px;
          margin-right: 1px;
        }
      }
    }

    .modal-container-import {
      margin-top: 32px !important;

      .cursor-import {
        cursor: pointer;
        color: #7876C1 !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        .plus-import {
          font-size: 16px;
          font-weight: bold;
        }

        &:hover {
          background: rgba(199, 188, 228, 0.2);
          border-radius: 4px !important;
          padding: 8px 12px !important;
          color: #2F2C88 !important;
        }

        .plus-import {
          margin-top: 15px;
          margin-right: 6px !important;
        }
      }
    }
  }
}
}

.anticon.close-icon {
  &:hover {
    background: $light-color;
    border-radius: 50%;
    padding: 4px 4px;
  }
}