@import 'styles/theme.scss';

.dashboard-multi-select {
  width: calc(100% - 20px);
  max-width: 450px;
}


.status-tags-filter {
  display: flex;

  .item-text {
    font-size: $label-font-size;
    color: $primary-color-dark;

    &.hide-multi-checkbox {
      .ant-checkbox-inner {
        display: none;
        border-color: none !important;

        &:hover {
          border-color: none !important;
        }
      }
    }
  }

  .ant-checkbox+span {
    padding-right: 0.75em;
    padding-left: 0.75em;
    text-transform: unset;
  }


  .status-menu {
    width: 40%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .tag-menu {
    flex: 1;
    overflow: hidden;
  }

  .title {
    font-weight: 600;
    color: $primary-color-text;
    padding-left: 5px;
  }

  .tags-title {
    .count {
      font-size: 0.8em;
      padding-left: 5px;
    }
  }

  .menu-header {
    padding: 8px;
    height: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tag-menu {
    display: flex;
    flex-direction: column;

    .menu-item-wrapper {
      flex: 1;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      @include custom-scrollbar;
    }
  }

  .tag-search {
    .ant-input-affix-wrapper {
      background-color: transparent;
      border: none !important;
    }

    .ant-input-affix-wrapper {
      box-shadow: unset !important;
    }
  }

  .radio-toggle {
    display: flex;
    background-color: $light-color;
    padding: 3px;
    border-radius: 6px;

    .ant-radio-button-wrapper {
      border: none !important;
      height: 20px;
      background-color: transparent;
      opacity: 0.6;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      padding: 0;
      border-radius: 4px;

      &:before {
        display: none;
      }

      &.ant-radio-button-wrapper-checked {
        background-color: $white-color;
        opacity: 1;
      }

      .switch-toggle-button-text {
        color: $primary-color-text;
        font-size: 12px;
      }
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  .menu-item-wrapper {
    padding: 10px 0;

    .menu-item {
      padding: 3px 0;
    }
  }

  .border-bottom {
    border-bottom: 1px solid $tab-border;
  }

  .border-right {
    border-right: 1px solid $tab-border;
  }



}