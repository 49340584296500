@import "styles/theme.scss";

.plans {
  .plans-header{
    margin-bottom:2em;
  }

  .plans-info,
  .billing-info {
    width:100%;
    background: $white-color;
    border-radius: 5px;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1.72em;
    padding: 2.3em 4.45em;
    text-align: center;

    .ant-row {
      justify-content: center;
      margin-bottom: 1em;
    }
  }
}
