@import 'styles/theme.scss';

.trial-end-header {
  top: 0;
  position: fixed !important;
  width: 100%;
  z-index: 2;

  background-color: $secondary-color-light !important;
  height: 56px;
  padding: 0 !important;

  .ant-page-header-content {
    padding-top: 0;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $primary-color-text;
    margin: 0 auto;
    display: flex;

    p {
      margin: 1em;
    }

    button {
      margin: 11px 0.5em;
      background: $secondary-color;
      border: 2px solid $secondary-color;
      box-sizing: border-box;
      box-shadow: $light-box-shadow;
      border-radius: 4px;

      span {
        color: white;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
      }
    }
  }
}