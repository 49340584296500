.skelton {
  height: 10px;
  width: 80%;
  background: rgba(rgb(233, 233, 233), 0.8);
  border-radius: 12px;

  background-image: linear-gradient(
    90deg,
    rgba(lightgrey, 0) 0,
    rgba(lightgrey, 0.8) 50%,
    rgba(lightgrey, 0) 100%
  );
  background-size: 80% 100%;
  background-position: -350% 0;

  background-repeat: no-repeat;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  to {
    background-position: 450% 0;
  }
}
