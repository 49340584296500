@import "styles/theme.scss";

.simple-info-modal.slack-notification-modal {
  width: 43em !important;
  .ant-modal-content {
    .ant-modal-body {
      padding: 2.5em;

      .title {
        margin-top: 0;
        justify-content: flex-start;
      }

      .divider {
        margin: 1em 0;
      }

      .body {
        text-align: left;

        .ant-form {
          .ant-form-item-label {
            padding: 0;
            label {
              color: $label-color-light;
            }
          }

          .slack-notification-button-row {
            margin: 0.6em 0em 0em 0em;
            .ant-col {
              border: 1px solid $tab-border;
              border-radius: 4px;
              margin: 0em 1em 1em 0em;
              width: 14em;

              &:hover,
              &:focus {
                border: 1px solid $primary-color-light !important;
              }

              .ant-checkbox-wrapper.slack-notification-checkbox {
                font-weight: 400;
                font-size: 14px;
                color: $primary-color-text;
                padding: 0.65em;
                width: 100%;
                &:hover,
                &:focus {
                  .ant-checkbox-checked {
                    &::after {
                      border-color: $primary-color-dark !important;
                    }
                  }
                  .ant-checkbox-input, .ant-checkbox-inner {
                    border-color: $primary-color-light !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-modal-footer {
      padding: 1em 1.5em;
      .button-wrapper {
        button.ant-btn {
          height: 44px;
        }
      }
    }
  }

  .anticon.close-icon {
    &:hover {
      background: $light-color;
      border-radius: 50%;
      padding: 4px 4px;
    }
  }
}