.table-action {
    .actions {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
    }

    .search-input {
        width: 200px;
    }
}

.spam-proposals {
    .proposal-card {
        &:not(:last-child) {
            margin-bottom: 10px;
        }

        position: relative;
        background: #f5f6fa;
        border-radius: 8px;
        padding: 20px;

        .info-row {
            display: flex;
            gap: 10px;
        }

        .name {
            font-size: 1.2em;
            font-weight: 500;
        }

        .state {
            position: absolute;
            background-color: #7876c1;
            color: #fff;
            right: 10px;
            padding: 0 7px;
            border-radius: 4px;
        }

        .spam-links {
            margin-top: 10px;
            display: block;
        }

        .url {
            display: inline-block;
            padding: 2px 5px;
            border-radius: 6px;
            background-color: #eceef4;
            margin: 0 5px 5px 0;
            color: #5e5e5e;

            a {
                color: inherit;
            }

            &:hover {
                background-color: #e2e4ee;
            }

            &.whitelisted {
                text-decoration: line-through;
                opacity: 0.6;
            }
        }
    }

    .ant-spin {
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}