@import "styles/theme.scss";

.covers-library-section .library-covers-container {
  .ant-col {
    .ant-card-bordered {
      cursor: grab;

      .ant-card-cover > div {
        height: 8.5em;
        width: 100%;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
      }
      
      .ant-card-actions {
        display: none;
        position: absolute;
        width: 100%;
        bottom: 0;
        li {
          margin: 0;
          font-size: 12px;
          line-height: 140%;
          color: $primary-color-text;
        }
      }

      &:hover {
        .ant-card-actions {
          display: block;
        }
      }

      .drag-element-placeholder {
        padding: 0px;
        width: 100%;
        height: 100%;
        margin-top: 0;
      }
    }
  }
}
