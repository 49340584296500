.language-config-container {
  .items-container {
    margin-top: 2em;
  }
  .field {
    border: 1px solid lightgray;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 3px 2px 14px #88888887;
    background-color: white;
    margin-bottom: 5%;
    span {
      display: block;
      text-transform: capitalize;
      font-weight: 900;
    }
    textarea {
      width: 100%;
      height: 100px;
      margin-top: 2%;
    }
  }
}
