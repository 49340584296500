@import 'styles/theme.scss';

.form-view {
  .form-view-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12.8px;
    padding: 20px 20px 0 20px;
    h3 {
      line-height: 1.4;
      font-size: 1.2em;
      text-align: left;
    }
  }

  .form-view-fields-row {
    @include custom-scrollbar;
    & > div {
      width: 100%;
    }
    .form-list-two-columns {
      display: contents;
      .form-list-item {
        width: 45%;
        &:nth-child(odd) {
          margin-right: 1em;
          .input-container {
            &.handle {
              width: 90%;
            }
            width: 100%;
          }
        }
        &:nth-child(even) {
          margin-left: 1em;
          .input-container {
            &.handle {
              width: 90%;
            }
            width: 100%;
          }
        }

        span.form-error-text {
          width: 90%;
        }
      }
    }
    .form-list-items {
      .form-list-item {
        align-items: flex-start;
        flex-direction: column;
        border-bottom: none;
        margin-bottom: 16px;
        margin-top: 16px;
        text-align: left;
      
        .form-error-text {
          width: 95%;
        }

        .ant-checkbox-wrapper {
          float: right;
          margin-top: 4px;
          margin-right: -8px;
          color: $primary-color-text;
          .ant-checkbox {
            &::after {
              border-color: $primary-color;
            }
            .ant-checkbox-inner {
              border-color: $primary-color;
            }
          }
        }
        label {
          padding-left: 26px;
          font-weight: 500;
          font-size: 0.8em;
          line-height: 1.4;
          color: $form-text-color;
          font-style: normal;
          margin-bottom: 8px;
        }
        .input-container {
          width: 100%;
          &.handle {
            display: inline-block;
            margin-left: -6px;
            width: 96%;
          }
          padding-left: 20px;
          margin-left: 0em;
          border-radius: 4px;
          margin-bottom: 0;
          input {
            width: 100%;
            min-height: 48px;
            background: $form-input-background-color;
            border: none;
            font-style: normal;
            font-size:0.8em;

            &::placeholder {
              color: $form-input-placeholder-color;
              opacity: 0.3;
              font-style: normal;
            }
            &.error-input {
              border: 1px solid;
              border-color: $form-error-color !important;
            }
          }
        }
        .editable {
          margin-left: 17.6px;
        }
        .handle-draggable {
          display: inline-block;
          transform: translateX(4px);
          position: relative;
          cursor: grab;
          opacity: 0.7;
          width:8px;
          &:hover {
            opacity: 1;
          }
          &:before {
            content: '';
            height: 100%;
            width: 40px;
            position: absolute;
            z-index: 0;
            left: -16px;
          }
          span {
            position: absolute;
            z-index: 1;
            top: calc(50% - 12px);
          }
        }

        .required-editable {
          margin-left: 4px;
        }
      }
    }
  }
}

.edit-container.hebrew, .edit-container.arabic {
  .input-container {
    input {
      text-align: right;
    }
  }
}

@media screen and (max-width: 800px) {
  .form-list-two-columns {
    .form-list-item {
      width: 100% !important;
      &:nth-child(odd) {
        margin-right: 0em !important;
      }
      &:nth-child(even) {
        margin-left: 0em !important;
      }
    }
  }
}

.required {
  color: $form-error-color;
  margin-left: 4px;
}

.handle-draggable {
  display: inline-block;
  &.hebrew, &.arabic {
    margin-left: 20px;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $primary-color !important;
    border: none !important;
  }
}

.form-field-wrapper {
  display: flex;
  justify-content: space-between;
}

.language-rtl {
  .form-list-item {
    .input-container {
      padding-right: 25px;
    }
    .handle-draggable {
      transform: translateX(-8px) !important;
    }
  }
}



.small-font {
  .form-view {
    font-size: 12px;
  }
}

.max-font {
  .form-view {
    font-size: 36px;

    .form-list-item {
      label,
      .required {
        font-size: 0.6em !important;
      }
    }
  }
}