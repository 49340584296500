@import "./breakpoint.scss";
// Extra Small devices
@mixin xs {
  @media (max-width: #{$screen-xs-min}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (max-width: #{$screen}px) {
    @content;
  }
}

@mixin rwd-min($screen) {
  @media (min-width: #{$screen}px) {
    @content;
  }
}

@mixin rwd-land($vscreen, $hscreen) {
  @media only screen and (min-device-width: #{$vscreen}px) and (min-device-width: #{$hscreen}px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    @content;
  }
}

@mixin rwd-land-max($vscreen, $hscreen) {
  @media only screen and (max-device-width: #{$vscreen}px) and (max-device-width: #{$hscreen}px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    @content;
  }
}

@mixin rwd-max($max-width) {
  @media (max-width: #{$max-width}px) {
    @content;
  }
}

@mixin rhd($screen) {
  @media (max-height: #{$screen}px) {
    @content;
  }
}