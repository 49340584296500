@import "styles/theme.scss";

.invalid-connection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  .ant-empty-description {
    span {
      color: $primary-color-text;
    }
  }
}