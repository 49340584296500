@import 'styles/theme.scss';

.rich-editor-price-edit {
  .ant-modal {
    width: 100% !important;
    max-width: 100% !important;
    height: var(--height-100);
    top: 0;
    padding: 0 !important;
    margin: 0 !important;

    @media screen and (min-width: 1025px) {
      width: calc(100vw - 100px) !important;
      max-width: 1400px !important;
      height: calc(var(--height-100) - 60px);
      margin: 30px auto !important;
    }

    .rich-editor-price-edit-header {
      .title {
        margin-top: 0;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        color: $primary-color-text;

        .rich-editor-title-info {
          background: $background-color;
          border-radius: 32px;
          padding: 0.2em 1em;
          font-size: $label-font-size;
          margin-left: 1em;
          font-weight: 500;
          vertical-align: middle;
        }
      }

      .ant-divider {
        background: $secondary-color;
        width: 32px;
        height: 1px;
        margin: 16px 0;
        min-width: 0;
      }
    }

    .ant-modal-content {
      display: flex;
      flex-direction: column;
      .ant-modal-body {
        padding: 0;
        flex: 1 1 auto;
        .tablist-container .tablist-content {
          max-height: calc(var(--height-100) - 193px);

          @media screen and (min-width: 1025px) {
            max-height: calc(var(--height-100) - 250px);
          }
        }
      }
      .ant-modal-footer {
        padding: 0;
        border-top: 1px solid $tab-border;
        z-index: 11;
      }

      @media screen and (min-width: 1025px) {
        height: calc(var(--height-100) - 100px);
      }
    }

    .rich-editor-modal-footer {
      .rich-editor-modal-continue-disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &.rich-editor-price-edit-hebrew {
    .tablist-container {
      .tablist-prelist-container {
        .tablist-prelist {
          .tablist-prelist-input {
            .input-container {
              input,
              .ant-input-affix-wrapper {
                direction: rtl;
              }
            }
          }

          .tablist-prelist-content {
            .ant-list-item {
              direction: rtl;
              text-align: right;
            }
          }
        }
      }

      .tablist-content {
        .tablist-sortlist-container {
          .tablist-sortlist-content {
            .tablist-input-container {
              .input-container,
              .select-container {
                .ant-row {
                  text-align: right;
                  .input-label {
                    direction: rtl;
                    width: 100%;
                  }
                }
                input,
                textarea,
                .ant-select-selection-placeholder {
                  direction: rtl;
                }
              }
            }

            .super-textarea,
            .miles-input,
            .item-name-wrap,
            .tablist-input-description {
              text-align: right;
            }
            .super-textarea textarea {
              padding-right: 0 !important;
            }
          }
        }
      }
    }
  }
}
