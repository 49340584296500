@import "styles/theme.scss";

.sign-type-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: $light-color;

    .sign-tab {
        flex: 1;
        text-align: center;
        padding: 8px;
        cursor: pointer;

        span {
            color: $link-color;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;

            @media screen and (max-width: 500px) {
                font-size: 14px;
            }
        }

        &.active {
            background: #DADBEB;
            border-radius: 8px;
        }
    }
}


.sig-draw {
    canvas  {border: 1px solid $tab-border;}
}

.upload-signature.language-rtl {
   .ant-modal-close {
    left:  0 !important;
    right: unset !important;
   }
}

.upload-icon > svg {
    fill: $link-color;
}

.upload-signature .button-wrapper {
    display: flex;
    align-items: center;


    @media screen and (max-width: 499px) {
        flex-direction: column;

        .m-hide {
            display: none !important;
        }

        .button.confirm {
            width: 100% !important;
        }

        .m-reset-wrapper {
            width: 100%;
            gap: 16px;
            margin-top: 16px;

            >button {
                flex: 1;
                margin: 0 !important;
            }

            .anticon, svg {
                height: 22px;
                width: 22px;
            }

            svg {
                :first-child {
                    stroke:#2559F6;
                } 

                :last-child {
                    fill:#2559F6;
                }
            }

            .reset {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    @media screen and (min-width: 499px) {
        > button {
            margin-right: 8px;
        }
        .anticon  {
            margin-left: 16px;
        }
        .m-show {
            display: none !important;
        }
    }
}

.signature-text{
    padding-top: 29px !important; 
}