@import "styles/theme.scss";

.simple-info-modal.client-sign-modal {
  .ant-modal-body {
    padding: 3em 4em;
    .title {
      margin-top: 0em;
    }
  }

  .body-container {
    .body {
      margin: 1em 2em 0em 2em !important;
      font-size: $small-font-size;
      .subtitle-font {
        font-size: $label-font-size;
        margin-bottom: 0;
      }
    }
    > div {
      margin: 0 2em !important;
    }
  }

  .button-wrapper {
    margin-top: 0em;
    flex-direction: column;
    padding: 0;
    .ant-btn {
      background: $section-color;
      border: 2px solid $section-color;
      box-sizing: border-box;
      box-shadow: $download-box-shadow;
      border-radius: 4px;
      width: auto;
      height: 44px;
    }
    > span {
      color: $primary-color-text;
      margin-top: 1em;
      height: 1em;
    }
  }
}
