@import "styles/theme.scss";

$section-padding: 32px;

.library-menu {
  background-color: $white-color !important;
  margin-bottom: 3em;
  .ant-list-item {
    padding-left: $section-padding;
    padding-right: $section-padding;
  }

  .covers-library-section {
    .folder-path,
    .folder,
    .ant-row {
      padding-left: $section-padding;
      padding-right: $section-padding;
    }
  }

  .user-cover-content {
    .user-cover-image {
      border-radius: 10px;
      cursor: pointer;
      -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.3s;
      transition: box-shadow 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
      height: 120px;
      width: 100%;
      margin-top: 5%;
    }

    .user-cover-image:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    }
  }

  .content-library-card {
    border-radius: 10px;
    margin-top: 5%;

    .ant-card-hoverable {
      cursor: pointer;
      -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.3s;
      transition: border-color 0.3s, -webkit-box-shadow 0.3s;
      transition: box-shadow 0.3s, border-color 0.3s;
      transition: box-shadow 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
    }

    .ant-card-body {
      padding: 0 !important;
    }
  }

  .library-list-item {
    cursor: pointer;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $primary-color-text;
    .anticon {
      svg {
        width:12px;
        height: 12px;
        path {
        fill: $primary-color-light;
      }
    }
    }
  }
}
