.sketch-picker {
  & > div:nth-child(3) {
    padding: 10px 0 !important;
    & > div:not(:first-child):not(:nth-child(2)) {
      padding-left: 3px !important;
    }
    input {
      width: 100% !important;
      font-size: 12px !important;
      box-shadow: inset 0px 0px 0px 1px rgba(9, 9, 9, 0.09) !important;
      border-radius: 4px;
      text-align: center;
      height: 28px;
      color: #777;
      &:focus{
        outline:1px solid #005aff;
      }
    }
    label {
      height: 20px;
      line-height: 20px;
      padding: 0 !important;
    }
  }
}

.ant-popover{
  .sketch-picker{
    box-shadow: none !important;
  }
}