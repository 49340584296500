@import "styles/theme.scss";

.rich-editor-gallery-edit.simple-info-modal.select-box {
  .ant-modal {
    width: 50% !important;
    .ant-modal-content {
      .ant-modal-body {
        padding: 40px 130px;
      }
    }
  }
}

.rich-editor-gallery-edit.simple-info-modal {
  .ant-modal {
    width: 90% !important;
    .ant-modal-content {
      .ant-modal-header {
        border-bottom: none;
        .ant-modal-title {
          .title {
            margin-top: 0;
            justify-content: flex-start;
            font-weight: 600;
            font-size: 24px;
            line-height: 140%;
            color: $primary-color-text;
          }

          .ant-divider {
            background: $secondary-color;
            width: 32px;
            height: 1px;
            margin: 16px 0;
            min-width: 0;
          }
        }
      }
      .ant-modal-body {
        // height: 70vh;
        .gallery-drop-image-container {
          display: flex;
          justify-content: space-between;

          .gallery-images-container {
            border: 1px solid lightgray;
            width: 563px;
            display: flex;
            justify-content: center;
          }

          .dropzone {
            min-height: 50px;
            width: 563px;
            cursor: pointer;
            margin: 0 auto;
            padding-top: 0;
            transform: scale(1.03);
            border-radius: 2px;
            transform-origin: center;
            display: flex !important;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            display: block;
            margin-right: 0px;
            margin-left: 0px;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.58);
            opacity: 1;
            transition: opacity 350ms ease;
            text-align: center;

            p {
              color: white;
              text-align: center;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
      button {
        width: auto;
        height: 44px;
      }
    }
  }
}
