@import "styles/theme.scss";
.user-item {
  margin-top: 1em;
  padding-left: 1em;
  padding: 8px;
  min-height: 80px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(3, 20, 63, 0.1);
  border-radius: 4px;
  &:hover {
    box-shadow: 0 10px 20px 0 rgba(168, 182, 191, 0.6);
    transform: translateY(-1px);
  }
  p {
    margin-bottom: 0;
  }

  p,
  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-avatar-circle {
    border-radius: 50%;
    overflow: hidden;
    margin-left: 0.5em;
    margin-right: 0.5em;
    box-shadow: $box-shadow;
  }

  .user-info,
  .user-position,
  .user-proposal,
  .user-created {
    padding-right: 10px !important;
    padding-left: 10px !important;
    .position,
    .proposal,
    .created {
      font-size: $label-font-size;
      color: $primary-color-text;
    }
    .position-title {
      color: $primary-color-08-opacity;
      font-size: $sub-font-size;
    }
    .position-value,
    .proposal-value,
    .created-value {
      font-weight: 600;
      font-size: $small-font-size;
      line-height: 140%;
      text-transform: capitalize;
    }
  }

  .user-created {
    padding-right: 0;
  }

  .user-info {
    h4 {
      font-weight: normal;
      font-size: $small-font-size;
      line-height: 150%;
      color: $primary-color-text;
      margin: 0;
      text-transform: capitalize;
    }

    p {
      font-weight: 600;
      letter-spacing: 0.04em;
      font-size: $sub-font-size;
      color: $primary-color-text;
    }
  }
  .last-col {
    display: flex;
    align-items: center;

    & > div:first-child {
      flex-grow: 1;
    }

    & > div:last-child {
      flex-grow: 0;
      flex-shrink: 1;
    }
  }

  .user-actions {
    justify-self: flex-end;

    & > :first-child {
      margin-right: 8px;
    }
  }

  .ant-btn {
    background: $white-color;
    border: none;
    box-shadow: $box-shadow-dark;
    border-radius: 4px;
    font-weight: normal;
    font-size: $label-font-size;
    line-height: 140%;
    color: $primary-color-text;
    &:hover,
    &:active,
    &:focus {
      background: $tab-background;
      color: $primary-color-text;
    }
  }
}

.users-menu-items {
  border-radius: 4px;
  .ant-dropdown-menu-item {
    padding: 9px 20px;
    color: $primary-color-text;
    &.ant-dropdown-menu-item-disabled {
      opacity: 0.4;
    }
  }
  .delete-text {
    color: $error-color;
  }
  .edit-text {
    color: $primary-color-text;
    span.anticon {
      vertical-align: -0.5em;
      margin-left: -0.5em;
      svg {
        width: 1.8em;
        height: 1.8em;
      }
    }
  }
}
