.custom-editor {
    
   & * {
        font-family: Poppins, sans-serif !important;
    }
    .anticon {
        height:auto !important;
    }

    .fr-toolbar .fr-command.fr-btn svg path {
        fill: #4F4E7B !important;
    }

    .fr-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown){
        background-color: #a5a5a529;
        border-radius: 5px ;
    }

    p > {
        margin-bottom: 5px;
    }
}