@import 'styles/theme.scss';
.toolbar-side-menu {
  height: calc(var(--height-100) - 64px);
  z-index: 12;
  padding: 1.5em 0;
  background: $white-color !important;

  @media screen and (max-width: 1299px) {
    position: fixed !important;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    &.main-menu {
      width: 300px !important;
      max-width: 300px !important;
    }
  }
  @media screen and (min-width: 1299px) {
    &.main-menu {
      flex-basis: 26% !important;
      max-width: 26% !important;
      width: 23% !important;
    }
  }

  &.content-table-side-menu {
    left: 0;
  }
  &.main-menu {
    right: 0;
    &.ant-layout-sider-is-collapsed {
      overflow-y: unset;
      transform: translateX(100%);
      position: fixed !important;
    }
  }

  .ant-tabs {
    overflow: visible !important;
  }

  @include rwd(1300) {
    .elements-menu {
      .items {
        flex-flow: column wrap;
        .ant-col {
          margin: 0 auto;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  @include lg {
    .elements-menu {
      padding: 16px 16px;
    }
    .ant-layout-content {
      .ant-col {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  @include md {
    .elements-menu {
      .ant-card-body {
        justify-content: center;
        .spacer,
        .text {
          display: none;
        }
      }
    }
  }

  .tabs {
    height: calc(var(--height-100) - 123px);
    .ant-tabs-content-holder {
      @include custom-scrollbar;
      max-height: calc(100% - 60px);
      overflow-y: auto;
      overflow-x: hidden; // experimental
    }

    .ant-tabs-nav-list {
      flex-grow: 1;
      margin-left: 32px;
      margin-right: 40px;
      flex-wrap: wrap;
      justify-content: space-evenly;
      border-radius: 4px;
      padding: 4px;
      background: $light-color;
      .ant-tabs-tab {
        margin: 0;
        padding: 7px;
        .ant-tabs-tab-btn {
          color: $primary-color-text;
          opacity: 0.8;
          transition: font-weight ease-in-out;
          user-select: none;
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-weight: 600;
            color: $primary-color-text;
            opacity: 1;
          }
        }

        &.ant-tabs-tab-active {
          border-radius: 4px;
          background: $white-color;
        }
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-nav {
      &::before {
        display: none;
      }
    }
  }

  .ant-layout-sider-trigger {
    width: inherit;
  }

  .ant-layout-sider-collapse {
    position: absolute;
    width: 100%;
    z-index: 1;
    bottom: 0;
    background: white;
    text-align: center;
    box-shadow: $box-shadow-top;
    border-radius: 4px;

    .anticon {
      height: 2em;
      width: 2em;
      font-size: 18px;
      padding: 0.5em;

      &.ant-layout-sider-collapse-expand {
        transform: rotate(180deg);
      }
    }

    &.ant-layout-sider-collapse-open {
      width: 50px;
      height: 36px;
      left: 0px;
      top: 5px;
      transform: translateX(-100%);
      position: fixed;
      box-shadow: $box-shadow-avatar;
    }
  }

  .ant-tabs {
    .ant-tabs-nav-list {
      justify-content: space-between;
      .ant-tabs-tab {
        width: 50%;
        justify-content: center;
        display: flex;
        @media (min-width: 1366px) {
          width: auto;
        }
        @media (min-width: 1500px) {
          width: 25%;
        }
      }
    }
  }
}
