.digital-ocean {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-card {
        width: 300px;
        .status {
            display: flex;
            justify-content: space-between;
        }
    }
}