.html-formatter {
    display: flex;
    flex-direction: column;
  
    .editor-container {
      text-align: start;
    }
  
    .CodeMirror {
      height: 100%;
      width: 100%;
      font-size: 14px;
  
      .CodeMirror-lines {
        padding: 4px;
      }
  
      .CodeMirror-gutters {
        border-right: 1px solid #ddd;
      }
    }
  }
  