@import 'styles/theme.scss';

.zoom-component-container {
    display: flex;
    align-items: center;
    background: $background-color;
    & > * {
        user-select: none;
    }

    .btn-disabled {
        cursor: not-allowed;
    }

    .btn-margin {
        padding: 0 0.5em;
    }
}