@import "styles/theme.scss";

.user-avatar {
  position: absolute;
  bottom: 20px;
  cursor: pointer;
  &:hover{
    
  }
}

.account-menu-wrapper {
  background: $white-color;
  box-shadow: 0px 0px 1px rgba(99, 114, 130, 0.32), 0px 8px 16px rgba(27, 39, 51, 0.08);
  border-radius: 4px;

  .account-menu {
    color: #05034d !important;
    border-radius: 4px !important;
    min-width: 170px;

    .ant-dropdown-menu-item {
      padding: 0.5em 1em;
      color: #05034d !important;
      &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background-color: #f5f6fa;
    }

    .menu-divider {
      margin: 0;
    }
  }
}