@import "styles/theme.scss";

.simple-info-modal {
  .ant-modal-body {
    padding: 3em 5em;
    text-align: center;
    .divider {
      margin: 0 auto;
      margin-top: 1em;
      background: $secondary-color;
      width: 32px;
      height: 1px;
    }
    .divider-thanks {
      margin: 0 auto;
      margin-top: 1em;
      background: $signature-color;
      width: 32px;
      height: 1px;
    }
    .title {
      display: flex;
      justify-content: center;
      margin-top: 1em;
      font-weight: 600;
      font-size: $large-title-text-font-size;
      line-height: 140%;
      text-align: center;
      color: $primary-color-text;
    }
    .body {
      text-align: center;
      font-weight: normal;
      margin-top: 1.5em;
      font-size: $label-font-size;
      line-height: 150%;
      color: $primary-color-text;
    }
    .button-wrapper {
      display: flex;
      justify-content: space-around;
      margin-top: 2em;
      padding: 0 1.5em;
    }
    .modal-options-block {
      display: flex;
      justify-content: space-between;
    }
  }

  .spinner{
    height: 50px;
    margin: 30px 0;
    .loader{
      height: 50px;
      left: 0;
      position: absolute;
    }
  }

  button.ant-btn {
    width: 100px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 600;
    font-size: $small-font-size;
    line-height: 140%;

    &.error {
      color: red;
      font-size: $sub-font-size;
      text-align: center;
    }

    &.confirm {
      background: $secondary-color;
      border: 2px solid $secondary-color;
      box-shadow: $light-box-shadow;
      &:hover {
        opacity: 0.8;
        background: $secondary-color;
        border-color: $secondary-color;
      }
    }

    &.cancel {
      background: $light-color;
      border: 2px solid $light-color;
      color: $primary-color-text;
      &:hover {
        opacity: 0.8;
        background: $light-color;
        border-color: $light-color;
      }
    }

    span {
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .ant-btn-loading-icon {
      position: absolute;
      margin-left: -1.5em;
      .anticon-loading {
        padding: 0;
        margin-right: 8px;
      }
    }
  }
}

.simple-info-modal.download-pdf-modal {
  .body {
    margin: 1.5em auto auto;
    width: 13em;
    text-align: left;
    span:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 900ms infinite;
      animation: ellipsis steps(4, end) 900ms infinite;
      content: "\2026"; /* ascii code for the ellipsis character */
      width: 0px;
    }

    @keyframes ellipsis {
      to {
        width: 20px;
      }
    }

    @-webkit-keyframes ellipsis {
      to {
        width: 20px;
      }
    }
  }

  .ant-modal-footer {
    display: none;
    button {
      display: none;
    }
  }
}
