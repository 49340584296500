@import 'styles/theme.scss';

.add-video-modal {
  .ant-modal {
    width: 28em;
    .ant-modal-content {
      .ant-modal-body, .ant-modal-footer {
        padding: 2em 3em;
      }
      .ant-modal-body {
        .title {
          justify-content: flex-start;
        }
        .divider {
          margin: 0;
        }
        .body {
          .ant_form .ant-form-item  {
            .ant-form-item-label label{
              color: $label-color-light;
            }
          }
          .ant-form-item-control-input input {
            border: 1px solid $primary-color-light;
          }
        }
      }
      .ant-modal-footer {
        border-top: none;
        justify-content: space-around;
        display: flex;
        button {
          width: 9em;
          height: 44px;
        }
      }
    } 
  }
}