@import "styles/theme.scss";

$header-height: 48px;

.rich-editor {
  width: 100%;

  &.inactive {
    .color-picker {
      display: none !important;
    }
  }

  .color-picker {
    direction: ltr;
    direction: initial;
    z-index: 13;
    position: absolute;
    top: 31px;
    right: -75px;
    background-color: #fff;
    color: #4a4a4a;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 12px 0px;
    border-radius: 4px;
    &.outside-toolbar {
      position: fixed;
      top: 105px;
      left: calc(37% - 110px);
      width: 220px;
    }
    & * {
      user-select: none;
    }

    .color-tabs {
      display: flex;
      border-bottom: 1px solid $tab-border;
      height: 34px;
      .color-tab {
        font-size: 14px;
        width: 50%;
        text-align: center;
        padding: 6px;
        border-bottom: 2px solid transparent;
        line-height: 20px;
        cursor: pointer;
        opacity: 0.6;

        &.active {
          border-bottom: 2px solid $section-color;
          opacity: 1;
        }

        &:hover {
          background-color: #03030305;
        }
      }
    }

    .sketch-picker {
      box-shadow: none !important;
      label {
        user-select: none;
      }
    }
  }

  .data-link {
    cursor: pointer;
  }
  .image-drop-section {
    position: relative;
    .image-size {
      color: #05034d;
      font-size: 10px;
      display: block;
      width: 280px;
      margin: 0 auto;
    }
    .logo-upload-progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: white;
      display: flex;
      padding: 25px 25px 10px 25px;
      flex-direction: column;

      .spacer {
        flex-grow: 1;
      }

      color: $primary-color-text;

      .progress-title {
        margin-bottom: 2px;
        font-weight: 600;
        font-size: $label-font-size;
      }

      .upload-image-name {
        margin-bottom: 0;
        font-size: $sub-font-size;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-slider {
        .ant-slider-step {
          border-radius: 4px;
        }

        .ant-slider-track {
          background-color: $secondary-color !important;
        }

        .ant-slider-handle {
          background-color: $secondary-color !important;
          border: solid 2px $secondary-color !important;
          border-color: $secondary-color !important;
          box-shadow: 0px 0px 4px $secondary-color;
        }
      }

      .ant-tooltip-content {
        color: white;
        background-color: white;

        .ant-tooltip-arrow-content {
          background-color: white !important;
        }

        .ant-tooltip-inner {
          color: $primary-color-text !important;
          background-color: white;
        }
      }
    }
  }

  .rich-editor-header {
    font-family: var(--font-family);
    .rich-editor-toolbar {
      font-size: 17px;
    }
    .rich-editor-toolbar .rich-editor-toolbar-overflow-dropdown {
      .rich-editor-toolbar-overflow-dropdown-trigger {
        border-radius: 0%;
        border: 0;
        padding: 0.25em 0.1em;
        margin: 0.1em 0em;
        width: 1.5em;
        height: 1.2em;
        cursor: pointer;
        &:hover {
          background-color: $primary-color-01-light;
          border-radius: 2px;
        }
        svg {
          height: 0.75em;
          width: 0.25em;
          transform: rotate(90deg);
        }
      }
    }

    .ant-dropdown-link {
      margin-right: 4px;
      margin-left: 4px;
      background: $white-color;
      border: 1px solid $tab-border;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 2px 4px 2px 7px;
      min-width: 130px;
      display: flex;
      align-items: center;

      & > span:first-child {
        flex-grow: 1;
      }
    }
  }

  .color-sketch-box {
    position: absolute;
    z-index: 5;
    color: black;
  }

  .DraftEditor-root {
    .public-DraftEditorPlaceholder-root {
      color: #9197a3;
      position: absolute;
      z-index: 0;
      width: max-content;
      max-width: 100%;
      height: 100%;
    }

    .public-DraftEditorPlaceholder-inner {
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: unset !important;
    }

    .DraftEditor-editorContainer {
      background-color: rgba(255, 255, 255, 0);
      border-left: 0.1px solid transparent;
      position: relative;
      z-index: 1;

      .public-DraftEditor-content {
        .RichEditor-atomic {
          .gallery-container {
            .gallery-image-container {
              border-radius: 4px;
              display: flex;
              width: 100%;
              background: $light-color;
              .react-grid-layout {
                width: 100%;
              }
            }
          }

          .divider-component {
            border: 1px solid;
            margin-top: 1em;
          }
        }
      }
    }

    // keep the image/element on top when hovered
    figure.RichEditor-atomic:hover {
      z-index: 10;
    }
  }

  .rich-copy-spinner {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999999;
    background-color: $loader-overlay-background;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
  }

  .rich-upload-loader {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999999;
    background-color: $loader-overlay-background;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;

    .ant-progress-inner {
      .ant-progress-circle-path {
        stroke: $primary-color;
      }

      .ant-progress-text {
        color: white;
      }
    }
  }

  div[id$="_image"] {
    color: black;
    height: 100%;
    .paragraph {
      display: none;
    }
    .RichEditor-atomic {
      margin: 0;
      margin-bottom: 30px;
      .rich-editor-components-wrapper {
        padding: 0;
      }
    }
  }

  div[id$="imported_image"] {
    .paragraph {
      display: block;
    }
  }

  figure {
    .rich-editor-components-wrapper {
      max-width: 100%;
    }
  }

  .gallery-uploader {
    margin: 1em auto;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: $primary-color-text;
    button {
      height: 2.5em;
      line-height: 140%;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
      margin-top: 1.4em;
      background-color: $secondary-color;
      color: $white-color;
      border: 2px solid $secondary-color;
      box-shadow: 0px 10px 15px -4px rgba(241, 92, 60, 0.4);
      border-radius: 4px;
    }
  }

  // text selection fake highlight
  .highlight > span {
    background-color: #1890ff5c !important;
  }

  .highlight-ai > span {
    background-color: #1890ff !important;
    color: white !important;
  }
}

.rich-editor-toolbar-dropdown {
  padding: 0;

  .ant-select-rich-editor-toolbar {
    padding: 0 0.5em !important;
    font-size: $sub-font-size;
    border-bottom: 1px solid $primary-color-01-light;
    .ant-select-item-option-content {
      margin: auto;
    }
  }
}

.rich-editor-list-option {
  &.ant-dropdown-menu {
    position: absolute;
    width: 160px;
    background: white;
    box-shadow: 0px 5px 20px rgba(4, 3, 49, 0.15);
    border-radius: 4px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: visible;
    scrollbar-width: thin;
    @media screen and (min-width: 1200px) {
      @include custom-scrollbar;
    }
  }

  &.list-item {
    min-height: 112px;
    padding: 6px;

    .ant-dropdown-menu-item {
      padding: 6px 6px;
      float: left;
      width: 33.33%;
      height: 50px;
      clear: inherit;
    }
  }

  .ant-dropdown-menu-item {
    padding: 6px 6px;
    float: left;
    width: 3.7em;
    height: 3.7em;
    clear: inherit;

    &.ant-dropdown-menu-item-selected,
    &.ant-dropdown-menu-item:hover {
      background-color: transparent;
      svg {
        background-color: $light-color;
      }
    }
    > .span {
      margin-right: 0;
    }

    svg {
      font-size: $medium-text-font-size;
    }
  }
}

.rich-editor-intent-list-option {
  height: 52px;
  display: flex;
  padding: 10px 8px !important;
  background: white;
  box-shadow: 0px 5px 20px rgba(4, 3, 49, 0.15);
  border-radius: 4px;

  .ant-dropdown-menu-item {
    border-radius: 4px;
    color: $primary-color-dark;
    padding: 6px 9px;

    .ant-divider {
      height: 100%;
      background: $border-color-tab;
      top: 0;
      margin: auto;
    }

    &:last-child {
      padding: 0px;

      .input-container {
        margin-bottom: 0em;
        width: 4em;
        border: 1px solid $tab-border;

        input {
          padding: 4px;
          text-align: center;
        }
      }
    }

    &.true {
      background: lightgray;
    }
  }
}

.rich-editor .DraftEditor-root > .DraftEditor-editorContainer > .public-DraftEditor-content .RichEditor-atomic,
.simple-section-content,
.ant-modal-content .ant-modal-body {
  .table-content-container {
    @include thin-custom-scrollbar;
    overflow-x: overlay;

    .table-container {
      width: 100%;
      border: 1px solid #dbdbdb;
      border-collapse: collapse;

      td {
        vertical-align: top;
      }

      th {
        background-color: #4a4a4a;
        color: white;
      }

      th,
      td {
        text-align: left;
        padding: 15px;
        height: 50px;
        border: 0;
        border-bottom: 1px solid #ddd;
      }

      tr:hover {
        background-color: $input-disabled-color;
      }

      tr:first-child {
        background-color: lightgray;
        font-weight: 600;
      }
    }
  }

  .image-component,
  .media-component {
    text-align: center;

    img {
      width: 100%;
    }

    .embed-wrap {
      display: block !important;
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      max-width: 100%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .img-buttons {
      a {
        padding: 0 15px;
      }
    }
  }
}

.editor { 
  .custom-dropzone{
    height: 115px;
    border: 2px dashed $section-color;
    background: rgba(234, 241, 255, 0.95);
    cursor: pointer;
    font-weight: 600;
    font-size: $label-font-size;
    line-height: 140%;
    color: $primary-color-text;
  
    input{
      display: none;
    }
    label { 
      padding: 5% 0px;
      position: absolute;
      height: 100%;
      width:100%;
      left: 0;
      cursor: pointer;
    }
  }

  .dropzone {
    height: 115px;
    border: 2px dashed $section-color;
    background: rgba(234, 241, 255, 0.95);
    cursor: pointer;
  
    p {
      padding: 5% 0px;
      font-weight: 600;
      font-size: $label-font-size;
      line-height: 140%;
      color: $primary-color-text;
    }
  }
}

.rich-editor-modal-footer {
  height: 6.5em;
  padding: 1em;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 0;
  width: 100%;
  background: white;

  button {
    min-width: 11em;
    height: 45px;
    justify-content: center;
    font-weight: 600;
  }

  .rich-editor-modal-close,
  .rich-editor-modal-reset {
    background: $tab-background;
    border-radius: 4px;
    border: none;
    color: $primary-color;

    &:hover {
      color: $primary-color;
      border-color: $section-background;
      background-color: $section-background;
    }
  }

  .rich-editor-modal-close {
    margin-right: 1em;
    direction: rtl;
    display: flex;
    min-width: 8em;

    span {
      margin: auto;
    }
  }

  .rich-editor-modal-continue {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: white;
    box-sizing: border-box;
    box-shadow: $box-shadow;
    border-radius: 4px;
    direction: rtl;
    display: flex;
    min-width: 8em;

    span {
      margin: auto;
    }

    &:hover {
      border-color: $secondary-color-hover;
      background-color: $secondary-color-hover;
      color: white;
    }
  }
  .ant-btn {
    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      color: $disabled-color;
      background: $input-disabled-color;
      border-color: $border-color-base;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.simple-section {
  .rich-editor {
    .DraftEditor-root {
      .public-DraftEditorPlaceholder-root {
        width: 100%;
      }
    }
  }
}

.rich-editor-toolbar-overflow-dropdown-overlay {
  .overflow-dropdown-list-menu {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4em;
    box-shadow: $box-shadow;
    border-radius: 4px;

    .overflow-dropdown-list-menu-item-dynamic {
      display: flex;
      padding: 0.4em 0px;

      &:hover {
        background-color: transparent;
      }

      .rich-editor-toolbar-clear {
        height: 1.8em !important;
        padding: 0.3em 0.3em;
      }

      .rich-editor-toolbar-icon {
        height: 2em;
        span.anticon {
          margin: 0 auto;
        }
      }
    }
  }
}

.rich-editor-header .rich-editor-toolbar .rich-editor-toolbar-overflow-dropdown,
.rich-editor-toolbar-overflow-dropdown-overlay {
  .rich-editor-toolbar-clear {
    padding: 3px;
    margin-top: 2px !important;
    align-items: flex-end !important;
  }

  .rich-editor-toolbar-font-family {
    .custom-select-selection-item {
      width: 10em;
    }
  }

  .rich-editor-toolbar-icon {
    height: 1.2em;
    min-width: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 1px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 50%;

    &.disabled {
      opacity: 0.4;
      background: $input-disabled-color;
      border-radius: 2px !important;
    }

    &.true,
    &.true > .ant-btn {
      background: $primary-color-01-light;
      border-radius: 2px;

      svg {
        fill: $primary-color-dark;

        &.direction-icon,
        &.line-height-icon {
          path {
            stroke: $primary-color-dark;
          }
        }
        path,
        rect {
          fill: $primary-color-dark;
        }
      }
    }

    &.COLOR,
    &.BACKGROUND {
      background: none;
      position: relative;
    }

    &.editor-toolbar-direction-left {
      path#path-left {
        stroke: $primary-color-dark;
        fill: $primary-color-dark;
      }

      rect#rect-right,
      path#path-right {
        stroke: $disabled-tool;
        fill: $disabled-tool;
      }
    }

    &.editor-toolbar-direction-right {
      path#path-right {
        stroke: $primary-color-dark;
        stroke: $primary-color-dark;
      }

      rect#rect-left,
      path#path-left {
        stroke: $disabled-tool;
        fill: $disabled-tool;
      }
    }

    .anticon {
      svg {
        font-size: $small-font-size;
      }
    }

    .color-block {
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Crect fill='%23ddd' width='5' height='5'/%3E%3Crect x='5' y='5' fill='%23ddd' width='5' height='5'/%3E%3C/svg%3E");
      border: 1px solid $border-color-tab;
      background-size: 13.2px;
    }
    .color-block,
    .color-block > div {
      box-sizing: border-box;
      border-radius: 4px;
      width: 30px;
      height: 24px;
    }

    .font-family-select,
    .font-weight-select {
      width: 10em;
    }

    .font-size-select {
      width: 6.5em;
    }

    .ant-select {
      .ant-select-selector {
        height: 24px;

        .ant-select-selection-item {
          font-size: $sub-font-size;
          line-height: 22px;
          text-align: initial;
        }
      }

      .ant-select-arrow {
        top: 50%;

        svg {
          font-size: $sub-font-size;
        }
      }
    }
  }

  .ant-divider-vertical {
    top: 0.1em;
    height: 1.9em;
    border-left: 1px solid $border-color-tab;
    .ant-divider-inner-text {
      padding: 0;
    }
  }

  .rich-editor-list {
    background: none;
    display: flex;
    border: 0;
    padding: 7px 0;
    align-items: center;
    min-width: 24px;
    height: 1.5em;
    text-align: center;
    justify-content: center;
    margin: auto 1px;
    width: 3em;

    &.toolbar-icon-active {
      background: $primary-color-01-light;
      border-radius: 2px;

      svg {
        fill: $primary-color-dark;

        path,
        rect,
        circle {
          fill: $primary-color-dark;
        }

        .line-spacing-path {
          stroke: $primary-color-dark;
        }
      }
    }

    .anticon {
      svg {
        font-size: $small-font-size;
        fill: $primary-color-dark;
      }
    }
  }

  .rich-editor-dropdown-icon {
    margin-left: 4px;
  }
}

.variables-items {
  cursor: pointer;
  width: 100%;
}

.ant-menu-vertical {
  z-index: 10;
  border-radius: 4px !important;
}

.ant-menu-sub {
  margin: 0 0 0 -2.5em !important;
}

.variable-category {
  width: 50%;
  background: white;
  height: 9.25em;
}

.variables-menu {
  background-color: white;
  width: 90%;
  .variable-subcategory {
    min-height: 9.25em;
    border-style: solid;
    border-color: $border-color-base;
    width: 50%;
    background: white;
  }
  .variables-items.category {
    border-style: solid none none solid;
    &.last {
      border-style: solid none solid solid;
    }
    border-color: $border-color-base;
  }
  .variables-items.subcategory {
    width: 50%;
    font-size: 15px;
    margin: 0.2em 0 0 1.2em;
  }
}

.variable-category-item {
  font-size: 18px;
  width: 70%;
  margin: 0.2em 0 0.2em 1em;
}

.variable-category-icon {
  margin: auto;
}

.variable-subcategory-header {
  display: flex;
}

.variables-menu-items {
  &.hebrew,
  &.arabic {
    direction: rtl;
  }
  &.ant-menu-submenu-active {
    background-color: $light-color;
  }
  &.ant-menu-item-active {
    background-color: $light-color;
  }
  color: $primary-color-text !important;
  .ant-menu-submenu-title {
    display: flex;
    justify-content: space-between;
    &:hover {
      color: $primary-color-text !important;
    }
    .ant-menu-submenu-arrow {
      &::before {
        background: $primary-color-text !important;
      }
      &::after {
        background: $primary-color-text !important;
      }
    }
  }
}

.variables-menu-chevron-icon {
  line-height: 45px !important;
  margin: 0 -1em 0 0 !important;
  &.hebrew,
  &.arabic {
    margin: 0 0 0 0 !important;
    transform: rotate(180deg);
  }
}

.variables-submenu-items {
  .ant-menu-sub {
    margin: 0 0 0 -0.5em !important;
  }
  &.hebrew,
  &.arabic {
    .ant-menu-sub {
      margin: 0 12em 0 -23em !important;
    }
  }
}

.ai-toolbar {
  position: absolute;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.3em;
  font-weight: 400;
  font-size: $sub-font-size;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(3, 20, 63, 0.15);
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: Poppins;
  border: 1px solid #e9e9ee;
  height: fit-content;

  .ai-toolbar-bot .ask-ai-icon-test {
    color: black;
  }
  
  &.ai-toolbar-ask-ai {
    width: 42em;
  }

  &.ai-toolbar-extended {
    height: 6em;

    .ai-toolbar-finalize {
      & > .ant-row {
        display: flex;
        align-items: center;
        margin: 0px 0px;

        & > .ant-row {
          align-items: center;
          margin: 7px 10px;
          padding: 0px 6px;
          gap: 3px;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            background: rgba(1, 196, 79, 0.1);
          }

          .anticon :hover {
            background-color: transparent;
          }
        }

        &.ai-toolbaar-finalize-info {
          font-size: 10px;
          color: $light-text-color;
          padding: 0 12px;

          a {
            text-decoration: underline;
            color: $svg-color-light;
          }
        }

        & > .ant-row.ai-toolbar-finalize-close {
          margin: 0;
          margin-top: 0px;
          padding: 0;
          margin-top: -24px;
          border-radius: 50%;

          .anticon {
            svg {
              height: 16px;
              width: 16px;
              rect:first-child {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .ant-divider {
    &.horizontal-divider {
      margin: 0;
    }

    &.vertical-divider {
      height: 1.2em;
      margin: 0 2px;
      border-color: $tab-border;
    }
  }

  .anticon {
    cursor: pointer;
    &.ai-toolbar-dropdown-icon {
      svg path {
        fill: $primary-color-light;
      }
    }

    &.anticon[class*=" ai-toolbar-icon"] {
      margin: 0 8px;

      .language-hebrew & {
        &.anticon[class*="-longer"] {
          margin-left: 4px;
          margin-right: 8px;
        }
      }
    }

    :hover {
      border-radius: 4px;
      background: $selected-dropdown-background;
    }
  }

  .ai-toolbar-dropdown-placeholder {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid $primary-color-light;
    }

    .anticon {
      margin: 0 5px 0 0px;
      svg {
        height: 16px;
        width: 16px;
      }
      &.ai-toolbar-dropdown-icon {
        margin: 0 0 0 5px;
        svg {
          height: 14px;
          width: 11px;
        }
      }
    }
  }

  .ai-toolbar-dropdown-selected {
    color: $primary-color-text;
    background: $selected-dropdown-background;
    border-radius: 4px;
    padding: 0 5px;
  }

  .ai-toolbar-dropdown-placeholder {
    color: $primary-color-light;
    border-radius: 4px;
    background: $selected-dropdown-placeholder;
  }

  .ant-dropdown-trigger {
    height: 25px;
    left: 2px;
    top: 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 0;
    padding: 6px;
    margin-right: 1.334em;

    .ai-toolbar-icon-text {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: black;

      .ant-col {
        .anticon {
          margin: auto 5px;
          vertical-align: sub;
          margin-top: 1px;
          svg {
            height: 15px;
            width: 15px;
            rect {
              fill: #7876c1;
            }
            :first-child {
              fill: #c7bce4;
            }
          }
        }
      }
    }
  }

  .ai-toolbar-bot {
    padding: 4px 8px;
    cursor: pointer;

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      .anticon::before {
        display: none;
      }
    }

    & > span {
      display: flex;
    }
    .anticon svg:hover {
      background: white;
    }

    &:last-child {
      margin: auto;
      font-size: 14px;
      font-weight: 400;
      width: 100%;
    }

    .ant-col {
      margin: auto;
      display: flex;
    }
  }

  .ai-toolbar-initial {
    width: 100%;
    padding: 4px 8px;
    .ant-row {
      height: auto;
      // &.ai-toolbar-input-ask-ai {
      //   opacity: 0.5;
      .ai-toolbar-input-group-ask-ai {
        display: flex;
        height: 40px;
        .ant-col {
          margin: auto 4px;
          padding: 0;
          width: 100%;
          &:first-child,
          &:last-child {
            width: fit-content;
          }
          .anticon {
            margin: 4px 0;
            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }

          textarea {
            height: 24px;
            border: 0;
            resize: none;
            padding: 4px;
            text-align: left;
            &:focus {
              box-shadow: none;
            }
          }
        }
      }

      .ant-input-affix-wrapper {
        padding: 4px 0px;
        border: 0;
        .ant-input-prefix,
        .ant-input-sufix {
          margin: 0px 4px;
        }
        .ant-input-prefix > .anticon > svg:hover {
          background: none;
          cursor: auto;
        }
        &:focus,
        &.ant-input-affix-wrapper-focused {
          box-shadow: none;
        }
        .ant-input {
          &::placeholder {
            color: $svg-color-light;
          }
        }
      }
    }
    .ai-toolbar-options {
      display: flex;
      margin: 4px 0;
      justify-content: space-between;
      > .ant-row {
        &:last-child {
          color: #7f82a1;
          margin: auto 0px;
        }
      }
    }

    .ai-toolbar-generating {
      padding: 0.5em;

      .ai-toolbar-icon-text {
        color: black;
      }

      .ant-row button {
        padding: 0px 8px 0px 8px;
        background-color: #f4f4ff;
        font-size: 12px;
        font-weight: 500;
        height: 1.8em;
        border-radius: 10%;
        border: none;
      }
      .anticon {
        margin-right: 0.5em;
      }
    }

    .ai-toolbar-suggestion-container {
      position: relative;
      height: 20em;
      min-height: 9em;
      position: relative;
      margin: 4px;
      .ai-toolbar-suggestion-header {
        height: 2em;
        position: sticky;
        top: 0;
        background: white;
        .ant-row {
          font-size: 14px;
          font-weight: 600;
          color: $placeholder-color;
          &.ant-row-middle {
            margin-top: 6px;
          }
          button {
            font-size: 12px;
            font-weight: 600;
            color: $primary-color-light;
            background-color: #e9e4f4;
            padding: 0px 6px 0px 6px;
            border-radius: 4px;
            height: 2em;
            border: none;
          }
        }
      }

      .ai-toolbar-suggestion-list {
        @include custom-scrollbar;
        width: 100%;
        position: absolute;
        overflow-y: overlay;
        height: 85%;
        margin: 8px 0;

        .ai-toolbar-suggestion-body {
          background-color: #f7f7f8;
          padding: 8px;
          border-radius: 5px;
          margin-top: 5px;
          width: 100%;
          height: fit-content;

          &:hover {
            background-color: #fff6f5;
            .ai-toolbar-suggestion-footer {
              .ai-toolbar-suggestion-options {
                display: block;
              }
            }
          }
          .ai-toolbar-suggestion-content {
            color: $primary-color-text;
            font-size: 14px;
            font-weight: 400;
          }
          .ai-toolbar-suggestion-footer {
            width: 100%;
            margin-top: 1em;
            height: 2em;

            .ant-row {
              color: #7f82a1;
              font-size: 12px;
            }

            .ai-toolbar-suggestion-options {
              display: none;
              button {
                background-color: $secondary-color-light;
                color: $secondary-color;
                font-size: 12px;
                font-weight: 600;
                margin-right: 8px;
                padding: 0px 8px 0px 8px;
                border-radius: 4px;
                border: none;
                height: 2em;

                &:last-child {
                  background-color: $secondary-color;
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-dropdown.ai-toolbar-dropdown {
  left: 0 !important;
  padding: 0;

  &.language-hebrew {
    left: auto !important;
    right: 0 !important;

    .ai-toolbar-dropdown-menu-list {
      text-align: right;
    }

    .ai-toolbar-dropdown-menu {
      .ant-dropdown-menu-item {
        text-align: right !important;

        .anticon {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }

    .ai-toolbar-dropdown-loader {
      & > .anticon {
        transform: rotate(180deg);
      }
    }
  }

  .ant-dropdown-menu {
    padding: 0 !important;

    &.ai-toolbar-dropdown-loader {
      width: 420px;
      height: 189px;
      left: 0px;
      top: 0px;
      background: $liner-background-color;
      box-shadow: 0px 5px 10px rgba(3, 20, 63, 0.15);
      border-radius: 4px;

      :hover {
        background: none;
      }

      .anticon {
        margin: 1em;
      }
    }

    &.ai-toolbar-dropdown-menu-list {
      width: 420px;

      .ant-dropdown-menu-item {
        height: 40px;
        left: 4px;
        top: 50px;
        border-radius: 4px;
        &:hover {
          background: $selected-dropdown-background;
        }
      }
    }

    &.ai-toolbar-dropdown-menu,
    &.ai-toolbar-dropdown-menu-list {
      min-width: 13em;
      background: #ffffff;
      box-shadow: $active-box-shadow;
      border-radius: 4px;
      padding: 4px;
      min-height: 5.5em;
      overflow: scroll;
      max-height: 15em;
      width: max-content;
      max-width: 15em;
      @include thin-custom-scrollbar;

      &.ai-toolbar-dropdown-menu-more .ant-dropdown-menu-item {
        justify-content: space-between;
        &.ant-dropdown-menu-item-selected {
          .anticon:last-child {
            margin-right: 0;
            padding: 0.5em;
          }
        }
      }

      .ant-dropdown-menu-item {
        font-weight: 400;
        font-size: $label-font-size;
        color: $primary-color-text;
        min-height: 32px;
        height: auto;
        white-space: initial;
        left: 4px;
        top: 44px;
        margin: 4px;
        width: auto;
        display: flex;
        align-items: center;

        &:hover {
          background: $selected-dropdown-background;
          border-radius: 4px;
        }

        &.ant-dropdown-menu-item-selected {
          .anticon:last-child {
            right: 10px;
            position: absolute;
            padding: 0.5em;
            svg {
              path {
                stroke: #448ef7;
              }
            }
            :hover {
              border-radius: inherit;
            }
          }
        }

        .ant-radio-checked .ant-radio-inner {
          border-color: #7876c1 !important;
          background-color: #7876c1 !important;
          &::after {
            background-color: white !important;
            top: 4px;
            left: 4px;
            width: 6px;
            height: 6px;
          }
        }
      }
    }

    .ant-dropdown-menu-item-divider {
      background-color: $border-color-tab;
    }
  }
}

.highlightedText {
  background: tomato;
  color: white;
}
