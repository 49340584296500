@import "styles/theme.scss";

.plans .ant-row.billing-info {
  padding: 2.3em;
  .billing-list-header {
    padding: 0.7em 2em;
    margin-top: 1em;
    background: $header-background-color;
    border-radius: 4px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    text-align: start;
    margin-bottom: 0;
    .header-value {
      font-weight: normal;
      font-size: $label-font-size;
      color: $primary-color-dark;
      &.invoice-header-value {
        text-align: center;
      }
    }
  }

  .ant-list {
    .ant-list-items {
      min-height: 30vh;
      .ant-list-item {
        padding: 0px 0.3em;
        border-bottom: 0 !important;
        .billing-list-item {
          border-left: 4px solid $primary-color;
          padding: 0em 2em;
          cursor: default;

          .invoice-value {
            justify-content: center;
          }
          button {
            display: flex;
            border: none;
            height: 32px;
            font-weight: 600;
            font-size: $label-font-size;
            text-transform: uppercase;
            box-sizing: border-box;
            border-radius: 4px;
            background: $tab-background;
            color: $primary-color;
            padding: 4.4px 10px;
            .anticon {
              margin: auto;
              border-radius: 4px;
            }
          }
        }
      }
    }
    .ant-list-pagination {
      .ant-pagination {
        text-align: center;
        .ant-pagination-prev,
        .ant-pagination-next {
          border: none;
          background: $tab-background;
          border-radius: 4px;
          padding: 0px 1em;
          font-size: $label-font-size;
          font-weight: 600;
          a {
          color: $primary-color-text;
          }
        }
        .ant-pagination-disabled {
          font-weight: 400;
        }
        .ant-pagination-item {
          border: none;
          a {
          color: $primary-color-text;
          }
        }
        .ant-pagination-item-active {
          background: $tab-background;
          border-radius: 4px;
          font-size: $label-font-size;
          font-weight: 600;
        }
      }
    }
  }
}

.download-invoice-menu li {
  color: $primary-color-text;
}