@import "./_mixin.scss";
@import "./_common-classes.scss";
//colors
$primary-color: #2f2c88; // primary color for all components
$link-color: #2f2c88; // link color
$success-color: #52c41a; // success state color
$warning-color: #faad14; // warning state color
$error-color: #ff1b1b; // error state color
$font-size-base: 14px; // major text font size
$heading-color: rgba(0, 0, 0, 0.85); // heading text color
$text-color: rgba(0, 0, 0, 0.65); // major text color
$text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
$disabled-color: rgba(0, 0, 0, 0.25);
$input-disabled-color: #f5f5f5;
$border-radius-base: 2px; // major border radius
$border-color-base: #d9d9d9; // major border color
$box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

$transform-color: #EEEBF7;
$primary-color-light: #7876c1;
$primary-color-01-light: #cbcce0;
$primary-color-08-opacity: #383572;
$primary-color-dark: #05034d;
$primary-color-text: #05034d;
$primary-color-active: #444194;
$completed-color: #01c44f;
$secondary-color: #f03f3b;
$secondary-color-hover: #fd635e;
$secondary-color-light: #ffeae8;
$placeholder-color: #afb1ba;
$light-color: #f5f6fa;
$disabled-tool: #9e9eb7;
$form-error-color: #f5222d;
$light-link-border-color: #c4c4c4;
$label-color-light: #4f4e7b;
$label-color-dark: #5c5c5c;
$label-count-color-light: #8281a6;

$border-color: #f0f2f5;
$border-color-tab: #cbced8;
$background-color: #e1e2ef;
$background-hover-color: #e8e8f0;
$background-color-dark: #f1f1f1;
$publish-background-color: #bed5ff;
$header-background-color: #eaeef7;
$liner-background-color: linear-gradient(180deg, #ffffff 0%, #f5f6fa 100%), #ffffff;
$side-bar-border-color: #ff9492;
$white-color: #ffffff;

$box-shadow: 0px 2px 4px #3c3f471a;
$box-shadow-dark: 0px 2px 4px #03143f33;
$light-box-shadow: 0px 10px 15px -4px #f15c3c66;
$box-shadow-avatar: 0px 5px 10px rgba(6, 5, 49, 0.15);
$box-shadow-top: #ffff 10px 0px 24px 24px;
$active-box-shadow: 0px 5px 20px rgba(4, 3, 49, 0.15);
$download-box-shadow: 0px 10px 15px -4px rgba(0, 90, 255, 0.3);

$tab-background: #f5f6fa;
$tab-dark-background: #ebecf3;
$tab-border: #e2e3e8;
$section-color: #005aff;
$section-color-hover: #447bff;
$section-background: #eaf1ff;
$publish-background: #dfeaff;
$signature-color: #05348b;
$signature-hover-background: #7876c1cc;
$loader-overlay-background: #2f2c8857;
$selected-dropdown-background: #E9F6FE;
$selected-dropdown-placeholder: #F5F6FA;

//SVG
$svg-color-dark: #5d5ab5;
$svg-color-light: #c7bce4;

//font
$sub-font-size: 12px;
$label-font-size: 14px;
$small-font-size: 16px;
$medium-text-font-size: 18px;
$large-text-font-size: 20px;
$large-title-text-font-size: 24px;
$small-title-font-size: 32px;
$medium-title-font-size: 36px;
$title-font-size: 40px;
$form-text-color: #585858;
$light-text-color: #afb1bacc;
$form-input-background-color: #f8f8f8;
$form-input-placeholder-color: #252525;

$button-icon-hover: #e7e7f2;
$button-border: #DCE2FA;
$slider-border: #D8D9E2;

$trail-slider-track: #5250AC;
$trial-text-underline: #5250AB;
$trial-left: rgba(5, 3, 77, 1);

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cbced8;
    border-radius: 1ex;
    border: 2px solid;
    border-color: transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-corner {
    background: white;
  }
}

@mixin thin-custom-scrollbar {
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cbced8;
    border-radius: 1ex;
    border: 2px solid;
    border-color: transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-corner {
    background: white;
  }
}
