@import 'styles/theme.scss';
$section-padding: 32px;

.font-menu {
  background-color: $white-color !important;
  padding: 16px #{$section-padding};
  margin-bottom: 3em;
  .header-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $primary-color-text;
  }
  .ant-col .ant-form-item-label .ant-form-item-no-colon {
    font-weight: normal;
    font-size: $font-size-base;
    line-height: 140%;
    color: $label-color-light;
  }

  .section-divider {
    width: calc(100% + 2 * #{$section-padding});
    margin-left: -#{$section-padding};
  }
}
