@import 'styles/theme.scss';
$section-padding: 32px;

.elements-menu {
  background-color: $white-color !important;
  padding: 16px #{$section-padding};
  margin-bottom: 3em;
  &.variables-view {
    width: 101%;
    position: relative;
  }

  .ant-divider {
    border-top-color: $tab-border;
    &.ant-divider-horizontal.ant-divider-with-text-left::before {
      width: 0%;
    }
    .ant-divider-inner-text {
      padding: 0 0.5em 0 0;
      h5 {
        font-weight: 600;
        font-size: $sub-font-size;
        letter-spacing: 0.04em;
        color: $label-color-light;
        margin-top: 0em;
        user-select: none;
      }
    }
  }

  .items {
    .ant-col {
      position: relative;
      .elements-menu-drag {
        position: absolute;
        z-index: 1;
        right: 15px;
        top: 15px;
        box-shadow: $box-shadow-avatar;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        display: flex;
        cursor: move;
        color: $primary-color-dark;
      }
      .ant-card {
        background: $liner-background-color;
        box-shadow: $box-shadow-avatar;
        border-radius: 4px;
        border: none;
        cursor: pointer;

        &:hover {
          transform: scale(1.02);
        }

        .ant-card-body {
          .icon svg {
            margin-top: 2px;
            height: 40px;
            width: 40px;
            background: $liner-background-color;
            box-shadow: $box-shadow;
            border-radius: 4px;
          }
          padding: 12px;
          display: flex;
          span.spacer {
            flex-grow: 1;
          }

          span.text {
            align-self: flex-end;
            margin-bottom: -6px;
            font-weight: normal;
            font-size: 12px;
            line-height: 140%;
            color: $primary-color-text;
          }
        }
      }
    }
  }
}

.elements-menu-tooltip {
  .ant-tooltip-arrow-content,
  .ant-tooltip-inner {
    background-color: white;
    font-size: 12px;
  }
}

.head-variables {
  &.hebrew, &.arabic {
    direction: rtl;
    div {
      .count {
        margin: 0 0.3em 0 0;
      }
    }
  }
  width: 100%;
  color: $label-color-light;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    justify-content: space-between;
    .count {
      margin: 0 0 0 0.3em;
      color: $label-color-light;
      background: #f5f6fa !important;
      border-radius: 10px;
      min-width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      padding: 5px;
    }
  }
  .anticon {
    line-height: 1.6em;
    &.edit-variable {
      vertical-align: middle !important;
    }
  }
  .close-variables {
    color: $primary-color-text !important;
    border: none !important;
    background: $light-color !important;
    border-radius: 2em !important;
    box-shadow: none !important;
    height: auto !important;
    min-width: 100% !important;
    font-weight: normal !important;
    -moz-transition: none;
    -webkit-transition: none !important;
    transition: none;
    span {
      display: inline-flex;
    }
    &:hover {
      span svg rect {
        &:first-child {
          fill: $background-hover-color;
        }
      }
      background: $background-hover-color !important;
    }
  }
  .left-text {
    font-size: 16px;
    font-weight: 600;
  }

  .text-icon {
    align-items: center;
    margin: 0 0 0 0.5em;
  }

  span {
    display: flex;
  }

  .text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: auto;
    max-width: 13em;
    display: block;
  }
}

.add-custom-field {
  flex-flow: column wrap !important;
  text-align: center;
  display: block !important;
  &.multiple {
    margin-top: 1em;
  }
}

.ant-collapse-header {
  color: $primary-color-text !important;
}

.variables-keys {
  margin: 0 0 1.5em 0;
  .text {
    margin: 0 0 4px 0;
  }
  .right-icons {
    margin: 4px 0 0 0;
    // margin: 4px 0 0 19em;
    // position: absolute;
    .custom-closed-menu-icon,
    .copy-icon {
      margin: 0 0.3em 0 0;
      cursor: pointer;
    }
  }
}

.ant-collapse.ant-collapse-icon-position-left {
  margin: 1em 0 0 0;
  width: 100%;
}

.variable-input {
  width: 80% !important;
  height: 100%;
}

.close-variables {
  span {
    font-weight: 600;
    font-size: 12px;
  }
}

.variables-count {
  color: $border-color-base;
  margin: 0 5px 1.2em 0;
  cursor: pointer;
}

.copied {
  color: green;
}

.close-icon {
  &:hover {
    background: $background-hover-color !important;
  }
}

.menu-icon {
  top: auto !important;
  &.opened {
    transform: rotate(90deg);
  }
}

.custom-menu-item {
  &:first-child {
    padding: 0.75em 5em 0.35em 1em;
  }
  &:last-child {
    padding: 0.35em 5em 0.5em 1em;
  }
  cursor: pointer;
  display: flex;
  align-content: space-between;
  padding: 0.5em 5em 0.5em 1em;
  color: $primary-color-text;
  &:hover {
    background-color: $light-color;
  }
  &.delete {
    color: $secondary-color !important;
  }
  .custom-menu-item-icon {
    margin: 0 4px 0 0;
    width: 20%;
    line-height: 25px;
  }
}

.ant-popover-inner-content {
  padding: 0 0 !important;
}

.variables-input.hebrew, .variables-input.arabic {
  direction: rtl;
}

.variables-popover {
  .ant-popover-arrow,
  .ant-popover-inner {
    margin-right: -0.88em;
  }
  &.hebrew, &.arabic {
    .ant-popover-arrow,
    .ant-popover-inner {
      margin-left: -0.88em !important;
      margin-right: 0em !important;
    }
  }
}

.error-message-variables {
  font-size: 11px;
  color: $error-color;
}
