@import 'styles/theme.scss';

.upgrade {
  color: $primary-color-text;
  text-align: center;
  font-size: 10px;
  position: relative;

  &:before {
    content: '';
    height: 1px;
    width: 30px;
    background-color: $border-color;
    position: absolute;
    left: calc(50% - 15px);
    top: -12.5px;
  }

  &:hover {
    color: $primary-color-text;

    .counter img {
      animation: rocket 0.4s infinite;
    }
  }

  .circle-progress {
    width: 50px;
    height: 50px;
    position: relative;
    margin: auto;
    overflow: hidden;
    border-radius: 50%;

    svg {
      transform: rotate(-90deg);
    }
  }

  &.red {
    .percent {
      stroke: $secondary-color;
    }
  }

  .counter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-size: 12px;

    img {
      width: 17px;
    }
  }

  &.clicked .counter img {
    animation: rocketFire 1.5s linear;
  }
}

@keyframes rocket {
  0%,
  100% {
    transform: translate(-3px, 3px);
  }
  50% {
    transform: translate(0px, 0px);
  }
}

@keyframes rocketFire {
  0% {
    transform: translate(-9px, 9px)
  }
  20% {
    transform: translate(37px, -37px);
    opacity: 0;
  }
  90% {
    transform: translate(-26px, 24px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}