@import "./theme.scss";
@import "./common-styles.scss";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=PT+Serif:400,700");
@import url("https://fonts.googleapis.com/css?family=Assistant:400,700&subset=hebrew");
@import url("https://fonts.googleapis.com/css?family=Sacramento");
@import url("https://fonts.googleapis.com/earlyaccess/opensanshebrew.css");
// @import url('../node_modules/react-grid-layout/css/styles.css');
// @import url('../node_modules/react-resizable/css/styles.css');
@font-face {
  font-family: "Sharpsansno1 light";
  src:
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Light.eot") format("embedded-opentype"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Light.woff") format("woff"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Light.svg?v=1") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Sharpsansno1 book";
  src:
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Book.eot") format("embedded-opentype"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Book.woff") format("woff"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Book.svg?v=1") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sharpsansno1 book";
  src:
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Semibold.eot") format("embedded-opentype"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Semibold.woff") format("woff"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Semibold.svg?v=1") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sharpsansno1 bold";
  src:
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Bold.eot") format("embedded-opentype"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Bold.woff") format("woff"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Bold.svg?v=1") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sharpsansno1 semibold";
  src:
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Semibold.eot") format("embedded-opentype"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Semibold.woff") format("woff"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Semibold.svg?v=1") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sharpsansno1 semibold";
  src:
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Semibold.eot") format("embedded-opentype"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Semibold.woff") format("woff"),
    url("https://d18jgsx8vuja2p.cloudfront.net/SharpSansNo1-Semibold.svg?v=1") format("svg");
  font-weight: 700;
  font-style: normal;
}

:root{
  --font-family: 'Poppins', sans-serif;
}

html,
body {
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#supporthero-button {
  display: none !important;
}

.main-content {
  width:100%;
  overflow: hidden;

  & > .container {
    --margin-top: 0px;
    margin-top: var(--margin-top);
    overflow-x: hidden;
    height: calc(100vh - var(--margin-top));
    display: flex;
    flex-direction: column;

    & > div {
      flex: 1;
      padding: 30px 50px;
    }
  }
  
  .narrow-container {
    & > div {
      width: 100%;
      max-width: 900px;
      margin: auto;
    }
  }

  @include rwd-min(1200) {
    .narrow-container{
      padding: 0;
      & > div {
        padding: 30px 0;
      }
    }
  }
}

.handle-user-lock {
  .main-content > .container {
    --margin-top: 56px;
  }
}

.primary-btn {
  width: 180px;
  height: 44px;
  background: $secondary-color;
  border: 2px solid $secondary-color;
  box-sizing: border-box;
  box-shadow: $light-box-shadow;
  border-radius: 4px;
  color: white;
  &:hover {
    opacity: 0.8;
    background: $secondary-color;
    border-color: $secondary-color;
  }
}

.delete-btn {
  width: 180px;
  height: 44px;
  background: $white-color;
  border: 1px solid $light-link-border-color;
  box-sizing: border-box;
  border-radius: 4px;
  color: $light-link-border-color;
  &:hover {
    opacity: 0.8;
    border-color: $light-link-border-color;
    color: $light-link-border-color;
  }
}

.ant-select-arrow {
  .anticon {
    color: #f03f3b;
  }
}

.ant-radio-wrapper {
  display: flex !important;
  align-items: center;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-upload.ant-upload-drag {
  border: 2px dashed $section-color;
  background: rgba(234, 241, 255, 0.95);
  margin-top: 2em;
  .ant-upload-text {
    color: $primary-color-text !important;
  }
}

.is-disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
  pointer-events: none;
}

.has-opacity-lowwered {
  opacity: 0.2 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.m-b-20 {
  margin-bottom: 20px;
}

.relative {
  position: relative !important;
}

.custom-error-notification {
  .ant-notification-notice-with-icon {
    display: flex;
    align-content: center;
    align-items: center;
    .ant-notification-notice-icon {
      margin-left: -8px;
      margin-right: 8px;
    }
  }
  .ant-notification-notice-close {
    display: none;
  }
}

.rc-virtual-list-holder {
  overflow-y: scroll !important;
}

.rc-virtual-list-scrollbar-show {
  width: 0px !important;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.ant-modal-mask {
  background-color: rgba(48, 53, 124, 0.57) !important;
}

// userway widget
body {
  .uwy {
    display: none;
    z-index: 999 !important;
    &.uon {
      z-index: 9999 !important;
    }
  }

  &.publish-preview,
  &.client-preview {
    .uwy {
      display: block !important;
    }
  }
}

// Intercom
.publish-body, .publish-preview {
  .intercom-lightweight-app {
    z-index: 10 !important;
    .intercom-lightweight-app-launcher {
      bottom: 90px !important;
      z-index: 10 !important;
    }
  }
}

// root variable
:root {
  --dynamic-height-100: 100dvh;
  --height-100: var(--dynamic-height-100, 100vh);
}

a {
  page-break-inside: auto;
}

blockquote {
  page-break-inside: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  page-break-after: avoid;
  page-break-inside: avoid;
}

img {
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: auto;
}

table,
pre {
  page-break-inside: avoid;
}

ul,
ol,
dl,
li {
  page-break-before: avoid;
  page-break-after: avoid;
  page-break-inside: auto;
}