@import 'styles/theme.scss';
.page-header {
  h1 {
    font-size: $large-title-text-font-size;
    font-weight: 600;
    color: $primary-color-text;
    margin-bottom: 0.2em;
    &.settings-title {
      font-size: 35px;
    }
  }

  .color-divider {
    background: $secondary-color;
    width: 32px;
    height: 1px;
    padding: 0 10px;
  }

  .create-new-button {
    display: flex;
    justify-content: flex-end;
  }

  margin-bottom: 0.85em;

  .page-title {
    margin-bottom: 10px;
  }
}
