@import 'styles/theme.scss';

.input-container {
  margin-bottom: 2em;
  width: 24em;

  label {
    font-size: $label-font-size;
    color: $primary-color;
    margin-bottom: 4px;
  }

  .ant-row {
    display: flex;
    justify-content: space-between;
  }

  .input-sub-label {
    margin: 0;
    font-size: $label-font-size;
    color: $label-color-light;
    opacity: 0.8;
  }

  input,
  textarea {
    box-sizing: border-box;
    border-radius: 4px;
    color: $primary-color;
  }

  ::placeholder {
    color: $placeholder-color;
    opacity: $placeholder-color;
  }

  .input-suffix-text {
    padding: 0.5em;
    margin-bottom: 0;
    color: $primary-color-text;
  }
}

.price-options-input {
  margin-bottom: 0;
  width: 100%;
}
