@import 'styles/theme.scss';

.reorder-section-modal {
  .ant-modal-body {
    padding: 3em 2em;

    .divider {
      margin-top: 1em;
      background: $secondary-color;
      width: 32px;
      height: 1px;
    }
    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: $primary-color-text;
    }
    .body {
      font-weight: normal;
      margin-top: 1.5em;
      font-size: 14px;
      line-height: 150%;
      color: $primary-color-text;
    }
    .section-item {
      border: 1px solid $primary-color-dark;
      border-radius: 4px;
      margin-top: 2%;
      display: flex;
      align-items: center;
      padding-left: 8px;
      font-size: 16px;
      line-height: 150%;
      cursor: pointer;
      padding: 4px 8px;
      color: $primary-color-text;
      .item-name {
        padding-left: 10px;
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: space-around;
      margin-top: 2em;
      padding: 0 1.5em;
    }

    .button {
      width: 147px;
      height: 32px;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
    }
    .confirm {
      background: $secondary-color;
      border: 2px solid $secondary-color;
      box-shadow: $light-box-shadow;
      &:hover {
        opacity: 0.8;
        background: $secondary-color;
        border-color: $secondary-color;
      }
    }
    .cancel {
      background: $light-color;
      border: 2px solid $light-color;
      color: $primary-color-text;
      &:hover {
        opacity: 0.8;
        background: $light-color;
        border-color: $light-color;
      }
    }
  }
}

.anticon.close-icon {
  &:hover {
    background: $light-color;
    border-radius: 50%;
    padding: 4px 4px;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  text-align: left !important;
  height: 29px !important;
}
