@import "styles/theme.scss";

.content-library {
  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs {
    margin-top: 1.5em;
  }

  .ant-tabs-ink-bar-animated {
    display: none;
  }

  .ant-tabs-nav-wrap {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
  }

  .ant-tabs-extra-content {
    flex-grow: 1;
  }

  .tab-icons-cotainer>span {
    margin: 0 5px;
  }

  .anticon-file {
    display: none;
  }

  .ant-tabs-tab {
    color: $primary-color-text;
    text-transform: uppercase;
    font-size: $label-font-size !important;
    height: 33px;
    line-height: 150%;
  }

  .content-library-tabs {
    .ant-tabs-nav-list {
      padding-top: 0.3em;
      gap: 12px;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-tabs-tab {
    margin-right: 0px;
    padding: 0px 16px;
    font-size: 12px;
    color: $primary-color-text !important;
    line-height: 150%;

    &:hover {
      border-radius: 4px;
      background: rgba(47, 44, 136, 0.03);
    }
  }

  .ant-tabs-tab-active {
    border-radius: 4px;
    background: $background-color !important;

    .ant-tabs-tab-btn {
      color: $primary-color-text !important;
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold !important;
      line-height: 150%;
    }
  }

  .empty-wrapper {
    height: calc(100% - 40px);
    width: 100%;
    display: flex;
    align-content: center;
    justify-items: center;
    align-self: center;
  }
}

.tab-icons-container {
  display: flex;
  align-items: center;

  .anticon {
    height: 32px;
    width: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    svg {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      &:hover {
        background: $button-icon-hover;
        border-radius: 3px;
      }
    }

    &:hover,
    &.anticon-active {
      background: $button-icon-hover !important;
      border-radius: 3px !important;
    }
  }

  .vertical-divider {
    height: 1.1em;
    border-left: 1px solid #e2e3e8;
  }

  .search-proposal {
    border-radius: 6px;
    border: none;
    transition: max-width 500ms, opacity 500ms;
    max-width: 0;
    opacity: 0;

    &.showInput {
      max-width: 383px;
      opacity: 1;
    }
  }

  .content-sort-icon {
    cursor: pointer;

    svg {
      width: 2em;
      height: 2em;
    }
  }

  .content-search-icon {
    transition: transform 500ms, opacity 500ms, max-width 500ms;
    opacity: 1;
    max-width: 80px;

    &.hide-search {
      opacity: 0;
      transform: scale(0);
      max-width: 0;
    }
  }

  .filter-icon {
    cursor: pointer;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 32px;
    background: rgba(226, 227, 232, 0.40);

    .text {
      color: $primary-color-text;
      /* Small Paragraph */
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      /* 19.6px */
    }

    .close-icon {
      border-radius: 50%;

      svg {
        :first-child {
          fill: #CBCED8;
        }
      }
    }
  }
}

.ant-tree-node-content-wrapper {
  .content-library-tree-title .anticon {
    margin-left: 5px;
    display: none;
  }

  &:hover .content-library-tree-title .anticon {
    display: inline-block;
  }
}

.preview-proposal-modal {
  .ant-modal-content {
    height: calc(100vh - 32px);
    border-radius: 6px;
    overflow: hidden;
  }
  h3 {
    &.title {
      line-height: 25px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      max-width: 400px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}