@import 'styles/theme.scss';

.content-library-card {
  position: relative;

  .card-title {
    .ant-input {
      &:focus, &.ant-input-focused, &:hover {
        border-color: $tab-border;
        box-shadow: none;
      }
    }
  }
  .gif-chips {
    position: absolute;
    width: 40px;
    height: 20px;
    right: 4px;
    bottom: 40px;
    background: $light-color;
    font-weight: 600;
    border-radius: 40px;
    text-align: center;
  }

  .cover-file-icon{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
