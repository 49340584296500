@import "styles/theme.scss";

.custom-review-widget-hide {
  transition: height 500ms 0ms, opacity 500ms 0ms, padding 500ms 0ms, margin-top 500ms 0ms !important;
  opacity: 0 !important;
  height: 0px !important;
  padding: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0 !important;
  visibility: hidden;
}

.custom-review-widget {
  height: auto;
  margin: 0 auto;
  padding: 10px 35px;
  margin-bottom: 1em;
  background: white;
  border-left: 5px solid $primary-color;
  opacity: 1;
  box-sizing: border-box;
  transition: height 500ms 0ms, opacity 500ms 500ms;
  color: $primary-color-text;
  position: relative;

  .custom-review-widget-header {
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
    }

    i {
      cursor: pointer;
      position: inherit;
    }
  }

  .custom-review-widget-buttons {
    display: flex;

    .confirm-button,
    .cancel-button {
      padding: 4px 10px;
      font-size: 10px;
    }

    .confirm-button {
      background: $secondary-color;
      border: 2px solid $secondary-color;
      box-shadow: $light-box-shadow;
      color: white;
      margin-right: 1em;

      &:hover {
        opacity: 0.8;
        background: $secondary-color;
        border-color: $secondary-color;
      }
    }

    .cancel-button {
      background: $light-color;
      border: 2px solid $light-color;
      color: $primary-color-text;

      &:hover {
        opacity: 0.8;
        background: $light-color;
        border-color: $light-color;
      }
    }
  }
}
