@import "styles/theme.scss";

.ant-card.publish-approved-info {
  position: fixed;
  right: 1em;
  box-shadow: $box-shadow;
  border-radius: 4px;
  color: $primary-color-text;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;

    p,
    strong {
      font-weight: 600;

      .anticon {
        margin-left: 0.2em;
      }
    }
  }


  @include rwd-min(1600) {
    width: 22em;
  }

  @include rwd-min(1550) {
    width: 22em;
  }

  @include rwd(1550) {
    width: 20em;
  }

  @include rwd(1500) {
    width: 19em;
  }

  @include rwd(1450) {
    width: 18em;
  }

  @include rwd(1400) {
    width: 16em;
  }

  @include rwd(1580) {
    width: 100%;
    position: relative;
    right: auto;
    margin-bottom: 2em;
  }
}

.publish-approved-info-hebrew {
  font-family: "Assistant", sans-serif;

  @include rwd-min(1580) {
    left: 2em !important;
    max-width: max-content !important;
    margin: 0 !important;
  }
}