@import 'styles/theme.scss';

.activity-header {
  margin-bottom: 2em;
  top: 0;

  .activity-list-header {
    padding: 8px;
    padding-left: 14px;
    margin-top: 1em;
    background: $background-color;
    border-radius: 4px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  
    .header-value {
      font-weight: normal;
      font-size: 14px;
      color: $primary-color-dark;
    }
  }

  &.shrink {
    margin: 0 !important;
    padding-bottom: 10px;

    a,
    button,
    span,
    input {
      pointer-events: all;
    }

    .activity-item-filter-menu > div:nth-child(n + 2) {
      overflow: hidden;
      transition: max-height 500ms, opacity 500ms;
      opacity: 1;
      max-height: 65px;
    }

    .activity-list-header,
    .activity-item-filter-menu {
      transition: margin 500ms;
    }

    &.hide-items {
      .activity-item-filter-menu {
        margin-top: 0.5em;

        & > div:nth-child(n + 2) {
          max-height: 0;
          opacity: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .activity-header.shrink {
    left: 70px;
    padding-right: 2px !important;
  }
  .activity-header.shrink::after {
    position: absolute;
    width: 32px;
    background: #f0f2f5;
    height: 100%;
    padding-top: 6em;
    top: 0;
    right: -19px;
    content: ' ';
    z-index: -1;
  }
}
