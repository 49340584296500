@import 'styles/theme.scss';

.wix-client-name-dropdown-container {
  text-align: end;

  > .ant-row {
    display: flex;
    justify-content: space-between;
    label {
      margin-bottom: 4px;
    }
  }

  .ant-btn.ant-dropdown-trigger {
    height: 100%;
    width: 100%;
    padding: 1em;
    background-color: #f5f6fa;
    &:hover {
      color: $primary-color-text;
    }

    .ant-row {
      .ant-col {
        &:first-child {
          span {
            font-weight: 600;
            color: $primary-color-text;
          }
          img {
            margin-right: 0.5em;
          }
        }
        &:last-child {
          margin: auto 0;
        }
      }
    }
  }
}

.wix-client-name-dropdown-menu {
  .ant-dropdown-menu-item-only-child {
    flex-direction: column;
    display: flex;
    padding: 0;

    .ant-cascader-picker {
      .ant-cascader-input {
        border: 0;
        &:focus {
          box-shadow: none;
          -webkit-box-shadow: none;
        }
      }
    }

    .ant-cascader-menus {
      width: 100%;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: transparent;

      .ant-cascader-menu {
        box-shadow: $active-box-shadow;
        background: white;
        height: auto;

        &:first-child {
          width: 100%;
        }

        &:last-child {
          max-height: 30vh;
          @include thin-custom-scrollbar;
          overflow-x: auto;
        }

        .ant-cascader-menu-item {
          color: $primary-color-text;
          padding: 0;

          .wix-ant-cascader-menu-item-load-more {
            margin: 0 auto;
          }

          &.ant-cascader-menu-item-expand,
          &.ant-cascader-menu-item-disabled {
            padding: 1em;
          }

          &:hover {
            background-color: $input-disabled-color;
          }

          .wix-ant-cascader-menu-item {
            padding: 1em;
            justify-content: space-between;
            display: flex;
            width: 100%;
          }
        }
      }
    }

    .ant-select {
      .ant-select-focused .ant-select-selector {
        border-color: white !important;
      }
      .ant-select-selector {
        border: 0 !important;
        box-shadow: none !important;

        .ant-select-selection-placeholder {
          .ant-row {
            .anticon {
              margin: auto 0;
              margin-right: 0.5em;
            }
          }
        }
      }
      .ant-select-dropdown {
        width: 100% !important;
        .rc-virtual-list {
          .rc-virtual-list-holder {
            .rc-virtual-list-holder-inner {
              .ant-select-item {
                padding: 0;
                .ant-select-item-option-content {
                  span {
                    display: inline-block;
                    width: 100%;
                    padding: 1em;
                    &:last-child {
                      margin-left: 1em;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.language-rtl {
    .ant-select-dropdown {
      .ant-select-item-empty {
        text-align: right;
      }
      .rc-virtual-list-holder-inner {
        .ant-select-item-option-content {
          flex-direction: row;
          display: flex;
          span:last-child {
            margin-right: 1em;
          }
        }
      }
    }
    .ant-cascader-menus {
      direction: ltr;
    }
    .ant-cascader-picker {
      .ant-cascader-picker-label,
      .ant-input {
        padding-right: 11px;
        padding-left: 24px;
        text-align: right;
      }
    }
    .ant-cascader-picker-clear,
    .ant-cascader-picker-arrow {
      right: unset;
      left: 12px;
    }
    .ant-cascader-menu-item {
      direction: rtl;
      text-align: right;
      padding-right: 30px !important;
    }
  }
}

.wix-template {
  width: 10%;
  font-size: 65%;
}
