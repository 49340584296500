.admin-nav-btn {
    cursor: pointer;
    &:hover {
        color: #2f2c88
    }
}

.admin-nav-mid-wrapper {
    display: flex;
    width: 100%;
    margin: 0 50px;
    a {
        color: rgba(0, 0, 0, 0.65) !important;
        &:hover {
            color: #2f2c88 !important
        }
    }
    .admin-nav-btn, a {
        margin-right: 25px;
    }
    .active {
        color: #2f2c88 !important
    }
}

.admin-nav-end-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .admin-nav-btn {
        margin-left: 30px;
    }
    .lang-button {
        text-transform: capitalize;
        border: 1px solid #2e2d88 !important;
        color: #2e2d88;
        border-radius: 4px;
    }
}