@import 'styles/theme.scss';
.prosper-icon-button {
  display: flex !important;
  box-sizing: border-box !important;
  box-shadow: 0px 5px 20px rgba(241, 92, 60, 0.2) !important;
  border-radius: 4px !important;
  align-items: center !important;
  font-size: 16px !important;
  height: 44px !important;
  font-weight: 600 !important;
  background: $secondary-color !important;
  color: white !important;
  justify-content: center !important;
  text-align: center !important;
  border: 2px solid $secondary-color !important;
  padding: 0px 8px !important;

  &:hover {
    background: $secondary-color-hover !important;
    color: rgb(255, 255, 255) !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-color: #f9726e !important;
    border-image: initial !important;
    text-decoration: none !important;
  }

  &:focus {
    background: $secondary-color !important;
    color: rgb(255, 255, 255) !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-color: transparent !important;
    border-image: initial !important;
    text-decoration: none !important;
  }

  min-width: 160px;

  .prosper-button-text {
    flex-grow: 1;
    margin-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .prosper-button-add-icon {
    flex-grow: 0;
    display: flex;
    align-items: center;
    margin-left: 0;
  }
}
