@import 'styles/theme.scss';

.affiliate {
  .page-header {
    margin-bottom: 2em;
  }

  .update-btn,
  .affiliate-btn {
    background: $secondary-color;
    border: unset;
    box-shadow: $light-box-shadow;
    border-radius: 4px;
    color: #fff;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    height: 44px;
    line-height: 1;
    &:hover,
    &:focus {
      background: $secondary-color;
      color: #fff;
      opacity: 0.8;
    }
  }
  .affiliate-btn {
    padding: 0 6px 0 20px;
    .anticon {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      width: 28px;
      height: 28px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .link-text {
      text-align: left;
      min-width: 100px;
    }
  }

  .affiliate-main-content {
    background-color: $white-color;
    box-shadow: $box-shadow;
    border-radius: 4px;
    border: 1px solid transparent;

    h3 {
      color: $primary-color-dark;
      font-size: 24px;
      margin-bottom: 20px;
    }
    .m-t-20 {
      margin-top: 20px;
    }

    .checkout {
      border: 1px solid white;
      border-bottom-color: $tab-border;
      .checkout-left {
        padding: 2.5em;

        .payout-active {
          opacity: 1;
        }

        h3 {
          color: $primary-color-dark;
          font-size: 24px;
          margin-bottom: 10px;
        }

        p {
          color: black;
          font-size: 16px;
          margin-bottom: 0px;
        }

        h1 {
          font-weight: 600;
          font-size: 56px;
          color: $secondary-color;
          opacity: 0.8;
          margin-bottom: 10px;
          & .active {
            color: $completed-color;
          }
        }

        .ant-btn {
          height: 44px;
          border-radius: 4px;
          font-weight: 600;
          font-size: 16px;
          width: 100%;
          overflow: hidden;
          &.active {
            color: $completed-color;
            border-color: $completed-color;
          }
          &[disabled] {
            background-color: white;
          }
        }

        @media (max-width: 1200px) {
          .ant-btn {
            width: auto;
          }
        }
      }

      .checkout-right {
        padding: 2.5em;
        h3 {
          color: $primary-color-dark;
          font-size: 24px;
          margin-bottom: 0px;
        }
        .heading-bar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 33px;
        }
        .ant-form-item {
          .ant-form-item-label label {
            color: $label-color-dark;
          }
        }
        .email-input {
          width: 70%;
          height: 44px;
        }
      }

      .checkout-divider {
        flex: 0;
        .ant-divider.ant-divider-horizontal {
          height: 100%;
          border-left: 1px solid $tab-border;
          border-top: none;
          margin: 0;
        }
      }

      @media (max-width: 1200px) {
        .ant-col-lg-24.checkout-divider {
          flex: 0 0 100%;
          .ant-divider {
            border-top: 1px solid $tab-border !important;
            display: -ms-flexbox;
            display: flex;
            clear: both;
            width: 100%;
            min-width: 100%;
            margin: 24px 0;
            border-left: 0;
          }
        }
      }
    }

    .link {
      padding: 2.5em;
      border: 1px solid white;
      border-bottom-color: $tab-border;
      .heading {
        display: flex;
        align-items: center;
        h3 {
          color: $primary-color-dark;
          font-size: 24px;
          margin-bottom: 0px;
          margin-right: 40px;
        }
        span {
          color: $label-color-dark;
          font-size: 0.85em;
          letter-spacing: 0.5px;
        }
      }

      .link-input-wrapper {
        width: 100%;
        display: flex;
        margin-top: 15px;
        justify-content: space-between;
        .affiliate-link {
          height: 44px;
          width: calc(100% - 195px);
          outline: none !important;
          box-shadow: none !important;
          border: 0.5px solid #bebebe;
          border-radius: 4px;
          font-size: 15px;
        }
      }
    }

    .social-share {
      .heading {
        padding: 2.5em;
        padding-bottom: 0px;
        h3 {
          margin-bottom: 0px;
        }
      }

      .social-icon-grp {
        width: 100%;
        margin: 20px 20px 0;
        text-align: center;
        color: $primary-color-dark;
        .anticon {
          height: 50px;
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .icon-wrapper {
          cursor: pointer;
          display: flex;
          height: 160px;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: 0 20px 40px;
          border: 1px solid $light-link-border-color;
          border-radius: 4px;
          transition: 0.5s ease all;
          &:hover {
            border: 1px solid $primary-color;
          }
        }

        p {
          margin-bottom: 0px;
          margin-top: 25px;
          font-size: 1em;
          @include rwd-min(1200) {
            font-size: 1.2em;
          }
        }
      }
    }

    &.table-block {
      margin-top: 2rem;
      padding: 2.5em;
      .affiliate-table {
        .ant-table-container {
          .ant-table-content {
            table {
              thead {
                background: $tab-dark-background;
                border-radius: 4px;
              }
              thead tr th {
                background: none;
              }
              thead tr th,
              tbody tr td {
                padding: 8px 16px;
                color: $primary-color-text;
              }
              tbody tr {
                border-radius: 4px;
                &:nth-child(2) {
                  background: #f5f6fa;
                }
                td {
                  border: none;
                  font-weight: 100;
                }
              }
            }
          }
        }
      }
    }
  }
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0 !important;
  margin-right: 8px;
}
