.input-number-container {
  display: flex;
  .increment-container {
    display: flex;
    flex-direction: column;
    span {
      border-radius: 6px;
      padding: 2px;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
