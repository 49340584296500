@import "styles/theme.scss";

.empty-user {
  .invite-row {
    background-color: #F7F7FC;
    background: url('https://dr08qaykn9isr.cloudfront.net/media/Group+42460.svg');
    background-size: cover;
    margin: 1em;
    margin-bottom: 3em !important;
    padding: 30px;
    border-radius: 8px;
    
    & > .ant-col {
      &.invite-col {
        padding-top: 40px !important;
        margin-left: 40px !important;
        p {
          font-weight: 400;
          font-size: 16px;
        }
      }
      & > .anticon svg {
        width: 16em;
        height: 16em;
      }
    }
    h3 {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 24px;
      line-height: 140%;
      color: $primary-color-text;
    }
    h4,
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      color: $primary-color-text;
    }
  }

  .ant-row {
    &:last-child {
      .ant-divider-vertical {
        height: auto;
        margin: 0 2em;
        margin-bottom: 1em !important;
      }
      .sub-header {
        font-weight: 600;
        font-size: 17px;
        line-height: 140%;
        color: $primary-color-text;
        margin-top: 0.5em;
      }
      .sub-header-value {
        font-weight: normal;
        font-size: 14px;
        color: $primary-color-text;
      }
    }
  }

  .builtin-row {
    margin-bottom: 2em !important;
    text-align: center !important;
      &.builtin-col {
          text-align: center !important;
      }
      h3 {
        font-size: 24px !important;
        color: $primary-color-text;
        font-weight: 600 !important;
        line-height: 33.6px;
      }
      h4 {
        font-size: 14px !important;
        color: $secondary-color;
        font-weight: 300 !important;
        line-height: 22.4px;
      }
  }
}
