.library-folder-items { 
  user-select: none;
  padding-top: 10px;
  .folder-item {
    position: relative;
    cursor: pointer;
    .tick-icon {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 16px;
      height: 16px;
      z-index: 2;
    }
    .remove-icon {
      opacity: 0;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0;
      left: 0;
      margin: auto;
      z-index: 2;
      height: 24px;
      transition: 0.2s opacity;
    }

    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      left:0;
      right:0;
      margin:6px;
      border-radius: 4px;
      bottom:0;
      top:0;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      background-color: #06044D;
      opacity: 0;
      transition: 0.2s opacity;
    }

    &:hover {
      .remove-icon {
        opacity: 1;
      }
      &::after{
        opacity: .5;
      }
    }
  }
}
