@import 'styles/theme.scss';

.header {
  position: fixed;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0em 3.125em 0em 1em !important;
  border-bottom: solid 1px #e8e8e8;
  background-color: #fff !important;
  box-shadow: $box-shadow;

  .logo, .logo-small {
    float: left;
    img {
      max-width: 163px;
    }
  }
  .logo-small {
    padding-left: 15px;
    padding-right: 30px;
    border-right: 1px solid $tab-border;
  }
}

