@import "styles/theme.scss";

.wix-connect-modal .ant-modal-body {
  padding: 4em;
  .wix-connect-modal-logo-container {
    .wix-connect-modal-logo-background {
      position: absolute;
      top: 0;
      right: 0;
    }
    .logo {
      width: 100%;
    }
  }
  .body {
    font-size: 16px;
  }
}

.wix-disconnect-modal {
  padding: 2.4em;
  .ant-modal-body {
    .title {
      margin-top: 0;
      margin-bottom: 0.6em;
      justify-content: flex-start;
    }

    .divider {
      margin: 0;
    }

    .body {
      text-align: start;
      margin: 3em 0;

      .ant-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;

        span {
          margin: auto 0;
          .ant-avatar {
            margin-right: 0.5em;
          }
        }

        .wix-disconnect-modal-manage {
          background: $secondary-color;
          border-radius: 2em;
          width: 10em;
          text-align: center;
          color: white;

          .ant-btn-loading-icon {
            position: absolute;
            margin-left: -1.5em;
            .anticon-loading {
              padding: 0;
              margin-right: 8px;
            }
          }

          &:hover {
            background-color: $secondary-color-hover;
            border-color: $secondary-color;
          }
        }
      }
    }
  }
}

.wix-connect-select-dropdown {
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .ant-select-item {
        &.item-brand-name-select-option-new {
          background-color: transparent;
          border-top: 1px solid $tab-border;
        }
        .ant-select-item-option-content {
          .ant-row {
            .ant-avatar {
              margin-right: 0.5em;
            }
            .ant-col:first-child {
              display: flex;
              img {
                height: 2em;
                width: 2em;
                margin-right: 0.5em;
                display: block;
              }
              .item-name {
                margin: auto;
              }
            }
            button {
              width: 100%;
              background: $light-color;
              border: 2px solid $light-color;
              color: $primary-color-text;
              border-radius: 4px;
              font-weight: 600;
              &:hover {
                opacity: 0.8;
                background: $light-color;
                border-color: $light-color;
              }
            }
          }
        }
      }
    }
  }
}
