@import 'styles/theme.scss';

.common-card{
  width: calc(50% - 2em);
  
  @media screen and (min-width: 992px) {
    width: calc(33% - 2em);
  }
  @media screen and (min-width: 1200px) {
    width: calc(25% - 2em);
  }
  @media screen and (min-width: 1600px) {
    width: calc(20% - 2em);
  }
}

.content-library-item {
  display: flex;
  background: $white-color;
  border-radius: 10px;
  height: calc(100vh - 190px);

  .empty-wrapper {
    justify-content: center;
  }

  ul {
    list-style: none;
    padding-left: 10px;

    li {
      color: $primary-color;
      font-size: 14px;
      text-transform: capitalize;
      padding: 10px 0px;
      font-weight: bold;
    }
  }

  .split-d1 {
    padding: 10px;
    padding-right: 0;
    width: 30%;
    position: relative;
  }
  .split-d2 {
    width: 70%;
  }

  .anticon-star:hover{
    opacity: 0.6;
  }

  @media screen and (min-width: 1200px) {
    .split-d1 {
      width: 300px;
    }
    .split-d2 {
      width: calc(100% - 300px);
    }
  }

  .split-d1 > .sidemenu-list {
    height: calc(100% - 54px) !important;
    overflow-y: auto;
  }

  .split-d2 .item-row {
    &.covers-card,
    &.medias-card {
      .ant-card-cover {
        padding-top: 6 / 13 * 100%;
      }
    }

    .content-library-card .card-content {
      // padding-top: 171 / 226 * 100%;
      padding: 0;
      overflow: hidden;
      position: relative;

      .card-description {
        padding: 16px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: auto;
        width: 100%;
      }
    }

    &:not(.covers-card):not(.medias-card) .ant-card-cover {
      padding-top: 13 / 16 * 100%;
    }

    .ant-card-cover {
      position: relative;
      overflow: hidden;

      .logo {
        position: relative;
      }

      > img,
      > .ant-image > img,
      > .mask-class-name,
      > .ant-image {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .loader {
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 34px;
        height: 34px;
      }
    }
  }

  .split-d2 {
    padding: 12px;
    border-left: 1px solid #f0f2f5;
    -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.3s;
    transition: border-color 0.3s, -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s, border-color 0.3s;
    transition: box-shadow 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;

    .divider-line {
      margin: 0 1%;
      height: 1px;
      background: #e8e8eb;
      margin-bottom: 1.2em;
      position: relative;
    }

    .scroll-wrapper, .template-scroll-wrapper, .section-scroll-wrapper {
      height: calc(100% - 39px);
      overflow-y: auto;

      & > .item-row {
        flex-wrap: wrap;
        margin-bottom: 0;

        .empty-wrapper {
          justify-content: center;
        }
      }

      &.full-page-covers {
        .item-row {
          &.covers-card {
            .ant-card-cover {
              padding-top: 18 / 13 * 100%;
            }
          }
        }
      }

      .item-row {
        &::not(.empty-wrapper) {
          margin: 20px 0;
        }
        display: flex;

        .ant-card-body {
          padding: 0;
        }
      }

      .ant-card {
        padding: 0;
        margin: 1em;
        border-radius: 8px;
        @extend .common-card;
      }
    }

    .add-folder {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f6fa;
      padding: 10px;
      color: $primary-color;
      font-size: 14px;
      font-weight: bold;
      border-radius: 4px;
      width: 80%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      float: none;
      margin: auto;

      .add-title {
        padding-left: 10px;
      }
    }

    .all-starred {
      padding-left: 28px;
      display: flex;
      align-items: center;

      .anticon {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .star-icon {
        display: inline-block;
        margin: 2px;
      }
    }

    .ant-tree-title,
    .star-title {
      color: $primary-color;
      text-transform: capitalize;
      padding: 7px 5px;
      font-size: $label-font-size;
      line-height: 140%;
    }

    .stared-header {
      color: $primary-color-text;
      font-weight: bold;
      line-height: 140%;
      padding-left: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    .star-badge {
      padding-left: 12px;

      .ant-scroll-number {
        background: #f5f6fa;
        color: $primary-color-text;
        padding: 2px;
        font-weight: 400;
        width: 42px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .split-d1,
    .split-d2 {
      height: calc(100vh - 246px);
      min-height: 150px;
    }
  }

  &.content-library-item-section {
    .covers-card {
      .content-library-card {
        height: 16.5em;
        @extend .common-card;

        .ant-card-body {
          height: 14em;
          position: relative;
          .card-content {
            height: 19.5em;
            position: relative;
            .card-description {
              overflow: hidden;
              height: 48em;
              transform: scale(0.37, 0.37);
    width: 735px;
              transform-origin: left;
              -webkit-transform-origin: top left;
              img {
                width: 100%;
                max-width: 100%;
                vertical-align: middle;
                display: inline-block;
              }
            }
          }
        }
        .card-title {
          bottom: -33px;
          position: absolute;
        }
      }
    }
  }
}

.content-library-item,
.move-to-library-list {
  .content-library-new-folder {
    background: #f5f6fa;
    margin-right: 5px;
    margin-top: 10px;
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    color: #05034d;
    border: none;
  }
}

