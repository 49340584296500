.code-delete-modal {
  z-index: 9999;
  .button-wrapper {
    .ant-btn {
      width: 100%;
    }
    .confirm {
        margin-right: 1em;
      }
      .cancel {
        margin-left: 1em;
      }
  }
}