@import 'styles/theme.scss';

.integration-item {
  margin-top: 12px;
  padding: 1.5em;
  min-height: 80px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(3, 20, 63, 0.1);
  border-radius: 4px;
  align-items: center;

  .item-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-transform: capitalize;
  }

  .item-sub-name {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    text-transform: capitalize;
    padding-top: 5px;
    padding-left: 3px;
  }

  .item-description {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: $primary-color-text;
  }

  .item-active {
    color: $success-color;
  }

  a {
    text-decoration: underline;
    color: $primary-color-text !important;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;

    &:hover {
      color: $secondary-color !important;
      text-decoration: underline;
    }
  }

  .value {
    font-weight: normal;
    font-size: 16px;
    color: $primary-color-text;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
  }

  .integration-button {
    height: 44px;
    border-radius: 4px;
    width: 169px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-transform: capitalize;
  }

  .item-brand-name {
    display: flex;
    align-items: center;
    margin-left: 15px;
    img {
      height: 1.3rem;
      margin-right: 10px;
    }
    .anticon {
      margin-right: 10px;
      svg {
        width: 30px !important;
        height: 30px !important;
      }
    }
    .ant-col {
      width: 100%;
      .ant-select {
        .ant-select-selector {
          height: auto;
          margin-bottom: 0;
          padding: 0;
        }
      }
    }
    .ant-select {
      width: 70%;
      &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: none;
      }
      .ant-select-selector {
        border: none;
        border-bottom: 1px solid $tab-border;
        height: 40px;
        margin-bottom: 1em;
        padding-left: 0;
        .ant-select-selection-placeholder {
          color: $primary-color-text;
          font-weight: 600;
          font-size: 18px;
        }

        .ant-select-selection-item {
          .ant-row {
            padding: 0 !important;
            .ant-col:last-child {
              display: none;
            }
          }
        }
      }
      .ant-select-arrow {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        right: 0;
        top: 30%;

        .anticon {
          pointer-events: none;
        }
      }
    }
  }

  .item-status-description {
    margin-left: 15px;
  }

  .integration-link {
    display: inline-block;
    padding-left: 0.5em;
    padding-right: 4em;
    margin: auto;
  }

  .client-payment-details {
    margin-top: 10px;
    width: 100%;
    align-items: flex-end;
    .payment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .col-flex {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
      }
      .payment-integration-input {
        width: 17em;
      }
    }
    .input-wrapper {
      .flex {
        display: flex;
        width: 100%;
      }
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    .label {
      font-weight: normal;
      font-size: 15px;
      line-height: 140%;
      color: $label-color-light;
    }
    input {
      border: 1px solid $tab-border;
      border-radius: 4px;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
    }
    .ant-select {
      width: 6em;
      .ant-select-arrow .anticon:not(.ant-select-suffix) {
        pointer-events: none;
      }
    }
    .save-edit-button {
      background: #ffffff;
      -webkit-box-shadow: $box-shadow;
      box-shadow: $box-shadow;
      border-radius: 4px;
      height: 29px;
      width: 4.5em;
      border: none;
    }

    .switch-toggle-button {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 3px;
      padding-right: 3px;
      background: $light-color;
      border-radius: 4px;
      height: 38px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;

      button {
        color: $white-color;
        font-weight: 600 !important;
        font-size: 14px;
        border-color: $secondary-color;
      }

      .anticon {
        padding-top: 2px;
      }

      .ant-dropdown-trigger {
        border-left-color: $white-color;
        .anticon > svg > path {
          fill: $white-color;
        }
      }

      &:hover {
        color: $white-color;
        text-decoration: none !important;
      }

      &:focus {
        text-decoration: none !important;
      }

      .anticon svg {
        display: inline-block;
        overflow: overlay;
        @supports (-moz-appearance: none) {
          overflow: auto;
        }
      }

      .ant-radio-button-wrapper {
        position: relative;
        display: inline-block;
        margin: 0;
        color: $primary-color-text;
        font-size: 12px;
        line-height: 30px;
        background: none !important;
        border: 0 !important;
        & > :hover {
          opacity: 0.8;
          color: $primary-color-text !important;
        }
        & > :active {
          opacity: 0.8;
        }
        box-shadow: none !important;
      }

      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        z-index: 1;
        font-weight: 600 !important;
        color: $primary-color-text !important;
        background: $white-color !important;
        border-radius: 4px !important;
        &:first-child {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
        &:last-child {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }

      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: $white-color !important;
        border-right-color: 0 !important;
      }

      .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: none !important;
        visibility: hidden !important;
      }

      @include xs {
        padding-top: 6px;
        .ant-radio-group {
          display: flex;
          .switch-toggle-button-text {
            display: none;
          }
        }
      }
    }

    .enable-radio-group {
      margin-left: 46px !important;
      color: $primary-color-text !important;
      div {
        .ant-radio-group.ant-radio-group-outline {
          label.ant-radio-wrapper {
            span {
              span.switch-toggle-button-text {
                color: $primary-color-text !important;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1293px) and (min-width: 992px) {
    .integration-button {
      width: 120px;
    }

    .integration-link {
      padding-right: 0.5em;
    }
  }

  @media screen and (max-width: 660px) {
    .integration-link {
      padding: 1em 0.2em;
      display: block;
    }
  }
}

.ant-dropdown-menu-item span:first-child {
  vertical-align: middle !important;
}

.ant-radio-checked {
  .ant-radio-inner {
    border-color: #7761df !important;
  }
  color: $primary-color-text !important;
}
.ant-radio-inner::after {
  background-color: #7761df !important;
}

.ant-select-dropdown .rc-virtual-list-holder-inner .client-payment-currency-option {
  padding: 0.5em;
}

.item-brand-name-select-option {
  .ant-row.item-select-row {
    display: flex;
    justify-content: space-between;
    padding-left: 0 !important;
    .ant-col {
      .item-name {
        text-transform: capitalize;
      }
    }
    .ant-col:last-child {
      display: none;
      .anticon {
        svg circle {
          fill: $primary-color;
        }
      }
    }
  }

  .ant-select-item-option-content {
    img {
      display: none;
    }
  }

  &.ant-select-item-option-selected {
    .ant-row .ant-col:last-child {
      display: block;
    }
  }
}

.popconfirm-integration-item {
  width: 20%;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(4, 3, 49, 0.15);
  border-radius: 4px;

  .ant-popover-content {
    .ant-popover-inner {
      .ant-popover-inner-content {
        .ant-popover-message .ant-popover-message-title {
          .ant-row {
            color: $primary-color-text;
            margin-bottom: 0.5em;
            &:first-child {
              font-weight: 600;
              font-size: 14px;
            }
          }
          .button {
            width: 7em;
            height: 32px;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            margin-right: 1em;
            margin-top: 0.5em;
          }
          .confirm {
            background: $secondary-color;
            border: 2px solid $secondary-color;
            box-shadow: $light-box-shadow;
            &:hover {
              opacity: 0.8;
              background: $secondary-color;
              border-color: $secondary-color;
            }
          }
          .cancel {
            background: $light-color;
            border: 2px solid $light-color;
            color: $primary-color-text;
            &:hover {
              opacity: 0.8;
              background: $light-color;
              border-color: $light-color;
            }
          }
        }
        .ant-popover-buttons {
          display: none;
        }
      }
    }
  }
}

.slack-notification-settings {
  background-color: $light-color !important;
  border-radius: 4px !important;
  border: 0px !important;
  margin: 0em 0em 0em 1em !important;
  color: $primary-color-text !important;
  font-weight: 600 !important;
  .slack-slider-icon {
    vertical-align: middle;
  }
}

.ant-dropdown-menu-item .integration-item .item-active {
  vertical-align: unset !important;
}
