.quickbooks-connect-modal {
  .title {
    margin: 0 auto;
  }
  .button-wrapper {
    .quickbooks-connect {
      width: auto;
      padding: 0;
      border: 0;
      img {
        height: 100%;
      }
    }
    .quickbooks-disconnect {
      background-color: #2aa01c;
      border: 0;
      &:hover {
        background-color: #1c900d;
      }
    }
  }
}

.quickbooks-disconnect-modal.confirm-modal {
  .button-wrapper {
    button {
      width: fit-content;
    }
  }
}