@import "styles/theme.scss";

.activity {
  .activity-list {
    width: 100%;
    @include custom-scrollbar;
    overflow: auto;
    .empty{
      margin-top: 20vh;
    }
    .new-proposal-activity-align {
      margin: 20px auto 0 !important;
      align-items: center !important;
      text-align: center !important;
    }
    .fetch-activity-visible {
      visibility: visible !important;
    }
    .fetch-activity-hidden {
      visibility: hidden !important;
    }
    .load-more {
      height: 44px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: auto;
      margin-top: 10px;
      align-items: center;
      display: flex;
      box-sizing: border-box;
      box-shadow: 0px 10px 15px -4px #d5d5d566;
      border-radius: 4px;
    }
  }
  .loader-container {
    margin-top: 4em;
  }

  .month-name {
    margin-top: 1.25em;
    font-weight: 300;
    line-height: 140%;
    color: $primary-color;
    font-size: $medium-text-font-size;
  }

  .empty-view {
    margin-top: 6em;
  }

  .scrollTop.arrow-top-button {
    background: $white-color;
    border: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $box-shadow-avatar;
    opacity: 1;

    transition: opacity 500ms, transform 500ms;
    transform: scale(1);
    &.hide {
      opacity: 0;
      transform: scale(0);
    }
  }
}

.handle-user-lock .main-content .activity {
  padding-top: 100px !important;
}

.loader.display-block {
  display: block;
  margin: auto 28rem;
}

.activity-menu-wrapper {
  left: 77px !important;
  top: 200px!important;
  z-index: 20!important;

  .activity-list {
    background: $white-color;
    box-shadow: 0px 0px 1px rgba(99, 114, 130, 0.32), 0px 8px 16px rgba(27, 39, 51, 0.08);
    border-radius: 4px;
    @include custom-scrollbar;
    overflow-y: auto;
    height: 60vh;
    max-width: 30em;
    transform: translateY(40px);
    .user-activity-list {
      padding: 0em 0.5em 0em 1.5em;
      box-shadow: none;
      .ant-divider {
        margin: 0.5em 0;
        border-top-color: $tab-border;
        &:before {
          width: 0;
        }
        span {
          background: none;
          padding: 0;
          margin: 0;
          padding-right: 0.6em;
          padding-top: 3px;
        }
      }

      .ant-list {
        .ant-spin-nested-loading .ant-spin-container .ant-list-items {
          .ant-list-item {
            padding: 0.5em 0;
            border-bottom: 0;
            .ant-list-item-meta {
              flex-direction: column;
              align-items: start;
              .ant-list-item-meta-avatar {
                margin-right: 0;
                width: 100%;
                .user-activity-list-avatar-container {
                  .ant-avatar {
                    min-width: unset !important;
                  }
                  .ant-avatar.ant-avatar-circle {
                    width: 2.3em;
                    height: 2.3em;
                    line-height: 2.3em;
                  }
                  .user-activity-list-avatar-content {
                    flex-direction: row;
                    display: flex;
                    a {
                      font-size: $label-font-size;
                      margin-bottom: 0;
                    }
                    p{
                      font-size: $sub-font-size;
                      margin: 0.25em 0 0 0.5em;
                    }
                  }
                }
              }
              .ant-list-item-meta-content {
                width: 100%;
                margin-top: 0.6em;
                .ant-list-item-meta-title span {
                  font-size:$label-font-size;
                }
              }
            }
          }
        }
      }
    }

    .user-activity-list-loader {
      margin: 2em 0;
      position: relative;
    }
  }
}

.activity-list {
  .empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .secondary-btn{
      margin: 0 auto;
    }
  }
  .ant-empty {
    color: $primary-color-text;
    padding: 0 20px 20px;
  }
}