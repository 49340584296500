@import "styles/theme.scss";

.custom-select {
  height: 1.5em;
  min-width: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 1px;
  position: relative;

  .custom-select-selector {
    border-radius: 4px;
    padding: 0 11px;
    display: flex;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 24px;
    position: relative;
    background-color: #fff;
    border: 1px solid $border-color-base;
    align-items: center;
    cursor: pointer;
    .custom-select-selection-item {
      font-size: $sub-font-size;
      line-height: 22px;
      text-align: initial;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .anticon-down {
      pointer-events: none;
      font-size: $sub-font-size;
      right: 0;
      margin-left: 11px;
      text-transform: none;
      vertical-align: -0.125em;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
    }
  }

  .custom-select-dropdown-list {
    @include custom-scrollbar;
    display: none;
    position: absolute;
    top: 1.5em;
    min-height: 1em;
    max-height: 13.5em;
    overflow-y: overlay;
    width: 100%;
    border-radius: 4px;
    background: white;

    @supports (-moz-appearance:none) {
      overflow-y: auto;
    }
    
    .custom-select-item {
      color: #2f2c88;
      text-align: initial;

      border-bottom: 1px solid #cbcce0;
      position: relative;
      display: block;
      min-height: 32px;
      padding: 5px 12px;
      font-weight: normal;
      font-size: $label-font-size;
      line-height: 22px;
      cursor: pointer;
      transition: background 0.3s ease;

      &.selected {
        font-weight: 600;
        background-color: #bdbbc7;
      }

      .custom-select-item-content {
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
