@import "styles/theme.scss";

.user-page {
  .page-header {
    margin-bottom: 2em;
  }

  .ant-card {
    box-shadow: $box-shadow;
    border-radius: 4px;
    .ant-card-body {
      min-height: 70vh;
      padding: 2.3em;
    }
  }

  h3,
  h4,
  p {
    color: $primary-color;
  }
}

