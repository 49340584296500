@import "styles/theme.scss";

.xero-account-select{
  width: 100%;
}

.body-accounts-map {
  margin: 2em 0;
  max-height: 20em;
  overflow-y: auto;
  .ant-col {
    .tax-discount {
      font-size: smaller;
    }
    .ant-row {
      .head-cost {
        text-align: start;
        font-size: large;
      }
      .total-cost {
        text-align: start;
        font-size: large;
        font-weight: 600;
      }
      & > .ant-col-12 {
        text-align: start;
        color: $label-color-light;
        padding: 5px;
        line-height: 2em;
      }
      & > .ant-col-10 {
        text-align: end;
        .ant-select {
          width: 66%;
          .ant-select-selector {
            border-radius: 4px;
          }
          .ant-select-arrow {
            top: 45%;
          }
          .ant-select-selection-item {
            color: $primary-color-text;
          }
        }
      }
      & > .ant-col > .input-container {
        width: 100%;
        margin-bottom: 0;
        display: flex;
        justify-content: flex-end;
        .ant-input-wrapper {
          justify-content: flex-end;
          display: flex;
          .ant-input-group-addon {
            display: flex;
            justify-content: center;
            padding: 0.2em 1em;
          }
        }
        .input-suffix-text,
        .input-prefix-text {
          font-weight: 400;
        }
        .ant-input {
          width: 50%;
        }
      }
      &.xero-organisation {
        margin: 0em 0em 1em 0em;
      }
      &.morning-language {
        margin: 1em 0em 0em 0em;
      }
    }
  }
  .ant-form {
    .ant-row {
      .ant-form-item-label {
        color: $label-color-light;
        letter-spacing: 1px;
      }
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            input {
              border: 1px solid $primary-color-light;
              border-radius: 4px;
              color: #05034d;
            }
            .ant-select {
              .ant-select-selector {
                border: 1px solid $primary-color-light;
                border-radius: 4px;
                input {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.accounts-head {
  background: $background-color;
  font-weight: 600;
}

// .account-map {
//   width: 40em !important;
// }

.account-map-button {
  width: 12em !important;
}