@import "styles/theme.scss";
.profile {
  .profile-header {
    margin-bottom: 2em;
  }

  &.show-password-form {
    min-height: calc(100% - 74px);
  }
  .link-btn {
    padding: 0;
    font-weight: 500;
    color: $primary-color-text;
    margin-left: 20px;
    margin-bottom: 40px;
    &.cancel {
      color: $light-link-border-color;
      opacity: 0.8;
    }
    span {
      text-decoration: underline;
    }
  }

  .change-password {
    margin-left: 40px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $tab-border;
    margin-top: 20px;
  }

  .password-form {
    margin: 20px;
    &.content {
      margin-bottom: 0px;
    }
    .ant-form-item {
      margin-bottom: 6px !important;
    }
  }

  .password-actions {
    margin-bottom: 30px;
    margin-left: 20px;
  }

  @include md {
    .profile-header {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
    .profile-form {
      width: 100% !important;
      margin-left: 60px !important;
      padding-right: 30px !important;
    }
  }

  @include sm {
    .profile-form {
      width: 100% !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
      .ant-form-item-control {
        width: 100% !important;
      }
      .reset-profile-btn {
        display: block !important;
        height: 44px !important;
        margin: 0px !important;
      }
    }
  }

  .profile-form {
    background: $white-color;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(3, 20, 63, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0 !important;
    .profile-actions {
      display: flex;
      justify-content: flex-start;
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 40px;
      .save-btn,
      .delete-btn,
      .reset-profile-btn {
        height: 44px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      .save-btn {
        background: $secondary-color !important;
        color: white !important;
        border: 2px solid $secondary-color !important;

        &:hover,
        &:focus {
          background: $secondary-color !important;
          color: rgb(255, 255, 255) !important;
          border-width: 2px !important;
          border-style: solid !important;
          border-color: transparent !important;
          border-image: initial !important;
          text-decoration: none !important;
        }
      }
      .delete-btn {
        background: $tab-background;
        color: $primary-color-text;
        border: none;
      }
      .primary-btn {
        margin-right: 20px;
        @include sm {
          width: 100%;
          margin-bottom: 20px !important;
        }
      }
      .reset-profile-btn {
        margin-bottom: 0;
        height: 100%;
      }
    }
    .content {
      @include sm {
        width: 100%;
        margin-left: 15px !important;
      }
      margin-top: 40px;
      margin-left: 20px;
      margin-right: 20px;
      .input-container {
        height: fit-content;
        margin-bottom: 0px;
        input {
          @include sm {
            width: 100% !important;
          }
          height: 32px;
        }
        .ant-progress {
          .ant-progress-inner {
            height: 64px !important;
            width: 64px !important;
          }
        }
        .ant-select-single {
          .ant-select-selector {
            .ant-select-selection-search {
              right: 0 !important;
              left: 0 !important;
            }
          }
        }
        .ant-select:not(.ant-select-customize-input) {
          .ant-select-selector {
            height: 34px !important;
            .ant-select-selection-search-input {
              padding: 4px 11px !important;
            }
          }
        }
        
      }
    }
    .ant-form-item {
      padding: 0 20px;
      margin-bottom: 8px;
      .ant-form-item-label {
        padding-bottom: 4px;
        label {
          color: $label-color-light;
          display: flex;
          justify-content: space-between;
        }
      }
      .change-btn {
        color: $primary-color;
        background: $light-color;
        border: none;
        height: 24px;
        font-size: 12px;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.04em;
        margin-left: 1em;
      }
    }
  }
}
