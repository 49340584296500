@import 'styles/theme.scss';
.proposal-builder-signature {
  .ant-modal-body {
    padding-top: 24px 32px;
    .title {
      margin-bottom: 32px !important;
    }
  }
}

.ant-modal-centered .ant-modal.upload-signature {
  width: 43em !important;

  &.language-rtl {
    text-align: right;
    direction: rtl;
    font-family: Assistant, sans-serif;
  }
  .title {
    margin-top: 0.5em;
    font-weight: 300;
    font-size: 32px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: $signature-color;
  }

  .accept-text {
    margin-top: 1.5em;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: $signature-color;
    opacity: 0.7;
  }

  .sig-options {
    margin-top: 1em;
    .ant-select {
      width: 100%;
    }
  }

  .error-field {
    color: $error-color;
  }

  .select-error .ant-select-selector {
    border-color: $error-color !important;
  }

  .radio-wrapper {
    margin-top: 1em;
    .ant-radio-group {
      display: flex;
      justify-content: space-between;
      gap: 5px;
      .ant-radio-wrapper {
        padding: 5px;
        width: 12em;
        border-radius: 4px;
        font-size: 14px !important;
        color: $primary-color-text !important;
        border: 1px solid $tab-border;
        margin-right: 0;

        &.ant-radio-wrapper-checked {
          border: 1px solid $primary-color-light;
        }
      }
    }
  }

  .signature-container {
    background: $tab-background;
    min-height: 224px;
    .item-title {
      margin-top: 1em;
      text-align: center;
      padding-top: 1em;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: $signature-color;
    }
    .typed-signature {
      height: 3em;
      font-size: 14px;
      padding: 5px;
      display: flex;
      align-items: center;
      width: 490px;
      margin: 0 auto;
      cursor: pointer;
      border: 0;
    }
    .signature-wrapper {
      position: relative;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 4px;
      min-height: 150px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 490px;
      margin: 0 auto;
      .anticon {
        justify-content: center;
        display: flex;
        height: 100%;
        svg {
          height: 100%;
          font-size: 30px;
        }
      }
      .hoverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        .anticon {
          display: none;
          color: white;
        }
      }
      .sigpic-wrap > img {
        width: 120px;
        height: 75px;
        object-fit: contain;
      }
      &.sig-draw {
        border-bottom: 3px dashed $signature-color;
      }
      &:hover {
        .hoverlay {
          width: 100%;
          height: 100%;
          background-color: $signature-hover-background;
          cursor: pointer;
          opacity: 0.8;
          outline: 1px solid $tab-background;
          .anticon {
            display: flex;
          }
        }
      }
    }
    .btn-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 20px 12px 12px;
    }
  }

  .signature-text {
    word-break: break-all;
    padding: 0 16px 0 19px;
    font-family: Sacramento, sans-serif;
    font-size: 45px;
    margin: 0 auto !important;
    min-height: 108px !important;
    height: 108px !important;
  }

  .update-checkbox {
    margin-top: 20px;
    justify-content: flex-start;
    display: flex;
  }

  .button-wrapper {
    margin-top: 3em;
    display: flex;
  }

  .button {
    border-radius: 4px;
    height: 44px;
    border: none;
    font-weight: 600;
  }

  .confirm {
    background-color: $section-color;
    color: $white-color;
    &:hover,
    &:active {
      background-color: $section-color;
      opacity: 0.8;
      color: $white-color;
      text-decoration: none !important;
    }
  }

  .cancel {
    margin: 0 0.5em;
    background-color: $publish-background;
    color: $section-color;
    &:hover,
    &:active {
      background-color: $publish-background;
      opacity: 0.8;
      color: $section-color;
      text-decoration: none !important;
    }
  }

  .reset-btn {
    @extend .cancel;
    height: 30px;
    margin: 0;
  }
  .erase-btn {
    @extend .cancel;
    margin: 0 8px;
    border-radius: 4px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.8;
    }
  }

  @include xs {
    .ant-modal-body {
      .radio-wrapper {
        .ant-radio-group-outline {
          .ant-radio-wrapper {
            padding: 8px;
            display: flex;
            margin-right: 5px;
            &:last-child {
              margin-right: 0;
            }
            & > span:last-child {
              white-space: pre-wrap;
              word-break: break-word;
              padding-right: 0px;
              margin-top: -3px;
            }

            .ant-radio {
              height: 16px;
            }
          }
        }
      }
      .signature-container {
        input,
        .signature-wrapper,
        canvas {
          width: 95%;
        }
      }
    }
  }
}
