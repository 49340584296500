.gallery-content {
  overflow-x: visible;
  margin-bottom: 10em;
  .image-container {
    position: relative;
    -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.3s;
    transition: border-color 0.3s, -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s, border-color 0.3s;
    transition: box-shadow 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
    cursor: pointer;
    .anticon {
      position: absolute;
      right: 4px;
      top: 24px;
    }
    img {
      width: 100%;
      height: 250px;
      border-radius: 4px;
      margin-top: 5%;
    }
    transition: 0.4s transform;
    &:hover {
      transform: scale(1.05);
    }
  }
}
