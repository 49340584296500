@import "styles/theme.scss";

.simple-info-modal.generate-invoice-modal {
  width: 35em !important;
  .ant-modal-content {
    .ant-modal-body {
      padding: 2.5em;
      .title {
        margin-top: 0;
        justify-content: flex-start;
      }
      .divider {
        margin: 1em 0;
      }
      .body {
        margin: 3.5em 0;
        .ant-col {
          .tax-discount {
            font-size: smaller;
          }
          .ant-row {
            .head-cost {
              text-align: start;
              font-size: large;
            }
            .total-cost {
              text-align: start;
              font-size: large;
              font-weight: 600;
            }
            & > .ant-col-12 {
              text-align: start;
              color: $label-color-light;
              padding: 5px 0;
            }
            & > .ant-col-10 {
              text-align: end;
              .ant-select {
                width: 66%;
                .ant-select-selector {
                  border-radius: 4px;
                }
                .ant-select-arrow {
                  top: 45%;
                }
                .ant-select-selection-item {
                  color: $primary-color-text;
                }
              }
            }
            & > .ant-col > .input-container {
              width: 100%;
              margin-bottom: 0;
              display: flex;
              justify-content: flex-end;
              .ant-input-wrapper {
                justify-content: flex-end;
                display: flex;
                .ant-input-group-addon {
                  display: flex;
                  justify-content: center;
                  padding: 0.2em 1em;
                }
              }
              .input-suffix-text,
              .input-prefix-text {
                font-weight: 400;
              }
              .ant-input {
                width: 50%;
              }
            }
            &.xero-organisation {
              margin: 0em 0em 1em 0em;
            }
            &.morning-language {
              margin: 1em 0em 0em 0em;
            }
          }
        }
        .ant-form {
          .ant-row {
            .ant-form-item-label {
              color: $label-color-light;
              letter-spacing: 1px;
            }
            .ant-form-item-control {
              .ant-form-item-control-input {
                .ant-form-item-control-input-content {
                  input {
                    border: 1px solid $primary-color-light;
                    border-radius: 4px;
                    color: #05034d;
                  }
                  .ant-select {
                    .ant-select-selector {
                      border: 1px solid $primary-color-light;
                      border-radius: 4px;
                      input {
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .button-wrapper {
        padding: 0;
        button.ant-btn {
          width: 100%;
          height: 44px;
        }
        .confirm {
          margin-right: 1em;
        }
        .cancel {
          margin-left: 1em;
        }
      }
    }
  }
}

.ant-input-group-addon {
  cursor: pointer;
}

.ant-input-group-addon:last-child {
  div {
    padding: 0.2em 1em;
  }
}

.currency-alert {
  justify-content: flex-start;
  color: $error-color;
  &:first-child {
    margin: 1em 0em 0em 0em;
  }
  a {
    text-decoration: underline;
  }
}

.xero-message, .morning-message {
  color: $label-color-light;
}