.content-library-skeleton-container {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;

  .item-row {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    .ant-col {
      padding: 0;
      width: 25%;
      height: 180px;
      margin: 0 1%;
      max-width: 23%;
      border-radius: 7px;
      margin-bottom: 20px;
      .content-library-skeleton {
        height: 100%;
        background: rgb(225, 228, 238);
        border-radius: 12px;
        background-image: linear-gradient(90.17deg, #dfe2ec 0.14%, rgba(245, 246, 250, 1.38) 98.52%);
        background-size: 80% 100%;
        background-position: -350% 0;

        background-repeat: no-repeat;
        animation: loading 1.5s infinite;
      }
    }
  }
}

@keyframes loading {
  to {
    background-position: 450% 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
