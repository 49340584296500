@import 'styles/theme.scss';

.testimonial-container {
  display: flex !important;
  align-items: flex-end;
  overflow: hidden;
  .testimonial-card {
    .top-object {
      position: absolute;
      background: url(https://dr08qaykn9isr.cloudfront.net/application/static/topGroup.svg);
      background-size: contain;
      content: '';
      width: 108px;
      height: 105px;
      background-repeat: no-repeat;
      left: 0;
      top: 0;
      @include rwd(992) {
        display: none !important;
        visibility: hidden;
        height: 0;
        max-width: 0 !important;
      }
      @include rwd-max(767) {
        display: none;
      }
    }

    .bottom-object {
      position: absolute;
      background: url(https://dr08qaykn9isr.cloudfront.net/application/static/eclipse.svg);
      background-size: contain;
      content: '';
      width: 91px;
      height: 70px;
      background-repeat: no-repeat;
      right: 0;
      bottom: -18px;
      @include rwd(992) {
        display: none !important;
        visibility: hidden;
        height: 0;
        max-width: 0 !important;
      }
      @include rwd-max(767) {
        display: none;
      }
    }
    color: #fff;
    padding: 28px;
    width: 100%;
    height: 100%;
    min-height: 570px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include rwd-max(767) {
      min-height: 428px;
    }

    .testimonial-content {
      position: relative;
      padding-bottom: 20px;

      .testimonial-quote {
        p {
          position: relative;
          background: url('https://dr08qaykn9isr.cloudfront.net/application/static/quote.svg');
          background-size: contain;
          content: '';
          width: 55px;
          height: 35px;
          background-repeat: no-repeat;
          left: 0px;
          top: 5px;
        }
      }

      .testimonial-text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.6;
        margin-top: 20px;
        font-style: italic;
        opacity: 0.8;
      }
    }

    .testimonial-author {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      .author-image {
        border-radius: 4px;
        width: 50px;
        height: 50px;
        max-height: 50px;
        object-fit: cover;
        object-position: top;
        margin-right: 10px;
      }

      .author-details {
        .author-name {
          font-weight: 500;
          font-size: 16px;
          color: white;
          margin-bottom: 0;
        }

        .author-title {
          font-size: 14px;
          font-weight: 300;
          opacity: 0.7;
          margin-bottom: 0;
        }
      }
    }
  }
}
