@import 'styles/theme.scss';

.form-container {
  cursor: auto;
}

.form-editor {
  .form-editor-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8em;
    padding: 1em 1em 0 1em;

    h5 {
      line-height: 140%;
      margin: auto 0;
    }
  }

  .form-editor-fields-row {
    @include custom-scrollbar;
    padding: 0 1em;
    height: 350px;
    overflow: scroll;
    .ant-list {
      width: 100%;
    }

    .ant-list > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items {
      direction: ltr;

      .ant-list-item {
        width: 47%;
        margin: 0.4em 1em 0.4em 0;
        padding: 0.57em;
        float: left;
        background: $tab-background;
        border-radius: 4px;
        border: 1px solid transparent;
        h4 {
          color: $primary-color-dark;
          font-weight: 400;
          margin: 0;
          word-wrap: none;
          word-break: break-word;
        }
        .anticon {
          margin: 1px 3px;
        }
        .ant-divider {
          display: none;
        }
        .form-editor-edit-button {
          svg {
            width: 20px;
            height: 20px;
            path {
              fill: $secondary-color;
            }
          }
        }
        .form-editor-add-icon {
          margin: -3px;
          svg {
            rect:first-child {
              fill: white;
            }
          }
        }
      }

      .ant-list-item.selected {
        background: white;
        border: 1px solid $primary-color-dark;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0.57em;
        .ant-divider {
          display: block;
          margin: 0px 5px;
          height: 1.2em;
        }
      }
    }
  }

  .form-editor-button-wrapper {
    padding: 0 1em 1em 1em;
    button.ant-btn {
      height: 44px;
      margin-right: 1em;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;

      &.error {
        color: red;
        font-size: 12px;
        text-align: center;
      }

      &.confirm {
        background: $secondary-color;
        border: 2px solid $secondary-color;
        box-shadow: $light-box-shadow;
        &:hover {
          opacity: 0.8;
          background: $secondary-color;
          border-color: $secondary-color;
        }
      }

      &.cancel {
        background: $light-color;
        border: 2px solid $light-color;
        color: $primary-color-text;
        &:hover {
          opacity: 0.8;
          background: $light-color;
          border-color: $light-color;
        }
      }

      span {
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    }
  }
}

.edit-container.hebrew {
  input {
    text-align: right;
  }
}
