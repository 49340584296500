@import 'styles/theme.scss';

.tour-backdrop {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
  background-color: rgba(48, 53, 124, 0.57);
}

.ant-popover.tour-overlay {
  width: 20em;
  .ant-popover-inner {
    border-radius: 0.8em;
    .close-btn {
      position: absolute;
      top: 5px;
      right: 0;
      width: 14px;
      height: 14px;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 2px;
        background-color: #2f2c88;
        transform-origin: center;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }

      &:hover::before,
      &:hover::after {
        background-color: darken(#2f2c88, 10%);
      }
    }
    .ant-popover-inner-content {
      padding: 12px 14px !important;
      .ant-col {
        p {
          text-align: left !important;
          color: #2f2c88;
          font-weight: 300;
          font-size: 12px;
        }
        h3 {
          color: #2f2c88;
          font-size: 14px;
          margin-bottom: 0.5em;
        }
        .ant-btn {
          background: #ffeae8;
          height: 32px;
          border: none;
          box-sizing: border-box;
          box-shadow: 0px 10px 15px -4px #ffeae824;
          border-radius: 4px;
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          text-transform: uppercase;
          color: $secondary-color;
          padding: 0px 15px;
          margin-top: 0.5em;
          width: 100%;
        }
      }
    }
  }
}

.tour-focus {
  position: relative;
  transform: translate(-50%, 0%);
  width: 40px;
  height: 40px;
  background-color: white;
  text-align: center;
  line-height: 46px;
  border-radius: 50%;
  pointer-events: none;
  z-index: 1001;

  &:hover {
    cursor: pointer;
  }

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background: rgb(239, 239, 239);
    border-radius: 50%;
    z-index: -1;
    animation: grow 1s ease-in-out infinite;
  }

  &::after {
    background: rgba(218, 218, 218, 0.4);
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      z-index: -1;
      animation: grow 1s ease-in-out infinite;
    }
  }

  &::before {
    background: rgba(244, 242, 243, 0.6);
    animation-delay: -0.5s;
  }
}

@keyframes grow {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(1.8, 1.8);
    opacity: 0;
  }
}
