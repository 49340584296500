@import 'styles/theme.scss';
.reset-item {
  text-align: right;
  .button {
    height: 32px;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
  }
  .cancel {
    background: $light-color !important;
    border: 2px solid $light-color !important;
    color: $primary-color-text !important;
    &:hover {
      opacity: 0.8;
      background: $light-color !important;
      border-color: $light-color !important;
    }
    &.active {
      opacity: 0.8;
      background: $light-color !important;
      border-color: $light-color !important;
    }
  }
  // position:fixed;
  // bottom:24px;
  // right:24px
}
