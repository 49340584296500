@import 'styles/theme.scss';

.autocomplete-wrapper {
  margin-bottom: 2em;
  .ant-select {
    width: 100%;
    .ant-row {
      .input-label {
        margin-bottom: 4px;
      }
      .ant-input {
        color: $primary-color;
      }
    }
    .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
  }
    .ant-input, .ant-select-selection-item {
      color: $primary-color;
    }
  }
  .input-container {
    margin-bottom: 0;
  }
}

.rc-virtual-list-holder-inner {
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(4, 3, 49, 0.2);
  border-radius: 4px;
  .ant-select-item {
    padding: 0.7em 1em;
    color: $primary-color;
    .ant-select-item-option-content {
      span:first-child {
        vertical-align: initial !important;
      }
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: $primary-color;
  }
}
