
.ant-modal-body .grid-image {
  height: 100%;
  .grid-image-layout {
    height: 50vh;
    position: relative;
    overflow: scroll;
    background: #F5F6FA;

    .react-grid-layout {
      width: 100%;
      // height: 90%;
      // .fa-close {
      //   padding: 0px;
      //   display: table;
      // }

      .anticon svg {
        color: rgb(0, 255, 255);
        mix-blend-mode: difference;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 3px;
        transition: 0.2s ease-in-out;
      }

      .react-resizable-handle::after {
        border-right: 2px solid rgb(0, 255, 255);
        border-bottom: 2px solid rgb(0, 255, 255);
        mix-blend-mode: difference;
        width: 15px;
        height: 15px;
      }
    }
  }
  .ant-row.button-wrapper {
    margin-top: 1em;
    padding: 0;
    justify-content: flex-start;
    button {
      margin-right: 1em;
    }
  }       
}