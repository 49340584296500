@import 'styles/theme.scss';
.activity-tabs {
    border-radius: 6px;
    background: #F6F6FA;
    margin-bottom: 12px;
    width: fit-content;
    margin-left: 24px;
    gap: 8px;
    padding: 4px;


    .tab {
        color: $primary-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 4 00;
        line-height: 140%;
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: $white-color;
        cursor: pointer;

        &.active {
            font-weight: 600;
        }
       
        &:hover {
            background: #e7e7f2;
        }
    }

    :first-child{
        width: 119px;
    }

    :nth-child(2){
        width: 70px;
    }   
}