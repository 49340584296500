.toast-review-message {
  direction: initial;
  width: 90%;
  max-width: 380px;
  border-radius: 8px;
  position: absolute;
  padding: 22px;
  z-index: 5;
  top: 140px;
  left: 20px;
  box-shadow: 0px 5px 12px 2px #6E788133;
  background: #fff;
  border: 1px solid #ECEFF3;
  font-size: 14px;
  line-height: 18px;

  @media screen and (min-width:1000px) {
    left: -50px;
  }

  @media screen and (min-width:1150px) {
    left: -100px;
  }

  h4 {
    font-weight: 700;
  }

  p {
    margin: 10px 0 15px 0;
  }

  .anticon {
    cursor: pointer;

    &.close {
      position: absolute;
      top: 6px;
      right: 6px;

      svg:not(:hover)>rect:nth-child(1) {
        fill: none;
      }
    }
  }

  button {
    &.cancel {
      padding: 6px 18px;
      border-radius: 40px;
      color: #000624;
      border: 1px solid #a5a5a5;
      line-height: 1;
      font-weight: 500;
      background: transparent;

      &:hover {
        background: rgba(53, 47, 100, 0.07);
      }
    }
  }
}