@import 'styles/theme.scss';

.content-library-card {
  overflow: hidden;
  position: relative;
  transition: transform 0.5s;

  .hover-items-container {
    top: 0;
    transition: 0.4s opacity;
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 999;
    background-color: #05034daa;
    display: flex;
    align-items: center;
    justify-content: center;

    .remove-icon {
      position: absolute;
      right: 5px;
      top: 5px;
    }

    .hover-items {
      align-items: center;
      justify-content: center;
      display: flex;
      .buttons {
        padding: 0.5em;
        pointer-events: all;
        display: flex;
        flex-direction: column;

        .ant-btn {
          border-radius: 4px;
          margin: 4px 0;
          font-size: 12px;
          color: #ffffff;
          background-color: transparent;
          border: 1px solid #ffffff;
          width: 100%;
          height: 28px;

          @media screen and (min-width: 1200px) {
            font-size: 14px;
            height: 32px;
          }

          &:hover {
            background: #ffffff;
            color: #05034d;
          }
        }
      }
    }
  }
  &:hover .hover-items-container {
    opacity: 1;
    display: flex;
  }
  .template-thumbnail-container {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    .template-thumbnail {
      width: 900px;
      transform-origin: left top;
      transform: scale(0.32);
    }
  }
}

.card-content {
  padding: 1em;
  font-size: 10px;
  font-weight: normal;
  line-height: 140%;
  color: #000000;
  .card-description {
    padding-left: 4px;
  }
}

.card-title {
  color: $primary-color-text;
  border-top: 1px solid #cbced8;
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 140%;
  width: 100%;
  .title {
    padding: 8px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-grow: 1;
  }

  .anticon-star,
  .menu-icon {
    padding-left: 10px;
    padding-right: 10px;

    flex-shrink: 1;
  }
}

.ant-card-cover {
  img {
    width: 100%;
    height: auto;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
}
