.keyboard-shortcut-modal {
  width: 90% !important;
  max-width: 600px !important;

  .title {
    justify-content: flex-start !important;
    margin-top: 0 !important;
  }

  .divider {
    margin: 0 !important;
  }

  .body {
    text-align: left !important;
  }

  .command-display {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;

    &>span {
      padding: 3px 10px;
      border-radius: 4px;
    }
  }

  .key-button {
    background: #F5F6FA;
    font-size: 0.8em;
    font-weight: 500;
  }

  .key-or {
    padding: 3px 3px !important;
    transform: translateY(-2px);
  }

  .key-plus {
    opacity: 0.5;
  }
  .key-value{
    padding: 2px 0 0 !important;
  }
}