.gallery-library-folders {
  user-select: none;
  .folder {
    cursor: pointer;
    margin: 16px 0;
    display: flex;
    align-items: center;

    .anticon {
      margin-right: 10px;
    }

    .folder-name {
      flex-grow: 1;
      text-align: left;
    }
    align-items: center;
  }

  .folder-path {
    display: flex;
    align-items: center;

    .path {
      flex-grow: 1;

      .folder-path-name {
        text-transform: capitalize;

        &:last-of-type {
          font-weight: bold;
        }
      }
    }
  }

  .star-icon {
    width: 20px;
    svg {
      transform: scale(1.7);
    }
  }
}
