@import 'styles/theme.scss';

.proposal-header {
  &.wixHeader {
    color: #162d3d;
    display: block;
    padding: 0em 28px 0em 28px !important;
    box-shadow: none;
    z-index: 999999;

    & > :not(.spacer) {
      flex-grow: 0;
      &:first-child {
        margin-right: 0 !important;
      }
      &:not(.ant-divider):not(.seprator) {
        @media screen and (min-width: 992px) {
          margin-right: unset;
          margin-left: unset;
        }
      }
    }
  }
  .nav-wrapper {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .wix_backToProposalsBtn {
    display: inline-flex;
    align-items: center;
    align-self: center;
    cursor: pointer;
    white-space: nowrap;
    background-color: transparent;
    border-color: transparent;
    text-decoration: none;
    border: 0;
    border-radius: 2px;
    transition-duration: 100ms;
    transition-timing-function: linear;
    transition-property: background-color,color,border-color;
    outline: 0;
    padding: 0;
    height: 24px;
    user-select: none;
    font-family: Madefor, sans-serif;
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .wix_backToProposalsBtn span:last-child {
    font-weight: 200;
    padding-left: 12px;
    color: #000;
  }

  .header-item {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
    justify-self: end;
    display: flex;
    gap: 12px;
  }

  .stepper {
    display: flex;
    align-items: center;
    font-family: Madefor, sans-serif;
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .header-stepper {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
    .stepper-wrapper {
      justify-content: center;
      display: flex;
      align-items: center;
      width: 100%;
    
      .stepper-item {
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        &.active {
          color: #1f75ff;
        }
        &.disabled {
          pointer-events: none;
        }
      }
    }

    .stepper-text {
      display: inline-flex;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 200;
      text-overflow: ellipsis;

      &.stepper.medium {
        height: 30px;
        max-width: 240px;
        padding: 0 10px;
      }
    }

    .stepper-divider {
      width: 4px;
      min-height: 10px;
      margin: 0px 18px 0 8px;
      color: #b6c1cd;
    }

    .item-wrap {
      align-items: center;
      flex-direction: row;
      gap: 0;
      display: flex;
      margin-right: 6px;

      &.complete {
        color: #116dff;
      }
    }
  }

  .wixPreview-button, .wix-template-save-button {
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    border: 1px solid;
    font-weight: 400;
    line-height: 24px;
    outline: 0;
    transition-duration: 100ms;
    transition-timing-function: linear;
    transition-property: background-color, color, border-color;
    min-width: 65px;
    border-radius: 18px;
    padding: 0 23px;
    background-color: #116dff;
    border-color: #116dff;
    color: #ffffff;
    display: flex;
    align-items: center;
    text-decoration: none;
    user-select: none;
    white-space: nowrap;

    &:hover {
      background-color: #1062e5;
      border-color: #1062e5;
      color: #fafafa;
    }
  }

  .wix-template-save-button {
    color: #116dff;
    background-color: #fff;
    border-color: #D6E6FE;
    &:hover {
      background-color: #fff;
      border-color: #D6E6FE;
      color: #116dff;
    }
  }

  .wix-dropdown {
    gap: 0;
    display: flex;

    .wixDropdownButton {
      width: 30px;
      height: 30px;
      border-radius: 18px;
      padding: 0;
      display: inline-flex;
      align-items: center;
      box-sizing: border-box;
      text-align: center;
      border: 1px solid;
      outline: 0;
      transition-duration: 100ms;
      transition-timing-function: linear;
      transition-property: background-color, color, border-color;
      border: solid 1px #d6e6fe;
      background-color: #ffffff;
      color: #116dff;
      cursor: pointer;
    }
    
    .dropdown-icon {
      line-height: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .wixScreen-switcher {
    height: 33px;
    padding: 3px 2px 3px;
    border: 1px solid;
    border-color: #D6E6FE;
    border-radius: 5px;
    background-color: #fff;

    .ant-radio-group {
      display: flex;
      line-height: 0px;
  }

    .ant-radio-button-wrapper {
      display: flex;
      align-items: center;

      span:last-child {
        display: flex;
        align-items: center;
      }

      &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        border-radius: 4px !important;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        box-shadow: 0px 0px 0px 2px #116DFF !important
      }
    }

    .switch-icon {
      padding-top: 0;
    }

    .switch-toggle-button-text {
      color: #000;
      font-size: 13px;
      font-weight: 300;
      margin-left: 6px;
    }
  }
}

.editor-color-picker {
  .ant-popover-inner {
    border-radius: 10px;
  }
}

.wixDropdown-menu {
  padding: 16px 18px;
  background: none !important;
  .menu-item {
    height: unset !important;
    line-height: 18px !important;
    overflow: hidden;
    display: flex;
    width: 100%;
    align-items: flex-start;
    cursor: pointer;
    text-align: start;
    outline: 0;
    border: 0;
    // padding: 22px !important;

    &:last-child:not(:only-child) {
      margin-top: 20px;
      // padding: 0 22px 22px 20px !important;
    }

    &.ant-menu-item-selected {
      background-color: #fff !important;
    }
  }

  .publish-button-menu-title {
    line-height: 18px;
  }

  .publish-button-menu-subtitle {
    color: #868AA5;
    font-size: 12px
  }
}
