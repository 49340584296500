@import 'styles/theme.scss';

.popover-wrapper {
    max-width: 581px;
    min-width: 398px;
    padding: 16px;
    border-radius: 4px;
    background: $white-color;
    box-shadow: 0px 5px 20px 0px rgba(4, 3, 49, 0.15);

    .page-title {
        font-size: 24px;
    }
}

.add-recipient-form {
    display: flex;
    gap: 12px;

    .ant-form-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .form-label {
            color: $label-color-light;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            opacity: 0.8;
        }

        input {
            border-radius: 4px;
            padding: 12px;
            border: 1px solid $tab-border;
            background: $white-color;
            color: $primary-color-text;
            font-size: 14px;
            font-weight: 400;
            height: 40px;
        }
    }

    .ant-form-item-control {
        width: 100%;
    }

    .ant-form-item-explain-error {
        font-size: 12px;
    }

    .ant-form-item-label>label {
        height: auto;
        margin-bottom: 4px;
    }

    .email-input {
        min-width: 271px;
    }
}


.recipient-form-footer-section {
    gap: 12px;

    .cancel-btn,
    .cancel-btn:hover {
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #F3F4F9 !important;
        border: 0;
        color: $primary-color-text !important;
        font-weight: 600;
    }

    .add-btn,
    .add-btn:hover {
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: $secondary-color !important;
        border: 0;
        color: $white-color !important;
        font-weight: 600;
    }

}

.edit-recipient-form {
    .ant-form-item {
        margin: 0;
    }

    .delete-icon {
        margin-top: 21px;

        svg {
            height: 20px;
            width: 20px;
        }
    }

    .email-input {
        min-width: 235px;
    }
}