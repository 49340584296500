@import 'styles/theme.scss';

.dashboard-header {
  background-color: $border-color;
  z-index: 1;
  top: 0;
  width: 100%;
  pointer-events: none;
  position: sticky;
  &:before{
    content: '';
    background-color: $border-color;
    position: absolute;
    width: calc(100% + 60px);
    height: calc(100% + 30px);
    left: -30px;
    top: -30px;
    z-index: 0;
  }

  &.shrink {
    @include sm {
      .page-header {
        .page-title {
          font-size: 24px;
        }
        .create-new-button {
          justify-content: flex-start;
          button {
            width: 100%;
            height: 37px !important;
          }
        }
      }
    }

    a,
    button,
    span,
    input {
      pointer-events: all;
    }

    .ant-picker-range {
      .ant-picker-clear {
        right: -7px !important;
        background: transparent !important;
      }
    }

    .item-filter-menu > div:nth-child(n + 2) {
      overflow: hidden;
      transition: max-height 500ms, opacity 500ms;
      opacity: 1;
      max-height: 65px;
    }

    .dashboard-list-header,
    .item-filter-menu {
      transition: margin 500ms;
      position: relative;
      z-index: 1;
    }

    &.hide-items {
      .item-filter-menu {
        margin-top: 0.5em;

        & > div:nth-child(n + 2) {
          max-height: 0;
          opacity: 0;
        }
      }
    }
  }
}

.handle-user-lock {
  .dashboard-header {
    &.shrink {
      .create-new-button .prosper-icon-button {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}
