@import "styles/theme.scss";

.emails-wrapper {
    margin: 0 48px;
    margin-top: 20px;

    .email-profile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;

        span {
            color: $label-color-light;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
        }

        .sender-image {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        img {
            max-height: 73px;
        }

        .edit-profile-btn {
            display: flex;
            padding: 6px 6px 6px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 2px;
            background: $light-color;
            border: none;

            span {
                color: $primary-color-dark;
                font-size: 14px;
                font-weight: 600;
                line-height: 140%;
            }
        }
    }

    .email-accordion-header {
        gap:4px;
        .email-accordion-title {
            color: $primary-color-text;
            font-size: 14px;
            font-weight: 600;
            line-height: 140%;
            /* 19.6px */
        }
    }

    .accordion-wrapper {
        display: inline-flex;
        padding: 32px;
        margin-top: 15px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        width: 100%;
        border-radius: 8px;
        background: $white-color;
        box-shadow: 0px 2px 4px 0px rgba(3, 20, 63, 0.10);

        >div {
            width: 100%;
        }
    }
}