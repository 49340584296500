.simple-info-modal.invoicing-info-modal {
  width: 520px !important;
  padding-bottom: 0 !important;
  .ant-modal-content {
    padding: 20px 25px;
    height: auto;
    .ant-form {
      .ant-form-item-label {
        label {
          text-transform: uppercase;
        }
      }
    }
    .ant-modal-body {
      div {
        &.button-wrapper {
          margin-bottom: 0;
          padding: 0;
          button {
            &.cancel {
              width: auto;
            }
          }
        }
      }
    }
  }
}
