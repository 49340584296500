@import 'styles/theme.scss';

.expired-proposal {
  &.back-button {
    position: fixed;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    text-align: right;
    height: 32px;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    background: #f5f6fa !important;
    border: 2px solid #f5f6fa !important;
    color: #05034d !important;
    z-index: 9999;
  }
}
.expired-proposal-modal {
  .ant-modal-close {
    display: none !important;
  }
  .ant-modal-body {
    padding: 3em 5em;

    .divider {
      margin: 0 auto;
      margin-top: 1em;
      background: $secondary-color;
      width: 32px;
      height: 1px;
    }
    .title {
      display: flex;
      justify-content: center;
      margin-top: 1em;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      text-align: center;
      color: $primary-color-text;
    }
    .body {
      text-align: center;
      font-weight: normal;
      margin-top: 1.5em;
      font-size: 14px;
      line-height: 150%;
      color: $primary-color-text;
    }
    .button-wrapper {
      display: flex;
      justify-content: space-around;
      margin-top: 2em;
      padding: 0 1.5em;
    }

    .button {
      height: 32px;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      border: unset !important;
      box-shadow: unset !important;
      text-shadow: unset !important;
      display: flex;
      justify-content: center;
      align-items: center;
      .anticon {
        line-height: 0;
      }
    }
    .confirm {
      background: $secondary-color;
      box-shadow: $light-box-shadow;
      &:hover {
        opacity: 0.8;
        background: $secondary-color;
      }
    }
    .cancel {
      background: $light-color;
      color: $primary-color-text;
      &:hover {
        opacity: 0.8;
        background: $light-color;
      }
    }
  }

  &.hebrew, &.arabic {
    .button {
      direction: rtl;
      .anticon {
        margin: 0 5px;
      }
    }
  }
}
