@import "styles/theme.scss";

.tutorial-container {
  .floating-object {
    position: absolute;
    content: '';
    &:nth-child(1) {
      background: url(https://dr08qaykn9isr.cloudfront.net/application/static/Ellipse+2.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 142px;
      width: 94px;
      right: 0;
      top: 0;           
    }
    &:nth-child(2) {
      background: url(https://dr08qaykn9isr.cloudfront.net/application/static/Group+11.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 132px;
      width: 104px;
      left: 0;
      bottom: 140px;
    }
    &:nth-child(3) {
      background: url(https://dr08qaykn9isr.cloudfront.net/application/static/Union.svg);
      background-size: contain;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      right: 87px;
      top: 25px;
    }
  }
  .tutorial-close-icon {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 1;
    cursor: pointer;
  }

  .bg-image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 8px;
  }

  .tutorial-content {
    position: relative;
    text-align: center;
    padding: 2rem;

    .logo {
      margin-top: 1.063rem;
    }
    .tutorial-video-container {
      margin-top: 3rem;
      align-items: center;

      iframe {
        width: 100%;
        border-radius: 8px;
      }

      img {
        height: 227px;
      }
      .play-prompt {
        align-items: center;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin-top: 1rem;
        
        span {
          font-size: 20px;
        }
      .label {
        flex: 1;
        color: $label-color-light;
        font-size: 14px;
        font-weight: 600;
        cursor: default !important;
        border-bottom: 3px solid #C7BCE4;
      }}
    }

    .tutorial-button {
      font-weight: 600;
    }

    .ant-btn.invite-button {
      margin-top: 4.375rem;
      width: 100%;
      height: auto;
      background: $tab-background;
      color: $primary-color;
      height: 44px;
    }

    .ant-btn.create-proposal-button {
      margin-top: 1rem;
      width: 100%;
      height: auto;
      height: 44px;
    }

    .tutorial-info {
      font-size: 18px;
      margin: 2rem 4rem;
      color: $primary-color-text;

      &-large {
        margin: 1.063rem 2rem;

        span:first-child {
          font-weight: 600;
        }

        + .tutorial-video-container {
          margin-top: 2.625rem;
        }
      }

      h3 {
        color:  #05034D;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    .ant-row {
      flex-direction: column;
    }

    .text-center {
      text-align: center;
    }

    .ant-btn-dangerous {
      box-shadow: 0 10px 15px -4px rgba(241, 92, 60, .4)
    }
  }
}