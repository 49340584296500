.bold-600 {
  font-weight: 600;
}

.pricing-wrapper {
  padding-top: 40px;
  margin-top: 20px;
  --padding: 18px;

  .border {
    border: 1px solid #ebebeb;
    border-radius: 12px;
  }

  table {
    width: 100%;
    position: relative;

    td {
      padding: calc(var(--padding) / 2);
    }

    td:first-child {
      padding-left: var(--padding);
    }

    td:last-child {
      padding-right: var(--padding);
    }

    thead td {
      position: relative;
      padding-top: 0;
      padding-bottom: 0;
      vertical-align: bottom;

      span {
        text-transform: uppercase;
        font-size: 14px;
        @extend .bold-600;
        opacity: 0.6;

        position: absolute;
        width: 100%;
        left: 0;
        bottom: -10px;
        padding: calc(var(--padding) / 2);
      }

      &:first-child span {
        padding-left: var(--padding);
      }

      &:last-child span {
        padding-right: var(--padding);
      }
    }

    tbody td {
      padding-top: 16px;
      padding-bottom: 16px;
      word-break: break-word;
      vertical-align: top;
      line-height: 1.4;
      @media screen and (max-width: 768px) {
        font-size: 0.9rem;
      }
    }
  }

  table.pricing-footer {
    margin-top: 0.8rem;
  }

  &.payment-schedule {
    table thead td span {
      bottom: -3px;
    }
  }

  .name {
    min-width: 125px;
    white-space: pre-line;
  }

  .qty {
    text-align: center;
    min-width: 100px;
  }

  .amount {
    min-width: 90px;
  }

  .price {
    min-width: 80px;
    text-align: right;
  }

  .payment {
    min-width: 150px;;
  }

  .no-break {
    white-space: nowrap; // word wont break at all
  }

  // all deliverable item wrapper
  .pricing-deliverables {
    .deliverable {
      .price {
        @extend .bold-600;
        line-height: 1.6;
      }

      .subtitle {
        opacity: 0.6;
        font-size: 0.75em;
        font-weight: 500;
        white-space: pre-line;
      }

      .subtitle.big {
        font-size: 0.8em;
      }

      &:nth-child(even) {
        background-color: #00000005;
      }
    }
  }

  // summary and total
  .pricing-summary {
    border-bottom: 1px solid #ebebeb;
    opacity: 0.6;

    td {
      padding: 10px 0;
    }

    .price {
      @extend .bold-600;
    }
  }

  .pricing-total {
    padding-top: 14px;
    font-size: 1.1em;
    @extend .bold-600;
  }

  .wix-pricing {
    .pricing-total {
      display: inline-block;
      padding-top: 23px;
    }
  }

  .hide-desktop {
    display: none;
  }

  @media screen and (max-width: 767px) {
    .hide-desktop {
      display: block;
    }
  }

  .notes {
    opacity: 0.5;
    font-size: 0.9rem;
    padding-top: 5px;
  }
}

.payment {
  .subtitle-wrapper {
    font-size: 0.75em;
    font-weight: 500;
  }
}

// -----toolbar----
.payment-component-wrapper {
  position: relative;

  .rich-editor-components-action-buttons {
    display: none;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
    position: absolute;
    bottom: -6px;

    .group {
      background-color: #fff;
      font-size: 14px;
      text-align: center;
      color: #05034d;
      display: flex;
      box-shadow: 0 2px 4px rgba(0, 38, 95, 0.2);
      border-radius: 4px;
      overflow: hidden;

      button {
        height: 26px;
        font-size: inherit;
        background-color: transparent;
        border: 0;
        border-radius: 4px;
        padding: 0 10px;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.06);
        }
      }
    }
  }

  &:hover {
    .pricing-wrapper {
      box-shadow: 0 0 0 2px #005aff;
    }

    .rich-editor-components-action-buttons {
      display: flex;
    }
  }
}

.RichEditor-atomic {
  .payment-component-wrapper {
    padding-bottom: 40px;
  }
}

// -----toolbar----

@mixin mobile-pricing-wrapper {
  // common mixin/code to be used for mobile screen and mobile preview
  padding: 0;
  margin: 0;

  table {
    td:first-child,
    td:first-child span {
      padding-left: 0;
    }

    td:last-child,
    td:last-child span {
      padding-right: 0;
    }

    .deliverable {
      background-color: transparent !important;
      border-bottom: 1px solid #e8ecf0;
    }

    thead {
      transform: unset !important;
      border-bottom: 8px solid #e8ecf0;

      td span {
        position: relative;
        bottom: unset;
      }
    }

    .item-col {
      &.due {
        text-align: right;
      }

      &.qty {
        display: none;
      }
    }

    .mobile-qty {
      font-size: 0.8em;
    }
  }

  &.payment-schedule {
    .pricing-header {
      transform: translateY(-10px);
    }
  }

  .hide-mobile {
    display: none;
  }

  .border {
    border: unset;
    border-radius: unset;
  }
}

.large-font {
  .subtitle.amount {
    display: none;
  }
}

.mobile-screen {
  .hide-desktop {
    display: block;
  }

  .pricing-wrapper {
    @include mobile-pricing-wrapper;
  }
}


@media screen and (max-width: 767px) {
  .pricing-wrapper {
    @include mobile-pricing-wrapper;
  }
}
