// ---------- common code for buttons ----------
.ant-btn {

    // ---------- small and medium buttons ----------
    &.small-btn,
    &.medium-btn,
    &.large-btn {
        border-radius: 4px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border: unset !important;
        box-shadow: unset !important;
        height: 24px;
        padding: 0 3px;


        .anticon+span {
            margin-left: 3px;
            padding-right: 6px;
        }

        >span {
            display: flex;
            align-items: center;
            height: 24px;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }

        &:after {
            display: none;
        }

        &:hover {
            filter: brightness(0.95);
        }
    }

    // ---------- medium sized button ----------
    &.medium-btn {
        height: 32px;
        padding: 0 14px;

        .anticon+span {
            margin-left: 6px;
        }
    }

    // ---------- large sized button ----------
    &.large-btn {
        height: 42px;
        padding: 0 18px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        gap: 10px;
        &.icon-right {
            padding-right: 8px;
        }
    }


    // ---------- colored button ----------
    &.secondary-btn {
        background-color: $secondary-color !important;
        color: $white-color !important;

        &:hover {
            color: $white-color !important;
        }
    }

    &.grey-btn {
        background-color: #F5F6FA !important;
        color: $primary-color-text !important;
    }

    &.white-btn {
        background-color: $white-color !important;
        color: $primary-color-text !important;
    }

    &.icon-right {
        flex-direction: row-reverse;
    }
}