@import 'styles/theme.scss';
.login-failed-modal {
  .ant-modal-close {
    display: none !important;
  }
  .ant-modal-body {
    padding: 3em 5em;

    .divider {
      margin: 0 auto;
      margin-top: 1em;
      background: $secondary-color;
      width: 32px;
      height: 1px;
    }
    .title {
      display: flex;
      justify-content: center;
      margin-top: 1em;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      text-align: center;
      color: $primary-color-text;
    }
    .body {
      text-align: center;
      font-weight: normal;
      margin-top: 1.5em;
      font-size: 14px;
      line-height: 150%;
      color: $primary-color-text;
    }
    .button-wrapper {
      display: flex;
      justify-content: space-around;
      margin-top: 2em;
      padding: 0 1.5em;
    }

    .button {
      height: 32px;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
    }
    .confirm {
      background: $secondary-color;
      border: 2px solid $secondary-color;
      box-shadow: $light-box-shadow;
      &:hover {
        opacity: 0.8;
        background: $secondary-color;
        border-color: $secondary-color;
      }
    }
    .cancel {
      background: $light-color;
      border: 2px solid $light-color;
      color: $primary-color-text;
      &:hover {
        opacity: 0.8;
        background: $light-color;
        border-color: $light-color;
      }
    }
  }
}
