@import "styles/theme.scss";

.morninginvoice-connect-modal {
  .ant-modal-body {
    .title {
      justify-content: flex-start;
    }
    .divider {
      margin: 1em 2em 0 0;
    }
    .body {
      font-size: 16px;
    }
  }
}
