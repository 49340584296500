@import "styles/theme.scss";


.send-test-mail-modal {

    .page-title {
        font-size: 24px;
    }

    input {
        display: flex;
        padding: 12px;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid $tab-border;
        background: $white-color;
        color: $primary-color-text;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .cancel-btn, .cancel-btn:hover {
        border-radius: 4px;
        background: $selected-dropdown-placeholder;
        color: $primary-color-text;
        text-align: center;
        width: 180px;
        height: 44px;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 600;
        border:0;
    }

    .save-btn {
        width: 180px;
        height: 44px;
        flex-shrink: 0;
        border-radius: 4px;
        border: 2px solid $secondary-color;
        background: $secondary-color;
        box-shadow: 0px 10px 15px -4px rgba(241, 92, 60, 0.40);
    }

}