@import "styles/theme.scss";

.add-html-modal {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-body,
      .ant-modal-footer {
        padding: 2em 2em;
      }

      .ant-modal-body {
        .title {
          justify-content: flex-start;
        }
        
        .divider {
          margin: 0;
        }

        .body {
          margin-top: 2.5em;
          .ant_form .ant-form-item {
            .ant-form-item-label label {
              color: $label-color-light;
            }
          }
          .ant-form-item-control-input textarea {
            font-family: Ubuntu;
          }
        }
      }

      .ant-modal-footer {
        border-top: none;
        justify-content: space-around;
        display: flex;
        padding-top: 0;
        button {
          width: 10em;
          height: 44px;
        }
      }
    }
  }
}
