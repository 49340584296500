@import 'styles/theme.scss';

.trial-end-popup.reminder {
  .ant-modal-content {
    .ant-modal-body {
      .popup-left {
        .top-object {
          position: absolute;
          background: url(https://dr08qaykn9isr.cloudfront.net/application/static/cards/Ellipse.svg);
          background-size: contain;
          content: '';
          width: 45px;
          height: 45px;
          background-repeat: no-repeat;
          right: 95px;
          top: 0;
        }

        .left-object {
          position: absolute;
          background: url(https://dr08qaykn9isr.cloudfront.net/application/static/cards/Union.svg);
          background-size: contain;
          content: '';
          width: 18px;
          height: 18px;
          background-repeat: no-repeat;
          left: 0;
          top: 85px;
        }

        .bottom-object {
          position: absolute;
          background: url(https://dr08qaykn9isr.cloudfront.net/application/static/cards/Union1.svg);
          background-size: contain;
          content: '';
          width: 20px;
          height: 30px;
          background-repeat: no-repeat;
          right: 0;
          top: 286px;
        }

        .trial-left-header {
          padding: 1.5em;
          .logo {
            height: 15px;
          }
          .title {
            margin-bottom: 1.6rem;
            position: relative;
            top: 1.9rem;
            p {
              color: $white-color;
              font-size: 14px;
              font-weight: 300;
              margin-bottom: 0;
            }
            h1 {
            color: $white-color;
            font-weight: 400;
            font-size: 27px;
            line-height: 33px;
            margin-bottom: 0;
            span {
              color: rgba(240, 63, 59, 1);
            }
            }
          }
        }
        .body {
          margin-top: 0;
          .dashboard-img {
            position: relative;
            left: auto;
            right: 5px;
            top: 8px;
            .floating-object {
              position: absolute;
              content: '';
              &:nth-child(1) {
                background: url(https://dr08qaykn9isr.cloudfront.net/application/static/cards/GroupLR.svg);
                background-size: contain;
                background-repeat: no-repeat;
                height: 94px;
                width: 322px;
                right: 0;
                bottom: 60px;            
              }
              &:nth-child(2) {
                background: url(https://dr08qaykn9isr.cloudfront.net/application/static/cards/GroupRL.svg);
                background-size: contain;
                background-repeat: no-repeat;
                height: 70px;
                width: 254px;
                right: 0;
                bottom: 0;
              }
            }
            img {
              width: 96%;
            }
          }
        }
      }
    }
  }
}
