@import "styles/theme.scss";

.covers-library-section .ant-row.library-sections-container {
  > div {
    position: relative;
    margin-bottom: 1.5em;
    padding: 0px !important;
    min-height: 12em;
    overflow: hidden;
    box-shadow: 0px 0px 1px 0px #d9d7d7;

    .header-section {
      padding: 16px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      overflow: hidden;
      transform: scale(0.4, 0.4);
      width: 900px;
      max-width: 58em;
      transform-origin: left;
      -webkit-transform-origin: top left;
      box-shadow: $box-shadow-dark;
      @media (min-width: 1441px) {
        width: 1440px;
        max-width: 90em;
      }
      &.thumbnail {
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27rem;
      }
      .proposal-headersection {
        margin-bottom: 0px;
        padding: 50px !important;
        border-bottom: 0px none #fff;
        background-color: #fff;
        transition: background 0.5s ease;
        position: relative;
        box-shadow: none !important;
      }
    }

    .toolbar-library-section {
      position: relative;

      border-radius: 4px;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
      }
    }

    .ant-row.section-title {
      position: absolute;
      padding: 0;
      bottom: 0;
      height: 30px;
      width: 100%;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      border-top: 1px solid #f0f0f0;
      display: none;
      color: $primary-color-text;
      background: white;
      span {
        line-height: 25px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      &.hebrew > span {
        direction: rtl;
      }
    }

    &:hover {
      .ant-row.section-title {
        display: flex;
      }
    }

    .drag-element-placeholder {
      padding: 0px;
      width: 100%;
      height: 100%;
      margin-top: 0;
    }
  }
}
