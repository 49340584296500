@import "styles/theme.scss";

.user-modal {
  width: 28em !important;
  h3 {
    color: $primary-color-text;
    font-weight: 600;
    font-size: 24px;
  }

  .user-image-container {
    .user-photo-title {
      font-size: $label-font-size;
      line-height: 140%;
      color: $label-color-light;
    }
    & > div {
      display: flex;
      align-items: center;

      .user-image {
        margin-top: 4px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;
      }

      .ant-btn {
        color: $primary-color;
        background: $tab-background;
        border: none;
        height: 24px;
        font-size: 12px;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.04em;
      }
    }
  }

  .color-divider {
    background: $secondary-color;
    width: 32px;
    height: 1px;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .user-form {
    margin-top: 22px;
    & label {
      font-style: normal;
      font-weight: normal;
      font-size: $label-font-size;
      line-height: 140%;
      color: $label-color-light;
    }
  }

  .ant-radio-wrapper {
    padding: 5px;

    border-radius: 4px;
    min-width: 181px;
    margin-bottom: 8px;
    font-size: 14px !important;
    color: $primary-color-text !important;
    border: 1px solid $tab-border;

    &.ant-radio-wrapper-checked {
      border: 1px solid $primary-color-light;
    }
  }

  .actions {
    display: flex;

    button.submit {
      margin-right: 12px;
      background: $secondary-color;
      color: $white-color;
      box-shadow: $light-box-shadow;
      border: 2px solid $secondary-color;
    }

    button.cancel {
      margin-left: 12px;
      background: $tab-background;
      color: $primary-color;
    }

    button {
      border: none;
      width: 100%;
      height: 44px;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  .change-btn {
    color: $primary-color;
    background: $light-color;
    border: none;
    height: 24px;
    font-size: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.04em;
    margin-left: 1em;
  }
}

.anticon.close-icon {
  &:hover {
    background: $light-color;
    border-radius: 50%;
    padding: 4px 4px;
  }
}
